import React from "react";
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {
    Button,
    ButtonGroup,
    FormControl,
    InputLabel,
    MenuItem,
    Select, Snackbar,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import ArrestDropDowns, {StolenStateDropdown, VinDropDowns} from "../arrest/_arrestDropDowns";
import Slide, { SlideProps } from '@mui/material/Slide';
import {formatInputDate} from "../../../services/formatDate.service";
type TransitionProps = Omit<SlideProps, 'direction'>;

interface StolenVehicleSearchProps {
    SubmitSearch: any
}

interface searchObject {
    option : string
    value : any
    type : string
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionDown(props: TransitionProps) {
    return <Slide {...props} direction="down" />;
}

const CarjackingSearch: React.FC<StolenVehicleSearchProps> = ({ SubmitSearch }) => {
    let today = new Date();
    let fromday = new Date();
    fromday.setMonth(fromday.getMonth() - 1);
    fromday.setDate(fromday.getDate() + 1);
    const [params, setParams] = React.useState({ toDate: formatInputDate(today), fromDate: formatInputDate(fromday), systemCall:false })
    const [dateType, setDateType] = React.useState<string>("Carjacking Date");
    const [dropDownParams, setDropDownParams] = React.useState<any>({City:{}, County:{}, Agency:{}});
    const [clear, setClear] = React.useState<number>(0);
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)

    const [options, setOptions] = React.useState<number>(1)
    const [optionList] = React.useState<string[]>(["Date", "City", "Resident County", "Agency Arrested"])
    const [searchOptions, setSearchOptions] = React.useState<searchObject[]>([{ option: "Date", value: 0, type: "Date" }])
    const [initialRun, setInitialRun] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (!initialRun) {
            setInitialRun(true)
            let p = {...params}
            p.systemCall = true
            SubmitSearch(p, dateType, dropDownParams, searchOptions.some(e => e.option == "Date"))
        }
    }, [])

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };
    const HandleDropDowns = (dropDowns: any) => {
        console.log(dropDowns)
        let temp = {...dropDownParams}
        temp.City = dropDowns.City
        setDropDownParams(temp)
    }

    const HandleDropDownsCounty = (dropDowns: any) => {
        let temp = {...dropDownParams}
        temp.County = dropDowns.County
        setDropDownParams(temp)
    }

    const HandleDropDownsAgency = (dropDowns: any) => {
        let temp = {...dropDownParams}
        temp.Agency = dropDowns.Agency
        setDropDownParams(temp)
    }
    const handleClear = async () => {
        await setSearchOptions([])
        setParams({ ...params, toDate: formatInputDate(today), fromDate: formatInputDate(fromday), systemCall:false })
        setDateType("Carjacking Date")
        setClear(clear + 1)
        setSearchOptions([{ option: "Date", value: 0, type: "Date" }])
        setDropDownParams({ City: {}, County: {}, Agency: {} })
        setOptions(options + 1)
    }

    const handleSearch = () => {
        if(!searchOptions.some(e => e.option == "")) {
            let dropDownParam = {...dropDownParams};
            dropDownParam.City = Object?.keys(dropDownParam.City)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
            SubmitSearch(params, dateType, dropDownParam, searchOptions.some(e => e.option == "Date"))
        }
        else {
            setAlertOpen(true)
        }
    }

    const handleOptions = () => {
        let temp = searchOptions
        temp.push({option:"", value:"", type:""})
        setSearchOptions(temp)
        setOptions(options +1)
    }

    const removeOption = (indx:number) => {
        let temp = searchOptions
        let del = temp.splice(indx, 1)
        if(del && del[0]?.option == 'City')
        {
            let ddtemp = dropDownParams
            ddtemp.City = {}
            setDropDownParams(ddtemp)
        }
        else if (del && del[0]?.option == 'Resident County' )
        {
            let ddtemp = dropDownParams
            ddtemp.County = {}
            setDropDownParams(ddtemp)
        }
        else if (del && del[0]?.option == 'Agency Arrested' )
        {
            let ddtemp = dropDownParams
            ddtemp.Agency = {}
            setDropDownParams(ddtemp)
        }
        setSearchOptions(temp)
        setOptions(options +1)
    }

    const changeOption = (selected:string, indx:number) => {
        let temp = searchOptions
        temp[indx] = {option:selected, value:"", type:selected == "Date" ? "Date" : selected == "City" ? "City" : selected == "Resident County" ? "Resident County" : selected == "Agency Arrested" ? "Agency Arrested" : "String"}
        setSearchOptions(temp)
        setOptions(options + 1)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <Snackbar open={alertOpen} TransitionComponent={TransitionDown} anchorOrigin={{vertical:'top', horizontal:'center'}} autoHideDuration={6000} onClose={ () => { setAlertOpen(false)}}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={"error"} sx={{ width: '100%' }}>
                    {"One or More Search Condition is Invalid"}
                </Alert>
            </Snackbar>
            <div className = "flex">
                <ToggleButtonGroup
                    color="primary"
                    value={dateType}
                    exclusive
                    size="small"
                    onChange={handleDateType}
                    sx={{ mt: 1, mr: 2 }}
                >
                    <ToggleButton value="Carjacking Date" sx={{ pr: 2, pl: 2 }} >Carjacking Date</ToggleButton>
                    <ToggleButton value="Entry Date" sx={{ pr: 2, pl: 2 }} >Entry Date</ToggleButton>
                </ToggleButtonGroup>
                <div className = "mt-2">
                    <IconButton size={"large"} onClick={handleOptions} color ={"inherit"} title={"Add Search Condition"}> <AddIcon/> </IconButton>
                </div>
            </div>
            {searchOptions.length > 0 ?
                ((searchOptions) ? searchOptions.map((obj, indx) => (
                    <div className="mt-5 flex">
                        <FormControl sx={{minWidth: 300, marginRight: 2}} size="small">
                            <InputLabel id="search-option">Search Option</InputLabel>
                            <Select
                                labelId="search-option"
                                value={obj.option}
                                label="Search Option"
                                onChange={(e) => changeOption(e.target.value, indx)}
                            >
                                {optionList.map((option) => (
                                    <MenuItem value={option} disabled={searchOptions.some(e => e.option == option)}>{option}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        {obj.option && obj.option.length > 0 ?
                            (obj.type == "Date" ?
                            <span className={"flex gap-x-4"} style={{width:'30%'}}>
                                <span className={"flex-1"}>
                                    <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                               size={"small"} label="From Date"
                                               InputLabelProps={{shrink: true}}
                                               inputProps={{style: {textAlign: 'center'}}}
                                               value={params.fromDate}
                                               onChange={(e: any) => {
                                                   setParams({...params, fromDate: e.target.value})
                                               }}
                                    />
                                </span>
                                <span className={"flex-1"}>
                                    <TextField variant="outlined" margin='none' type={"date"} fullWidth
                                               size={"small"} label="To Date"
                                               InputLabelProps={{shrink: true}}
                                               inputProps={{style: {textAlign: 'center'}}}
                                               value={params.toDate}
                                               onChange={(e: any) => {
                                                   setParams({...params, toDate: e.target.value})
                                               }}
                                    />
                                </span>
                            </span>
                                    :
                                    (
                                        obj.type == "City" ?
                                            <span className="inline">
                                                <ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"carjackingCity"}/>
                                            </span>
                                            :
                                            obj.type == "Resident County" ?
                                                <span className="inline">
                                                    <ArrestDropDowns HandleDropDowns={HandleDropDownsCounty} clear={clear} tool={"carjackingCounty"}/>
                                                </span>
                                                :
                                                obj.type == "Agency Arrested" ?
                                                    <span className = "inline">
                                                        <ArrestDropDowns HandleDropDowns={HandleDropDownsAgency} clear={clear} tool={"carjackingAgency"}/>
                                                    </span>
                                                    :
                                                    <></>
                                                    
                                    )
                            )
                            :
                            <></>
                        }
                        <span className="ml-10 inline">
                            <IconButton aria-label="delete" size="small" onClick={() => removeOption(indx)} color={"error"} title="Remove Search Condition">
                                <DeleteIcon/>
                            </IconButton>
                        </span>
                    </div>

                )) : <></>)
                :
                <div className = "text-lg text-gray-500 mt-5" style={{"display":"flex", "justifyContent":"center"}}>Please add Search Condtions</div>
            }
            <div className={"pt-3"} style={{"display":"flex", "justifyContent":"end"}}>
                <ButtonGroup size="large" color="inherit" >
                    <Button  type="submit" onClick={handleSearch} disabled={searchOptions.length == 0}>Search</Button>
                    <Button  onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default CarjackingSearch;

