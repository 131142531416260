import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { NJPortDashboardDataApi } from "../../../services/njport.dashboard.service";

interface VolunteerRequestProps {}

const VolunteerHirePieChart: React.FC<VolunteerRequestProps> = () => {
  const [hiredData, setHiredData] = useState<any>([]);

  useEffect(() => {
    const payload = {
      queryType: "VOLUNTEERHIRE_MONTH_START_TIME",
      dateTypeValue: 1,
    };
    NJPortDashboardDataApi(payload).then((qRes) => {
      setHiredData(qRes?.AnyTable);
    });
  }, []);

  const handleSeries = () => {
    const timeMapping: { [key: string]: string } = {
      "08:00": "8AM",
      "10:00": "10AM",
      "13:00": "1PM",
      "15:00": "3PM",
      "19:00": "7PM",
      "23:00": "11PM",
    };

    const countMapping: { [key: string]: number } = {
      "8AM": 0,
      "10AM": 0,
      "1PM": 0,
      "3PM": 0,
      "7PM": 0,
      "11PM": 0,
    };

    hiredData.forEach((item: any) => {
      const hiringTime = item.HiringTime;
      if (timeMapping[hiringTime]) {
        const mappedTime = timeMapping[hiringTime];
        countMapping[mappedTime] += item.Requested;
      }
    });

    const pieData = Object.keys(countMapping).map((time) => ({
      name: time,
      value: countMapping[time],
    }));


    return [
      {
        name: "Volunteer Hire",
        type: "pie",
        radius: "50%",
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        data: pieData.filter((item) => item.value > 0),
      },
    ];
  };

  var option = {
    tooltip: {
      trigger: "item",
    },
    height: "180",
    width: "95%",
    toolbox: {
      feature: {
        saveAsImage: { show: true },
      },
    },
    series: handleSeries(),
  };

  return (
    <>
      {hiredData && hiredData?.length !== 0 ? (
        <div>
          <ReactECharts option={option} style={{ height: "160px" }} />
        </div>
      ) : (
        <div
          className={"text-center text-base xl:text-3xl mt-6 mb-1"}
          style={{ color: "red", fontWeight: 500 }}
        >
          No Data
        </div>
      )}
    </>
  );
};

export default VolunteerHirePieChart;
