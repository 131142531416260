import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import {
  DropDown,
  InvestigationObject,
} from "../../../interfaces/NJPORT/InvestigationLog.interface";
import { InvestigationDropDown } from "../../../services/njport.service";
import { ShipEntyObject } from "../../../interfaces/NJPORT/ShipEntry.interface";
// import { formatInTimeZone } from "date-fns-tz";
import FilePreview from "./filePreview";
import DeleteIcon from "@mui/icons-material/Delete";

interface InputFormProps {
  handleReportSubmit: any;
  editReport: InvestigationObject | null;
}

const ShipEntryInputForm: React.FC<InputFormProps> = ({
  handleReportSubmit,
  editReport,
}) => {
  const [report, setReport] = React.useState<ShipEntyObject>(
    editReport ? editReport : {},
  );
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [alertText, setAlertText] = React.useState<String>("");
  const [alertType, setAlertType] = React.useState<any>("success");
  const [shipTerminalDD, setShipTerminalDD] = React.useState<DropDown[]>([]);
  const [defaultstatus, setDefaultStatus] = React.useState<string>("");
  const [defaultPort, setDefaultPort] = React.useState<string>("");
  const [portDD, setPortDD] = React.useState<DropDown[]>([]);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isError, setError] = useState<boolean>(false);
  const [fileBase64, setFileBase64] = useState<string | undefined>("");

  React.useEffect(() => {
    portLocationDropDown();
    // portTerminalDropDown();
    if (editReport) {
      terminalDropDown(editReport?.Port);
      if (editReport?.FileContent) {
        // console.log(getFileTypeFromBase64(editReport?.FileContent));
        setFileBase64(editReport?.FileContent);
      }
      setReport(editReport);
    } else {
      // const timeZoneDate = toZonedTime(new Date(), "Asia/Kolkata");
      // report.ArrivalDateTime = formatInTimeZone(
      //   new Date(),
      //   "Asia/Kolkata",
      //   "yyyy-MM-dd'T'HH:mm:ss",
      // );
    }
    defaultStatusDD(shipTerminalDD);
    defaultPortDD(portDD);
  }, []);


  const getFileTypeFromBase64 = (base64: string) => {
    const mimeType = base64.match(/data:([^;]+);base64,/);
    return mimeType ? mimeType[1] : "";
  };

  const defaultStatusDD = (d: DropDown[]) => {
    let v: string = d
      ? d.find((d) => d.Key == editReport?.ShipTerminal)?.Value!
      : "";
    setDefaultStatus(v);
  };

  const defaultPortDD = (d: DropDown[]) => {
    let v: string = d ? d.find((d) => d.Key == editReport?.Port)?.Value! : "";
    setDefaultPort(v);
  };

  const portLocationDropDown = async () => {
    let data = {
      params: {
        DropDownName: "PORT_LOCATION",
        State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setPortDD(response);
  };

  const terminalDropDown = async (port: any) => {
    let data = {
      params: {
        DropDownName: "PORT_SHIPTERMINAL" + "_" + port,
        // State: "NJ",
      },
    };
    let response: DropDown[] = await InvestigationDropDown(data);
    setShipTerminalDD(response);
    defaultStatusDD(response);
  };

  // const portTerminalDropDown = async () => {
  //   let data = {
  //     params: {
  //       DropDownName: "PORT_SHIPTERMINAL_NEWYORK",
  //     },
  //   };
  //   let response: DropDown[] = await InvestigationDropDown(data);
  //   setShipTerminalDD(response);
  //   defaultStatusDD(response);
  // };

  const handleSubmit = async () => {
    let data = report;
    if (editReport === null) data.EditStatus = "ADD";
    else data.EditStatus = "EDIT";
    if (!handleValidation()) handleReportSubmit(data);
  };

  const handleValueChange = (field: string, val: any) => {
    let temp = { ...report };
    switch (field) {
      case "id":
        temp.ID = val;
        break;
      case "caseNo":
        temp.CaseNo = val;
        break;
      case "shipName":
        temp.ShipName = val;
        break;
      case "Berth":
        temp.Berth = val;
        break;
      case "shipTerminal":
        temp.ShipTerminal = val;
        setDefaultStatus(
          shipTerminalDD.find((d) => d.Key === val)?.Value ?? "",
        );
        break;
      case "arrivalDateTime":
        temp.ArrivalDateTime = val;
        break;
      case "portLocation":
        temp.Port = val;
        setDefaultPort(shipTerminalDD.find((d) => d.Key === val)?.Value ?? "");
        terminalDropDown(temp.Port);
        break;
      case "fileContent":
        setErrorMsg("");
        setError(false);
        const selectedFile = val.target.files[0];
        if (selectedFile) {
          if (selectedFile.size > 10 * 1024 * 1024) {
            setError(true);
            setErrorMsg("File size must be less than 10MB");
            return;
          }
          const validTypes = [
            "application/pdf",
            "image/png",
            "image/jpg",
            "image/jpeg",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ];
          if (!validTypes.includes(selectedFile.type)) {
            setErrorMsg(
              "Invalid file type. Only PDF, image, or DOC files are allowed",
            );
            return;
          }
          const reader = new FileReader();
          let base64Data: string | undefined | null = null;
          reader.onloadend = () => {
            base64Data = reader.result?.toString();
            temp.FileContent = base64Data;
            setFileBase64(base64Data);
          };
          reader.readAsDataURL(selectedFile);
        }
        break;
    }
    setReport(temp);
  };

  const handleValidation = () => {
    let flag = false;
    let text = "";
    let temp = { ...report };
    if (temp) {
      if (!temp.Berth || temp.Berth.trim().length === 0) {
        flag = true;
        text = "Berth";
      } else if (!temp.ShipName || temp.ShipName.length === 0) {
        flag = true;
        text = "Ship Name";
      } else if (!temp.ShipTerminal || temp.ShipTerminal.length === 0) {
        flag = true;
        text = "Ship Terminal";
      } 
      // else if (!temp.ArrivalDateTime) {
      //   flag = true;
      //   text = "Arrival Date Time";
      // }
      if (flag) {
        setAlertText("Please complete Mandatory fields" + text);
        setAlertOpen(true);
        setAlertType("error");
      }
    }
    return flag;
  };

  const convertDate = (dateInput: string | Date | null | undefined) => {
    const date = dateInput ? new Date(dateInput) : new Date();

    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${
      day < 10 ? "0" : ""
    }${day}`;
    const formattedTime = `${hours < 10 ? "0" : ""}${hours}:${
      minutes < 10 ? "0" : ""
    }${minutes}`;

    return `${formattedDate}T${formattedTime}`;
  };

  return (
    <Box className="pt-3" sx={{ flexGrow: 1 }}>
      <div className=" border-2 border-gray-600 mx-4 mt-4">
        <div className="border-b-2 border-gray-600 bg-blue-300 h-2/12 font-bold text-center text-xl">
          Ship Entry
        </div>
        <div className="my-3">
          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="port">Port</InputLabel>
                <Select
                  labelId="port"
                  id="Port"
                  value={report.Port}
                  label="InvStatus"
                  onChange={(e: any) =>
                    handleValueChange("portLocation", e.target.value)
                  }
                  error={report.Port ? false : true}
                >
                  {portDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <FormControl fullWidth>
                <InputLabel id="invStatus-Label">Ship Terminal</InputLabel>
                <Select
                  labelId="invStatus-Label"
                  id="Ship Terminal"
                  value={defaultstatus}
                  renderValue={() => defaultstatus}
                  label="InvStatus"
                  onChange={(e: any) =>
                    handleValueChange("shipTerminal", e.target.value)
                  }
                  error={report.ShipTerminal ? false : true}
                >
                  {shipTerminalDD.map((d) => (
                    <MenuItem key={d.Key} value={d.Key}>
                      {d.Value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Berth"
                value={report.Berth}
                onChange={(e) => handleValueChange("Berth", e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={1} sx={{ pl: 2, mt: 2 }}>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                label="Ship Name"
                value={report.ShipName}
                onChange={(e) => handleValueChange("shipName", e.target.value)}
              />
            </Grid>

            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                fullWidth
                margin="none"
                type="datetime-local"
                label="Date and Time of Ship Terminal"
                defaultValue={convertDate(report.ArrivalDateTime)}
                InputLabelProps={{ shrink: true }}
                onChange={(e) =>
                  handleValueChange("arrivalDateTime", e.target.value)
                }
                // error={!report.ArrivalDateTime && editReport !== null}
                // helperText={
                  // !report.ArrivalDateTime && editReport !== null
                    // ? "Required"
                    // : ""
                // }
              />
            </Grid>
            <Grid item xs={4} sx={{ pr: 2 }}>
              <TextField
                type="file"
                inputProps={{ accept: ".pdf,.jpg,.jpeg,.png,.doc,.docx" }}
                onChange={(e) => handleValueChange("fileContent", e)}
                fullWidth
                error={isError ? true : false}
                helperText={isError ? errorMsg : ""}
                disabled={fileBase64 ? true : false}
              />
            </Grid>
          </Grid>

          {fileBase64 && (
            <div className="flex flex-row justify-center items-center m-2">
              <FilePreview base64Data={fileBase64} />
              <IconButton
                onClick={() => {
                  setFileBase64("");
                }}
              >
                <DeleteIcon fontSize="small" color="error" />
              </IconButton>
            </div>
          )}
        </div>
      </div>

      <Snackbar
        open={alertOpen}
        autoHideDuration={7000}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <Grid
        container
        spacing={1}
        sx={{ pl: 2, mt: 2, justifyContent: "flex-end" }}
      >
        <Grid item xs={8} sx={{ pr: 2 }}></Grid>
        <Grid item xs={2} sx={{ pr: 2 }}>
          <ButtonGroup fullWidth size="large" color="inherit">
            <Button type="submit" onClick={handleSubmit}>
              Submit
            </Button>
            <Button>CLEAR</Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ShipEntryInputForm;
