import React, { useState } from "react";
import PrisonReleaseSearchInfo from "../../components/tools/prisoner_information/_prisonerInformationSearch";
import SearchResults from "../../components/tools/prisoner_information/_searchResultsPrisonerInformation";
import { GetDarInfo_Query, PersonInfo, getDarInfoResponse } from "../../interfaces/getDarInfo_interface";
import { RunDarQuery } from "../../services/getDar.service";
import { Account } from "../../interfaces/auth_interface";
import {getUser, isUserPermission} from "../../services/auth.service";
import {handleSearchTime} from "../../services/formatDate.service";

function PrisonReleaseSearch() {
    let [darTbl, setDarTbl] = useState<PersonInfo[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)

    const SubmitSearch = async (params: any, dateType: string, andOrType: string, dropDowns: any) => {
        let search_query: GetDarInfo_Query = {
            "QueryType": "9",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": (dateType === "Entry Date") ? 0 : 1,
            "FromDate": handleSearchTime(params?.fromDate, 'fromDate')?.toLocaleString(),
            "ToDate": handleSearchTime(params?.toDate, 'toDate')?.toLocaleString(),
            "SearchType": andOrType,
            "IndicatorOn": true,
            "TopArrestNumber": 0,
            "FNames": params.FirstName ? params.FirstName.split(',') : [],
            "MNames": [],
            "LNames": params.LastName ? params.LastName.split(',') : [],
            "DOBs": [],
            "SEX": [],
            "Address": "",
            "Cities": [],
            "States": [],
            "Zip": "",
            "Statutes": [],
            "Gang": [],
            "FBIs": params.FBI ? params.FBI.split(',') : [],
            "SBIs": params.SBI ? params.SBI.split(',') : [],
            "DLNos": [],
            "PlateNos": [],
            "VINs": [],
            "AgencyArrested": dropDowns.AgencyArrested ? dropDowns.AgencyArrested : [],
            "InfocopDept": [],
            "Indicators": dropDowns.Indicator ? dropDowns.Indicator : [],
            "IndicatorOccurrence": dropDowns.IndicatorOccurrence ? dropDowns.IndicatorOccurrence : null,
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": dropDowns.ResidentCounty ? dropDowns.ResidentCounty : [],  // jurisdiction county
            "ArrestedCounty": dropDowns.ArrestedCounty ? dropDowns.ArrestedCounty : [],
            "Arrest_Type": [],
            "CaseNumbers": [],
            "FTSIDs": [],
            "SystemCall": params.systemCall ?? false,
        }
        let qRes: getDarInfoResponse = await RunDarQuery(search_query)
        setDarTbl([])
        setDarTbl(qRes.AnyTable)

        if (qRes?.AnyTable?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }

    return (
        <div id="ToolsArrest">
            {isUserPermission('Arrest')?
                <>
                    <PrisonReleaseSearchInfo SubmitSearch={SubmitSearch} />
                    {!noData ? <SearchResults Persons={darTbl} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}
                </>
            :<></>}
        </div>
    );
}

export default PrisonReleaseSearch;
