import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import {
  NJPortDashboardDataApi,
  VolunteerDashboardDataApi,
} from "../../../services/njport.dashboard.service";

interface VolunteerRequestProps {
  data?: any;
}

const VolunteerCrossHarbor: React.FC<VolunteerRequestProps> = () => {
  const [chartData, setChartData] = useState<any>([]);

  useEffect(() => {
    const payload = {
      queryType: "VOLUNTEERHIRE_MONTH_START",
      dateTypeValue: 1,
    };
    NJPortDashboardDataApi(payload).then((qRes) => {
      console.log(qRes);
      const filteredData = qRes?.AnyTable?.filter(
        (item: any) => item.StateID === "NY" || item.StateID === "NJ"
      );


      const groupedData = filteredData.reduce((acc: any, item: any) => {
        if (item.HiringDateTime && item.Requested !== null) {
          const port = item.StateID;
            if (!acc[port]) {
              acc[port] = 0;
            }
            acc[port] += item.Requested;

          // const month = new Date(item.HiringDateTime).toISOString().slice(0, 7);
          // if (!acc[month]) {
          //   acc[month] = 0;
          // }
          // acc[month] += item.Requested;
        }

        return acc;
      }, { NY: 0, NJ: 0 });

      const formattedData = Object.keys(groupedData).map((month) => ({
        name: month,
        value: groupedData[month],
      }));
      setChartData(formattedData);
    });
  }, []);

  // useEffect(() => {

  //   const fetchDataForMonths = async () => {
  //     let allData: any[] = [];
  //     let currentDate = new Date();

  //     for (let i = 0; i < 6; i++) {
  //       let { firstDay, lastDay } = getMonthRange(
  //         new Date(currentDate.getFullYear(), currentDate.getMonth() - i),
  //       );

  //       let params = {
  //         dateTypeValue: 0,
  //         dateRange: true,
  //         fromDate: firstDay,
  //         toDate: lastDay,
  //         queryType: "VOLUNTEERHIRE_MONTH_START",
  //       };
  //       const qRes = await VolunteerDashboardDataApi(params);
  //       allData = [...allData, ...qRes?.AnyTable];
  //     }

  //     return allData;
  //   };

  //   fetchDataForMonths().then((allData) => {

  //     setChartData(formattedData);
  //   });
  // }, []);

  const handleSeries = () => {
    let series: any = [];
    series.push({
      name: "PDO Hire",
      type: "pie",
      radius: "50%",
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: "rgba(0, 0, 0, 0.5)",
        },
      },
      data: chartData,
    });

    return series;
  };

  var option = {
    tooltip: {
      trigger: "item",
    },
    height: "180",
    width: "95%",
    toolbox: {
      feature: {
        saveAsImage: { show: true },
      },
    },
    series: handleSeries(),
  };

  return (
    <>
      {chartData && chartData.length !== 0 ? (
        <div>
          <ReactECharts option={option} style={{ height: "160px" }} />
        </div>
      ) : (
        <div
          className={"text-center text-3xl mt-6 mb-1"}
          style={{ color: "red", fontWeight: 500 }}
        >
          No Data
        </div>
      )}
    </>
  );
};

export default VolunteerCrossHarbor;
