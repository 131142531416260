import React, { useState, useEffect } from "react";
import {TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, ButtonGroup} from "@mui/material";
import ArrestDropDowns from "../arrest/_arrestDropDowns";
import ErrorIcon from "@mui/icons-material/Error";
import GeoCode_Position from "../../shared/GeoCodes/_GeoCode_GetPos";
import MapIcon from "@mui/icons-material/Map";
import {formatInputDate} from "../../../services/formatDate.service";
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";
import { SiteName } from "../../../services/config.service";

interface PersonNearbySearchProps {
    SubmitSearch: any
}

const PersonNearbySearch: React.FC<PersonNearbySearchProps> = ({ SubmitSearch }) => {
    let today= new Date()
    let yearsAgoDate = new Date();
    yearsAgoDate.setFullYear(yearsAgoDate.getFullYear() - 5);
    yearsAgoDate.setDate(yearsAgoDate.getDate() + 1);
    const [params, setParams] = React.useState({ Address: "", City: "", State: "", Zip: "", Lat: "", Long: "", Radius: (SiteName == SITE.DCPROD || SiteName == SITE.DCDEMO || SiteName == SITE.DCLOCAL) ? "0.25" : "1", ToDate: formatInputDate(today), FromDate: formatInputDate(yearsAgoDate), systemCall:false })
    const [dateType, setDateType] = React.useState<string>("Residency");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<number>(0);
    const [showGeoCode, setShowGeoCode] = React.useState(false)
    const [submitError, setSubmitError] = React.useState<string>("")
    
    const openGeoCode = () => {
        setShowGeoCode(true)
    }

    const HandleGeoCodeClose = () => {
        setShowGeoCode(false)
    }

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns: any) => {
        setDropDownParams(dropDowns)
    }
    
    const handleSetLatLong = (lat:any, lng:any) => {
        setParams({ ...params, Address: params.Address, City: params.City, State: params.State, Zip: params.Zip, Lat: lat, Long: lng, Radius: params.Radius, ToDate: params.ToDate, FromDate: params.FromDate, systemCall: params.systemCall  })  
    }
                              
    const handleClear = () => {
        setParams({ ...params, Address: "", City: "", State: "", Zip: "", Lat: "", Long: "", Radius: (SiteName == SITE.DCPROD || SiteName == SITE.DCDEMO || SiteName == SITE.DCLOCAL) ? "0.25" : "1", ToDate: formatInputDate(today), FromDate: formatInputDate(yearsAgoDate), systemCall:false })
        setDateType("Residency")
        setClear(clear + 1)
        setSubmitError("")
    }

    const handleSearch = () => {
        if (handleValidation()) {
            let statutes = []
            for (const [key, options] of Object.entries(dropDownParams.Statute)) {
                if(!key.includes('BT_UNIQUE_GROUP'))
                    statutes.push(key)
            }
            dropDownParams.Statute = statutes
            SubmitSearch(params, dateType, dropDownParams)
        }
    }

    const handleValidation = () => {
        setSubmitError("")
        let val = true

        if (params?.Lat?.length === 0 || params?.Long?.length === 0) {
            setSubmitError("Please click GEOCODE to generate Latitude and Longitude");
            return false
        }

        return val
    }
    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <div className="grid grid-cols-7 gap-x-4 gap-y-4" >
                <div>
                    <ToggleButtonGroup
                        color="primary"
                        value={dateType}
                        exclusive
                        size="small"
                        fullWidth
                        onChange={handleDateType}
                        sx={{ mr: 2 }}
                    >
                        <ToggleButton value="Incident" sx={{ pr: 2, pl: 2 }} >Incident</ToggleButton>
                        <ToggleButton value="Residency" sx={{ pr: 2, pl: 2 }} >Residency</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div><TextField margin='none' size={"small"} fullWidth type="text" label="Address" value={params.Address} onChange={e => setParams({ ...params, Address: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth type="text" label={GetStringNameForSite(SiteName,"CITY")} value={params.City} onChange={e => setParams({ ...params, City: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth type="text" label="State" value={params.State} onChange={e => setParams({ ...params, State: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth type="number" label="Zip" value={params.Zip} onChange={e => setParams({ ...params, Zip: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth type="number" label="Radius ( in miles )" value={params.Radius} onChange={e => setParams({ ...params, Radius: e.target.value })} /></div>
                <div>
                    <Button variant="outlined" fullWidth color="inherit" size={"large"} onClick={openGeoCode}>
                        <span><MapIcon color="success" style={{ fontSize: "20" }} className="mr-1" />GeoCode</span>
                    </Button>
                    {(showGeoCode) ? <div><GeoCode_Position SetLatLng={(lat:any,lng:any)=>{handleSetLatLong(lat,lng)}} HandleGeoCodeClose={HandleGeoCodeClose} show={showGeoCode} pin={{lat:params.Lat, lng:params.Long, address: params.Address, city:params.City, state:params.State, zip:params.Zip}} /></div>:<></>}
                </div>
                <div><TextField margin='none' size={"small"} fullWidth type="number" label="Latitude" value={params.Lat} onChange={e => setParams({ ...params, Lat: e.target.value })} /></div>
                <div><TextField margin='none' size={"small"} fullWidth type="number" label="Longitude" value={params.Long} onChange={e => setParams({ ...params, Long: e.target.value })} /></div>
                
            </div>
            <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{ "marginTop": "25px" }}>
                <div><ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"nearby"} /></div>
            </div>
            <div className={"pt-3"} style={{"display":"flex", "justifyContent":"end"}}>
                <ButtonGroup size="large" color="inherit" >
                    <Button  type="submit" onClick={handleSearch}>Search</Button>
                    <Button  onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
            <span className="col-span-4">{submitError?.length > 0 ? <span style={{ color: "red", fontSize: 14 }}><ErrorIcon style={{ color: "red" }} fontSize='small' />{submitError}</span> : <></>}</span>
        </div>
    );
}

export default PersonNearbySearch;