import {Button, ButtonGroup, Checkbox, TextField, ToggleButton, ToggleButtonGroup} from "@mui/material";
import React from "react";
import ArrestDropDowns from "../arrest/_arrestDropDowns";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import {formatInputDate} from "../../../services/formatDate.service";

interface FrequentOffenderSearchProps {
    SubmitSearch: any
}

const FrequentOffenderSearch: React.FC<FrequentOffenderSearchProps> = ({SubmitSearch}) => {
    let today:any = new Date()
    let date:any = new Date()
    date.setFullYear(date.getFullYear() - 1)
    const [params, setParams] = React.useState({toDate:formatInputDate(today), fromDate:formatInputDate(date), systemCall:false})
    const [top, setTop] = React.useState<number>(50)
    const [dateType, setDateType] = React.useState<string>("Arrest Date");
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<number>(0);
    const [initialRun, setInitialRun] = React.useState<boolean>(false)

    React.useEffect(() => {
        if(!initialRun)
        {
            setInitialRun(true)
            let p = {...params}
            p.systemCall = true
            SubmitSearch(p, dateType, andOrType, dropDownParams, top)
        }
    }, [])
    
    
    const handleSearch = () => {
        let dropDownParam = {...dropDownParams};
        dropDownParam.Statute = Object?.keys(dropDownParam.Statute)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        dropDownParam.City = Object?.keys(dropDownParam.City)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        SubmitSearch(params, dateType, andOrType, dropDownParam, top)
    }

    const handleClear = () => {
        setParams({...params, toDate:formatInputDate(today), fromDate:formatInputDate(date), systemCall:false})
        setTop(50)
        setDateType("Arrested Date")
        setAndOrType("AND")
        setClear(clear+1)
    }

    const handleParamChange = (e:any) => {
        setTop(e.target.value)
    }

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if(dateType){
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns:any) => {
        setDropDownParams(dropDowns)
    }

    const handleAndOrType = (event: React.MouseEvent<HTMLElement>, andOrType: string) => {
        if(andOrType)
            setAndOrType(andOrType);
    };


    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <div className="grid grid-cols-7 gap-x-4 gap-y-2">
                <ToggleButtonGroup
                    color="primary"
                    value={dateType}
                    exclusive
                    size="small"
                    fullWidth
                    onChange={handleDateType}
                    sx={{mr: 2 }}
                >
                    <ToggleButton value="Offense Date" sx={{ pr: 2, pl: 2 }} >Offense Date</ToggleButton>
                    <ToggleButton value="Arrest Date" sx={{ pr: 2, pl: 2 }} >Arrest Date</ToggleButton>
                </ToggleButtonGroup>
                <div >
                    <TextField variant="outlined" margin='none' type={"date"} fullWidth
                               size={"small"} label="From Date"
                               InputLabelProps={{shrink: true}}
                               inputProps={{style: {textAlign: 'center'}}}
                               value={params.fromDate}
                               onChange={(e: any) => {
                                   setParams({...params, fromDate: e.target.value})
                               }}
                    />
                </div>
                <div >
                    <TextField variant="outlined" margin='none' type={"date"} fullWidth
                               size={"small"} label="To Date"
                               InputLabelProps={{shrink: true}}
                               inputProps={{style: {textAlign: 'center'}}}
                               value={params.toDate}
                               onChange={(e: any) => {
                                   setParams({...params, toDate: e.target.value})
                               }}
                    />
                </div>
                <div><TextField margin='none' size={"small"} fullWidth label="Top #" type="number" value = {top} onChange={handleParamChange}  /></div>
            </div>
            <div className="grid grid-cols-1 gap-y-2" style={{"marginTop":"25px"}}>
                <div><ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"frequent"}/></div>
            </div>
            <div className={"pt-3"} style={{"display":"flex", "justifyContent":"end"}}>
                <ButtonGroup size="large" color="inherit" >
                    <Button  type="submit" onClick={handleSearch}>Search</Button>
                    <Button  onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
        </div>
        );
}

export default FrequentOffenderSearch