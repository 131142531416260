import React, {useState} from 'react'
import {
    Button, ButtonGroup, ToggleButton, ToggleButtonGroup, Tooltip,
} from "@mui/material";
import {
    ALPRIncidents, ALPRLocationItem,
    ALPRMultipleOccurenceResult,
    DeviceMultipleOccurrenceResult,
    PersonInfo
} from "../../../interfaces/getDarInfo_interface";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties, SortSettingsModel
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import {JAOS} from "../../../services/JAOS.service";
import icon_car from "../../../assets/Images/Vehicle.png"
import Marquee from "react-fast-marquee";
import {getUser, isUserPermission} from '../../../services/auth.service';
import {DEVICELOCATION_URL, SiteName} from "../../../services/config.service";
import {Account} from "../../../interfaces/auth_interface";
import PersonIcon from "../../../assets/Images/Person.png";
import VehicleIcon from "../../../assets/Images/Vehicle.png";
import DeviceIcon from "../../../assets/Images/DeviceSmall.png";
import MapIcon from "@mui/icons-material/Map";
import GeoCode from "../../shared/GeoCodes/_GeoCode_Display";
import {SITE} from "../../../interfaces/config.interface";

interface ALPRMultipleOccurrenceResultsProps {
    ALPRData: ALPRMultipleOccurenceResult
    DeviceData: DeviceMultipleOccurrenceResult
    SearchType:string
    IncidentList: ALPRIncidents[]
    
}

const ALPRMultipleOccurrenceResults: React.FC<ALPRMultipleOccurrenceResultsProps> = ({ALPRData, DeviceData, SearchType = "Vehicle", IncidentList = []})=> {
    const [typedData, setTypedData] = React.useState<any>({})
    const [typedDeviceData, setTypedDeviceData] = React.useState<any>({})
    const [showGeoCode, setShowGeoCode] = React.useState(false)
    const [mapVersion, setMapVersion] = useState<string>("2")
    const [allPins, setAllPins] = React.useState<any[]>([])
    const [resultType, setResultType] = useState<string>('Vehicle')
    let [user, setUser] = useState<Account>(getUser());
    let grid: Grid | null;


    const handleDataTyping = () =>
    {
        if(SearchType === 'Device')
        {
            setResultType('Device')    
        }
        else
        {
            setResultType('Vehicle')
        }
        
        if(ALPRData && ALPRData.MultipleOccurrenceList) {
            let tempP: any = [...ALPRData.MultipleOccurrenceList]

            for (let p of tempP) {
                if (p.red_TimeStamp) {
                    p.red_TimeStamp = new Date(p.red_TimeStamp)
                }
                if (p.POIReason) {
                    //p.POIReason = p.POIReason.toUpperCase()
                }
            }
            setTypedData(tempP)
        }
        
        if(DeviceData && DeviceData.MultipleOccurrenceResults)
        {
            let temp:any = [...DeviceData.MultipleOccurrenceResults]

            for (let data of temp) {
                if (data.EventDateTimeUTC) {
                    data.EventDateTimeUTC = new Date(data.EventDateTimeUTC)
                }
            }
            setTypedDeviceData(temp)
        }

    }
    
    const handleDeviceMap = (device:any) => {
        setMapVersion("device")
        setAllPins([])
        let temp = DeviceData.AllResults?.filter((x)=> x.DeviceID === device.DeviceID)
        let pins:any[] = []
        if(temp) {
            for (let p of temp) {
                if (p.Latitude && p.Longitude) {
                    pins.push({
                        lat: p.Latitude,
                        lng: p.Longitude,
                        device: p.DeviceID,
                        date: p.EventDateTimeUTC,
                        distance: p.Distance,
                        accuracy: p.HorizontalAccuracy,
                        name: p.IncidentName,
                        tool: "device"
                    })
                }
            }
            
            for (let p = 0; p < IncidentList.length; p++)
            {
                if(IncidentList[p]?.location?.latitude && IncidentList[p]?.location?.longitude)
                {
                    pins.push({
                        lat: IncidentList[p]?.location?.latitude,
                        lng: IncidentList[p]?.location.longitude,
                        date: IncidentList[p].date,
                        address: IncidentList[p]?.location?.address,
                        city: IncidentList[p]?.location?.city,
                        state: IncidentList[p]?.location?.state,
                        zip: IncidentList[p]?.location?.zip,
                        name: (p + 1) + "",
                        tool: "incident"
                    })
                }
            }
            setAllPins(pins)
            setShowGeoCode(true)
        }
    }

    const HandleGeoCodeClose = () => {
        setShowGeoCode(false)
    }
    const handleDeviceLookup = (device:any) =>
    {
        let dev = {
            Latitude:device?.Latitude,
            Longitude:device.Longitude,
            DeviceID:device.DeviceID,
            EventDateTimeUTC:device.EventDateTimeUTC,
            EventTimeMilli: 15
            
        }
        window.open(DEVICELOCATION_URL + "/auth?UserName=" + user.EmpID + "&Dept=" + user.Dept + '&OTP=' + user.eOTP + '&Device=' + btoa(JSON.stringify(dev)), '_blank')
    }

    const ActionTemplate = (device: any): any => {
        return (
            <span>
                <Tooltip title={'Map'} arrow><Button onClick ={() => {handleDeviceMap(device)}}><MapIcon color="success" className ="mr-2"/></Button></Tooltip>
                {isUserPermission('DeviceID') ? <Tooltip title={'Device Search (15 Days)'} arrow><Button onClick ={() => {handleDeviceLookup(device)}}><img src={DeviceIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /></Button></Tooltip> : <></>}
            </span>);
    }

    React.useEffect(() => {
        handleDataTyping()
    },[ALPRData, DeviceData])
    
    const getALPRDetailsSummary = (alpr:any) =>
    {
        let temp = ALPRData.MultipleOccurrenceDetailList?.filter((x)=> x.red_vrm === alpr.red_vrm)
        if(temp) {
            let tempP: any = [...temp]

            for (let p of tempP) {
                if (p.red_TimeStamp) {
                    p.red_TimeStamp = new Date(p.red_TimeStamp)
                }
            }
            temp = tempP
        }
        return (
            <GridComponent
                dataSource={temp}
                allowPaging={true}
                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                allowSorting={true}
                allowMultiSorting={true}
                allowFiltering={true}
                filterSettings={{type: 'CheckBox'}}
                allowTextWrap={true}
                textWrapSettings={{ wrapMode: 'Both' }}
                allowSelection={true}
                allowResizing={true}
                rowHeight={24}
            >
                <ColumnsDirective>
                    <ColumnDirective field='red_vrm' headerText='Plate #'/>
                    <ColumnDirective field='red_TimeStamp' format = {{type:'dateTime', format:'MM/dd/yyyy HH:mm:ss'}} headerText='Time Stamp'/>
                    <ColumnDirective field='POIReasonType' headerText='POI Type'/>
                    <ColumnDirective field='POIReason' headerText='POI Purpose'/>
                    <ColumnDirective field='src_Name' headerText='Source Name'/>
                    <ColumnDirective field='src_Description' headerText='Source Description' />
                    <ColumnDirective field='Latitude' headerText='Latitude' />
                    <ColumnDirective field='Longitude' headerText='Longitude' />
                    {/*<ColumnDirective template={(item: any) => (<>({item.Latitude}, {item.Longitude})</>)} headerText='MSP (Latitude, Longitude)' visible={false} />*/}
                    <ColumnDirective template={(item: any) => (<Button onClick={() => {window.open((item?.ste_Name == "ETicket" ? "/detail/ETicket" : item?.ste_Name == "ParkMobile" ? "/detail/ParkMobile" : "/detail/ALPR") + '?ID=' + item?.LprID + '&Dept=' + item?.src_Name, '_blank') }} >View</Button>)} headerText='View' />
                </ColumnsDirective>
                <Inject services={[Sort, Page, Filter]}/>
            </GridComponent>
        )
    }

    const getDeviceDetailsSummary = (device:any) =>
    {
        let temp = DeviceData.AllResults?.filter((x)=> x.DeviceID === device.DeviceID)
        if(temp) {
            let tempP: any = [...temp]

            for (let data of tempP) {
                if (data.EventDateTimeUTC) {
                    data.EventDateTimeUTC = new Date(data.EventDateTimeUTC)
                }
            }
            temp = tempP
        }
        return (
            <GridComponent
                dataSource={temp}
                allowPaging={true}
                pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                allowSorting={true}
                allowMultiSorting={true}
                allowFiltering={true}
                filterSettings={{type: 'CheckBox'}}
                allowTextWrap={true}
                textWrapSettings={{ wrapMode: 'Both' }}
                allowSelection={true}
                allowResizing={true}
                allowExcelExport={true}
                allowPdfExport={true}
                toolbar={['ExcelExport','Search']}
                toolbarClick={GridToolsClickDevice}
                rowHeight={24}
            >
                <ColumnsDirective>
                    <ColumnDirective field='IncidentName' headerText='Incident #' width={100}/>
                    <ColumnDirective field='DeviceID' headerText='Device ID'/>
                    <ColumnDirective field='EventDateTimeUTC' format = {{type:'dateTime', format:'MM/dd/yyyy HH:mm:ss'}} headerText='Time Stamp'/>
                    <ColumnDirective field='DeviceOS' headerText='Device Type'/>
                    <ColumnDirective field='Latitude' headerText='Latitude' format={'N6'}/>
                    <ColumnDirective field='Longitude' headerText='Longitude' format={'N6'}/>
                    <ColumnDirective field='HorizontalAccuracy' headerText='Horizontal Accuracy' format={'N0'}/>
                </ColumnsDirective>
                <Inject services={[Sort, Page, Filter, Toolbar, ExcelExport,PdfExport]}/>
            </GridComponent>
        )
    }
    
    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            let excelProp:ExcelExportProperties = {}
            excelProp.fileName = 'MultipleOccurrence.xlsx'
            grid.excelExport(excelProp);

        }else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const GridToolsClickDevice = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            let excelProp:ExcelExportProperties = {}
            excelProp.fileName = 'CommonSearchDevices.xlsx'
            grid.excelExport(excelProp);

        }else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }
    
    const dataBound = () => {
        if(grid){
            grid.autoFitColumns([])
        }
    }

    const expandRow = () => {
        if(grid)
        {
            const selectedrowindex: number[] = grid.getSelectedRowIndexes()
            for (let idx of selectedrowindex) {
                grid.detailRowModule.expand(idx)
            }
        }
    }

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'red_vrm', direction: 'Ascending' }
        ] };
    
    let deviceSortSettings: SortSettingsModel = { columns: [
            {field: 'EventDateTimeUTC', direction: 'Ascending' }
        ] };

    const searchPlates = () =>  {
        if(typedData)
        {
            let obj = {Plates:""}
            if(typedData)
            {
                for (let p of typedData){
                    if(p.red_vrm)
                    {
                        obj.Plates += p.red_vrm + ",";
                    }
                }
            }

            if(obj.Plates.length !== 0) {
                let l = new JAOS()
                let hashID = l.objToStack(obj)
                window.open('/tools/arrest?personList=' + hashID, '_blank')
            }
        }
    }

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setResultType(dateType);
        }
    };
    
    return (
        <div className={"pl-10 pr-10 mt-5 w-full"}>
            {(showGeoCode) ? <div><GeoCode HandleGeoCodeClose={HandleGeoCodeClose} show={showGeoCode} pins={allPins} mapVersion={mapVersion} exportData={"exportData"}/></div>:<></>}

            {(SiteName == SITE.NJPROD || SiteName == SITE.NJUAT || SiteName == SITE.NJDEMO || SiteName == SITE.LOCAL) && <>
                {(ALPRData?.MultipleOccurrenceList && ALPRData?.MultipleOccurrenceList?.length>0) || (DeviceData?.MultipleOccurrenceResults && DeviceData?.MultipleOccurrenceResults?.length>0) && isUserPermission('DeviceID') ?
                    <div className="col-span-1 mb-2">
                        <ToggleButtonGroup
                            color="primary"
                            value={resultType}
                            exclusive
                            size="small"
                            onChange={handleDateType}
                        >
                            <ToggleButton value="Vehicle" sx={{ pr: 2, pl: 2 }} disabled={SearchType === 'Device'}>Vehicle</ToggleButton>
                            <ToggleButton value="Device" sx={{ pr: 2, pl: 2 }} disabled={SearchType === 'Vehicle'}>Device</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    :<></>}
            </>}
            
            
            
            {ALPRData?.MultipleOccurrenceList && resultType === 'Vehicle' ? <>
                <span style={{ display: "inline-block" }} className='mb-5'>
                    <h4 className={"font-bold inline mr-5 text-lg"}> {ALPRData?.MultipleOccurrenceList && ALPRData?.MultipleOccurrenceList?.length > 0 ? ALPRData?.MultipleOccurrenceList?.length : 'NO Matching'} Records Found</h4>
                    {ALPRData?.MultipleOccurrenceList?.length > 0 ?
                        <ButtonGroup size="medium" color="inherit" >
                             <Button variant="outlined" onClick={searchPlates}>
                                <img src={icon_car} className ="mr-2"/><b>Vehicle Connection</b>
                            </Button>
                        </ButtonGroup>
                    :<></>}
                </span>
                {ALPRData?.MultipleOccurrenceList?.length > 0 ?
                    <GridComponent
                        dataSource={typedData}
                        allowPaging={true}
                        pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                        allowSorting={true}
                        sortSettings={sortSettings}
                        allowMultiSorting={true}
                        allowFiltering={true}
                        filterSettings={{type: 'CheckBox'}}
                        detailTemplate={getALPRDetailsSummary}
                        allowTextWrap={true}
                        textWrapSettings={{ wrapMode: 'Both' }}
                        allowExcelExport={true}
                        allowPdfExport={true}
                        toolbar={['ExcelExport','Search']}
                        toolbarClick={GridToolsClick}
                        ref={g=> grid = g}
                        selectionSettings={{
                            persistSelection: true,
                            type: "Multiple",
                            //checkboxOnly: true,
                            mode: "Row"}}
                        rowSelecting = {(event:any) => {console.log(event)}}
                        rowSelected = {(event:any) => {expandRow()}}
                        allowSelection={true}
                        allowResizing={true}
                        rowHeight={24}
                    >
                        <ColumnsDirective>
                            <ColumnDirective field='red_vrm' headerText='Plate Number'/>
                            <ColumnDirective field='red_TimeStamp' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm' }} headerText='Time Stamp' />
                            <ColumnDirective field='POIReasonType' headerText='POI Type' />
                            <ColumnDirective field='POIReason' headerText='POI Purpose' />
                            <ColumnDirective field='src_Name' headerText='Source Name' />
                            <ColumnDirective field='Latitude' headerText='Latitude' />
                            <ColumnDirective field='Longitude' headerText='Longitude' />
                            <ColumnDirective field='Occurrence' headerText='Occurrence' />
                      
                        </ColumnsDirective>
                        <Inject services={[Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search]}/>
                    </GridComponent>
                :<></>}
            </>:<></>}

            
            {DeviceData?.MultipleOccurrenceResults && resultType === 'Device' ? <>
                <span style={{ display: "inline-block" }} className='mb-5'>
                    <h4 className={"font-bold inline mr-5 text-lg"}> {DeviceData.MultipleOccurrenceResults && DeviceData.MultipleOccurrenceResults?.length > 0 ? DeviceData.MultipleOccurrenceResults?.length : 'NO Matching'} Records Found</h4>
                        {/*<ButtonGroup size="medium" color="inherit" >
                             <Button variant="outlined" onClick={searchPlates}>
                                <img src={icon_car} className ="mr-2"/><b>Vehicle Connection</b>
                            </Button>
                    </ButtonGroup>*/}
                </span>
                {DeviceData?.MultipleOccurrenceResults?.length > 0 ?
                    <GridComponent
                        dataSource={typedDeviceData}
                        allowPaging={true}
                        pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                        allowSorting={true}
                        sortSettings={deviceSortSettings}
                        allowMultiSorting={true}
                        allowFiltering={true}
                        filterSettings={{type: 'CheckBox'}}
                        detailTemplate={getDeviceDetailsSummary}
                        allowTextWrap={true}
                        textWrapSettings={{ wrapMode: 'Both' }}
                        allowExcelExport={true}
                        allowPdfExport={true}
                        toolbar={['ExcelExport','Search']}
                        toolbarClick={GridToolsClickDevice}
                        ref={g=> grid = g}
                        selectionSettings={{
                            persistSelection: true,
                            type: "Multiple",
                            //checkboxOnly: true,
                            mode: "Row"}}
                        rowSelecting = {(event:any) => {console.log(event)}}
                        rowSelected = {(event:any) => {expandRow()}}
                        allowSelection={true}
                        allowResizing={true}
                        rowHeight={24}
                    >
                        <ColumnsDirective>
                            <ColumnDirective field='DeviceID' headerText='Device ID'/>
                            <ColumnDirective field='EventDateTimeUTC' format = {{type:'dateTime', format:'MM/dd/yyyy HH:mm:ss'}} headerText='Time Stamp'/>
                            <ColumnDirective field='DeviceOS' headerText='Device Type'/>
                            <ColumnDirective field='Latitude' headerText='Latitude' format={'N6'}/>
                            <ColumnDirective field='Longitude' headerText='Longitude' format={'N6'}/>
                            <ColumnDirective field='Distance' headerText='Occurrence'/>
                            <ColumnDirective template={ActionTemplate} headerText='Actions' width={150}/>
                        </ColumnsDirective>
                        <Inject services={[Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search]}/>
                    </GridComponent>
                :<></>}
            </>:<></>}
            
        </div>
    );
};



export default ALPRMultipleOccurrenceResults;