//JAOS: JUST ANOTHER OBJECT STORAGE 
export class JAOS {
    
    private generateHash():string{
        let EpochTime: string = new Date().getTime().toString()
        return (Math.random().toString(36).substring(2))+EpochTime;
    }

    private CleanText(text:string): string {
        return text.replace(/[\x00-\x08\x0E-\x1F\x7F-\uFFFF]/g, '');
    }
    
     objToStack (obj:any) : string {
        let hash = this.generateHash();
        
        while(sessionStorage.getItem(hash) !== null)
            hash = this.generateHash();

         try {
             sessionStorage.setItem('JAOS'+hash, btoa(this.CleanText(JSON.stringify(obj))))
         } catch (e:any) {
             this.clearStack()
             sessionStorage.setItem('JAOS'+hash, btoa(this.CleanText(JSON.stringify(obj))))
         }
        return hash;
    }
    
    objFromStack(hash:string): any {
        let obj:string | null = sessionStorage.getItem('JAOS'+hash)
        return obj? JSON.parse(atob(obj)):{};
    }
    
    clearStack(): void {
        Object.keys(sessionStorage).forEach((key) => {
            if(key.includes('JAOS'))
                sessionStorage.removeItem(key)
        });
    }
    
}