import React, { useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import { NJPortDashboardDataApi } from "../../../services/njport.dashboard.service";

interface VolunteerNotHiredProps {}

const VolunteerNotHired: React.FC<VolunteerNotHiredProps> = ({}) => {
  const [notHiredData, setNotHiredData] = useState<any>([]);

  useEffect(() => {
    const payload = {
      queryType: "VOLUNTEERHIRE_WEEK",
      dateTypeValue: 1,
    };
    NJPortDashboardDataApi(payload).then((qRes) => {
      setNotHiredData(qRes?.AnyTable);
    });
  }, []);

  var option;

  const handleFormatXAxis = () => {
    let xAxis: any = [];
    if (notHiredData) {
      let currentDate = new Date();
      for (let i = 6; i >= 0; i--) {
        let date = new Date();
        date.setDate(currentDate.getDate() - i);
        let formattedDate = date.toISOString().split("T")[0];
        xAxis.push(formattedDate);
      }
    }
    return xAxis;
  };

  const handleSeries = () => {
    let stolen: any = [];
    let series: any = [];
    if (notHiredData) {
      for (let d of notHiredData) {
        stolen.push(d.Remaining);
      }
    }
    series.push({
      type: "bar",
      color: "#1e3a8a",
      emphasis: {
        focus: "series",
      },
      label: {
        show: true,
        position: "inside",
        rotate: 20,
        fontSize: 11,
      },
      data: stolen,
    });

    return series;
  };

  option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    toolbox: {
      feature: {
        saveAsImage: { show: true },
      },
    },
    legend: {},
    grid: {
      left: "1%",
      right: "1%",
      bottom: "1%",
      top: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      axisLabel: {
        interval: 0,
        rotate: 45,
        fontSize: 8,
      },
      data: handleFormatXAxis(),
    },
    yAxis: {
      type: "value",
    },
    series: handleSeries(),
  };

  return (
    <>
      {notHiredData && notHiredData?.length !== 0 ? (
        <div>
          <ReactECharts option={option} style={{ height: "300px" }} />
        </div>
      ) : (
        <div
          className={"text-center text-base xl:text-3xl mt-6 mb-1"}
          style={{ color: "red", fontWeight: 500 }}
        >
          No Data
        </div>
      )}
    </>
  );
};

export default VolunteerNotHired;
