import React from 'react'
import BTPersonReport from "../../shared/_BTPersonReport";
import { PersonInfo } from "../../../interfaces/getDarInfo_interface";

import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import PersonIcon from "../../../assets/Images/Person.png";
import {showCrumbScore, SiteName} from '../../../services/config.service';
import BTPersonGridImage from "../../shared/_PersonGridImage";
import BTPersonIndicatorGrid from "../../shared/_PersonIndicators";
import {getValue} from "@syncfusion/ej2-base";
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";
interface SearchResultsProps {
    NJTracePerson: PersonInfo[]
}

const SearchResultsPerson: React.FC<SearchResultsProps> = ({ NJTracePerson }) => {
    const [typedPerson, setTypedPersons] = React.useState<any>({})

    let grid: Grid | null;
    let selectedRows: any[] = [];

    const miniPersonImage = (person: any): any => {
        return (
            <BTPersonGridImage person={person} />
        )
    }
    const IndicatorTemplate = (person: any): any => {
        return (
            <BTPersonIndicatorGrid person={person} />
        );
    }

    const getPersonSummary = (p: any) => {
        return (<div style={{maxWidth:"80%"}}><BTPersonReport person={p} /></div>)
    }

    const handleDataTyping = () => {
        if (NJTracePerson) {
            let tempP: any = [...NJTracePerson]

            for (let p of tempP) {
                if (p.DOB) {
                    p.DOB = new Date(p.DOB)
                }
                if (p.DTOFARREST) {
                    p.DTOFARREST = new Date(p.DTOFARREST)
                }
                if (p.DTOFUPDATE) {
                    p.DTOFUPDATE = new Date(p.DTOFUPDATE)
                }
            }
            setTypedPersons(tempP)
        }

    }

    React.useEffect(() => {
        handleDataTyping()
    }, [NJTracePerson])

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            let excelProp: ExcelExportProperties = {includeHiddenColumn:true}
            excelProp.fileName = 'NJTracePerson.xlsx';
            (grid.getColumnByField("image") as Column).visible = false;               
            grid.excelExport(excelProp);
            
        } 
        // else if (grid && args.item.text === 'PDF Export') {
        //     grid.pdfExport();
        // }
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.getColumnByField("image") as Column).visible = true;
        }
    }
    
    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([])
        }
    }

    const expandRow = (args:any) => {
        // if (grid) {
        //     const selectedrowindex: number[] = grid.getSelectedRowIndexes()
        //     for (let idx of selectedrowindex) {

        //         grid.detailRowModule.expand(idx)
        //     }
        // }
        let rows = [...selectedRows];

           if  (args?.data && args?.data?.length !== typedPerson.length) {
               var result = rows.findIndex(x => x.PersonID === args?.data.PersonID)
               if (result === -1) {
                   let person = args?.data;
                   if (person && person.PersonID) {
                       rows.push(person)
                   }
               }
               else {
                   rows.splice(result,1)
               }
           }
       
       selectedRows=rows
    }

    return (
        <div className={"pl-10 pr-10 mt-5 border-2 border-gray-100 pb-5 pt-5"}>
            <span style={{display:"inline-block", width:"full"}}>
                NJTrace Person Information <img className={"inline ml-2"} src={PersonIcon} alt="ai" />
                {(NJTracePerson && NJTracePerson?.length > 0 && NJTracePerson[0]?.LName !== null) ? <h4 className={"font-bold pl-5"}> {NJTracePerson.length} Records Found</h4> : <h4 className={"font-bold pl-5"}>No Records Found</h4>}
            </span>
            {NJTracePerson && NJTracePerson?.length > 0 && NJTracePerson[0]?.LName !== null ? <>
            <GridComponent
                dataSource={typedPerson}
                allowPaging={true}
                dataBound={dataBound}
                pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                allowSorting={true}
                allowMultiSorting={true}
                allowFiltering={true}
                filterSettings={{ type: 'CheckBox' }}
                detailTemplate={getPersonSummary}
                allowTextWrap={true}
                textWrapSettings={{ wrapMode: 'Both' }}
                allowExcelExport={true}
                allowPdfExport={true}
                toolbar={['ExcelExport','Search']}
                toolbarClick={GridToolsClick}
                excelExportComplete={excelExportComplete}
                ref={g => grid = g}
                selectionSettings={{
                    persistSelection: true,
                    type: "Multiple",
                    //checkboxOnly: true,
                    mode: "Row"
                }}
                //rowSelecting={(event: any) => { console.log(event) }}
                rowSelected={(event: any) => { expandRow(event) }}
                allowSelection={true}
                allowResizing={true}
                rowHeight={24}
                rowDataBound={(args : any) => {
                    if (args.row) {
                        if (getValue('FoundJail', args.data)  === 2){
                            args.row.classList.add('red');
                        }
                    }
                }}
            >
                <ColumnsDirective>
                    <ColumnDirective field='image' template={miniPersonImage} maxWidth={120} headerText='Image' customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective field='FName' headerText='First Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='MName' headerText='MI' width={110} minWidth={110} maxWidth={110} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='LName' headerText='Last Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='DOB' headerText='DOB' width={115} minWidth={115} maxWidth={115} format={{ type: 'date', format: 'MM/dd/yyyy' }} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='SERIAL_NUMBERs' headerText='Weapon Serial #(s)' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    { showCrumbScore ? <ColumnDirective field='CrumbScorePercentile' width={180} minWidth={180} maxWidth={180} headerText={`${GetStringNameForSite(SiteName,'SCOR_TBL')}`} customAttributes={{ class: ['e-attr'] }}/>:<></>}
                    <ColumnDirective field='FBI' headerText='FBI #' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='SBINo' headerText='SBI #' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='SBIState' headerText='SBI State' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='DLNo' headerText='DL #' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='SSN1' headerText='SSN' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='SEX' headerText='SEX' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='DLSt' headerText='DL State' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='City' headerText='City' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective template={IndicatorTemplate} headerText='Indicator' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='STATUTE' headerText={`${GetStringNameForSite(SiteName,'STATUTE')}`} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective field='STATUTEDESCRIPTION' headerText='Statute Description' maxWidth={250} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='DTOFARREST' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Date of Arrest' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='ORIGINATINGORINAME' headerText='Agency Arrested' width={180} minWidth={180} maxWidth={180} customAttributes={{ class: ['e-attr'] }} />
                    {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) &&<ColumnDirective field='Dept' maxWidth={200} headerText='InfoCop Dept' customAttributes={{ class: ['e-attr'] }}/>}
                    <ColumnDirective field='FoundALPR' headerText='Associated ALPR' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundArrest' headerText='Associated Arrest' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundCADRMS' headerText='Associated CAD/RMS' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundCODIS' headerText='Associated CODIS' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundCarTheft' headerText='Associated Car Theft' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundCarTheftRecent' headerText='Associated Car Theft Recent' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundDomViolence' headerText='Associated Domestic Violence' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundETicket' headerText='Associated ETicket' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundGunTrace' headerText='Associated Gun Trace' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundHumanTraffic' headerText='Associated Human Traffic' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundInfoCop' headerText='Associated Info Cop' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundJail' headerText='Associated Jail' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundNIBIN' headerText='Associated NIBIN' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundNJGunTrace' headerText='Associated NJGunTrace' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundNJTraceRecent' headerText='Associated NJGunTrace Recent' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundParkMobile' headerText='Associated ParkMobile' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundPhoto' headerText='Associated Photo' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundPrisonParole' headerText='Associated Prison/Parole' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundPrisonRelease' headerText='Associated Prison Release' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundProfession' headerText='Associated Profession' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundProfile' headerText='Associated Profile' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundReferral' headerText='Associated Referral' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundSexCrime' headerText='Associated Sex Crime' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundShooting' headerText='Associated Shooting' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundTowing' headerText='Associated Towing' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundTriggerLock' headerText='Associated TriggerLock' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundViolent' headerText='Associated Violent' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundNJSTAD' headerText='Associated NJSTAD' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundNJTrace_Pos' headerText='Associated NJ Gun Trace Possessor' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundNJTrace_Asc' headerText='Associated  NJ Gun Trace Associate' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundETrace_Pur' headerText='Associated Gun Trace Purchaser' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='FoundNJTrace_Ghg' headerText='Associated NJ Gun Trace Ghost Gun' width={140} minWidth={140} maxWidth={140} visible={false} customAttributes={{ class: ['e-attr'] }} />

                </ColumnsDirective>
                <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder]} />
                </GridComponent>
            </> : <></>}
        </div>

    )
};

export default SearchResultsPerson
