import {AxiosResponse} from "axios";
import {getDarInfoResponse} from "../interfaces/getDarInfo_interface";
import AuthenticatedAPIReqSplash from "./AuthenticatedAPIReqSplash.service";
import {PIRFiles, PIRReport} from "../interfaces/PIR.interface";

export async function SetPIRReport(report: PIRReport): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/PIR/SetPIR', report)
    return (res.data)
}

export async function SetPIRFile(fileObj: PIRFiles): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/PIR/SetPIRFile', fileObj)
    return (res.data)
}

export async function SetPIRSupplement(supplementObj: any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/PIR/SetPIRSupplement', supplementObj)
    return (res.data)
}

export async function SearchPIR(searchObj: any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.post('/api/PIR/SearchPIR', searchObj)
    return (res.data)
}


export async function GetPIR(id:any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.get('/api/PIR/GetPIR?IDs=' + id)
    return (res.data)
}

export async function GetPIRPDF(id:any): Promise<any> {
    let res: AxiosResponse<getDarInfoResponse> = await AuthenticatedAPIReqSplash.get('/api/PIR/GetPIRPDF?IDs=' + id,{responseType:"blob"})
    if (res && res.data) {
        const fileURL = URL.createObjectURL(res.data);
        window.open(fileURL);
    }
}

export async function getCCTVCamerasInRange(latitude:number, longitude:number, radius:string){
    let res: AxiosResponse<any> = await AuthenticatedAPIReqSplash.get('/api/PIR/GetCCTVCamerasInRange?Latitude='+ latitude +'&Longitude='+longitude + '&Radius=' + radius)
    return (res.data)
}

export function GetGUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
        .replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0,
                v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
}