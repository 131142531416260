import React, { useState } from "react";
import GTBMLogo from "../../assets/Images/gtbm.png";
import InfoCopLogo from "../../assets/Images/infocop.png";
import njspLogo from "../../assets/Images/njspLogo.png";
import dcLogo from "../../assets/Images/dc_logo.png";
import uasiLogo from "../../assets/Images/uasiLogo.png";
import NJSnapLogo from "../../assets/Images/NJSnapLogo.png";
import cap5Logo from "../../assets/Images/NJCAP5.png";
import btLogo from "../../assets/Images/bt-logo-transparent.png";
import nysLogo from "../../assets/Images/dcjs-psblue.png"
import FiberNewIcon from '@mui/icons-material/FiberNew';
import { Link } from "react-router-dom";
import { Menu, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Account, AccountTools, DashboardList, Expiry, SupportList, UserProfile } from "../../interfaces/auth_interface";
import {
    getDashboards,
    getExpiry, getSupportTabs, 
    getNJPort,
    getToken,
    getUser,
    getUserProfile,
    getUserTools,
    isUserPermission
} from "../../services/auth.service";
import { useHistory } from "react-router-dom";
import {NJPOP, NJSTAD_URL, SiteName} from "../../services/config.service";
import Marquee from "react-fast-marquee";
import {
    Alert,
    Button,
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select, Snackbar,
    Tooltip,
    Typography
} from "@mui/material";
import {RunPOIList, RunUserExpiryDays} from "../../services/account.service";
import { auto } from "@popperjs/core";
import { BTVERSION } from "../../services/config.service";
import { BTVERSION_RELEASE_DT } from "../../services/config.service";
import { OpenReleaseNotesPDF } from "../../services/detail.service";
import TaxiAlertIcon from '@mui/icons-material/TaxiAlert';
import {AlertColor} from "../tools/njtrace/_njtraceInputForm";
import {GetStringNameForSite, SITE} from "../../interfaces/config.interface";
interface ToolsProps {
    isActive: number,
    ActiveTool: any,
}

const Tools: React.FC<ToolsProps> = ({ isActive, ActiveTool }) => {
    const history = useHistory()
    const [activeTool, setActiveTool] = useState<string>("");
    const [isHover, setIsHover] = useState(false);
    const [hoverTool, setHoverTool] = useState("")

    const handleMouseEnter = (tool: string) => {
        setIsHover(true);
        setHoverTool(tool)
    };
    const handleMouseLeave = () => {
        setIsHover(false);
        setHoverTool("")
    };

    let [userTools, setUserTools] = useState<AccountTools[]>(getUserTools())

    switch (history.location.pathname) {
        case ('/tools/arrest'):
            if (activeTool !== "Arrest")
                setActiveTool("Arrest")
            break;
        case ('/tools/frequent_offender'):
            if (activeTool !== "Frequent Offender")
                setActiveTool("Frequent Offender")
            break;
        case ('/tools/person_nearby'):
            if (activeTool !== "Person Nearby")
                setActiveTool("Person Nearby")
            break;
        case ('/tools/alpr_multiple_occurrence'):
            if (activeTool !== "LPR Common Plate")
                setActiveTool("LPR Common Plate")
            break;
        //case ('/tools/phone_search'):
        //    if (activeTool !== "Phone Search")
        //        setActiveTool("Phone Search")
        //    break;
        case ('/tools/towed_vehicle'):
            if (activeTool !== "Towed Vehicle")
                setActiveTool("Towed Vehicle")
            break;
        case ('/tools/shooting_notification'):
            if (activeTool !== "Shooting Notification")
                setActiveTool("Shooting Notification")
            break;
        case ('/tools/open_case_incident'):
            if (activeTool !== GetStringNameForSite(SiteName, "NAV_TOOL_OPENCASE"))
                setActiveTool(GetStringNameForSite(SiteName, "NAV_TOOL_OPENCASE"))
            break;
        case ('/tools/codis_notification'):
            if (activeTool !== "CODIS Notification")
                setActiveTool("CODIS Notification")
            break;
        case ('/tools/alpr_vin_search'):
            if (activeTool !== "LPR Search")
                setActiveTool("LPR Search")
            break;
        case ('/tools/arrest_analysis'):
            if (activeTool !== "Arrest Analysis")
                setActiveTool("Arrest Analysis")
            break;
        case ('/tools/parole'):
            if (activeTool !== "Parole")
                setActiveTool("Parole")
            break;
        case ('/tools/jail'):
            if (activeTool !== "Jail")
                setActiveTool("Jail")
            break;
        case ('/tools/prisoner_information'):
            if (activeTool !== "Prisoner Information")
                setActiveTool("Prisoner Information")
            break;
        case ('/tools/professional_license'):
            if (activeTool !== "Professional License")
                setActiveTool("Professional License")
            break;
        case ('/tools/profile_report'):
            if (activeTool !== "Profile Report")
                setActiveTool("Profile Report")
            break;
        case ('/tools/statute_mapping'):
            if (activeTool !== GetStringNameForSite(SiteName, "STATUTE") + " Mapping")
                setActiveTool(GetStringNameForSite(SiteName, "STATUTE") + " Mapping")
            break;
        case ('/tools/violent_offender'):
            if (activeTool !== "Violent Offender")
                setActiveTool("Violent Offender")
            break;
        case ('/tools/scor'):
            if (activeTool !== GetStringNameForSite(SiteName, "SCOR") + " Search")
                setActiveTool(GetStringNameForSite(SiteName, "SCOR") + " Search")
            break;
        case ('/tools/scor_variation'):
            if (activeTool !== GetStringNameForSite(SiteName, "SCOR") + " Variation")
                setActiveTool(GetStringNameForSite(SiteName, "SCOR") + " Variation")
            break;
        case ('/tools/shot_spotter'):
            if (activeTool !== "Shot Spotter")
                setActiveTool("Shot Spotter")
            break;
        case ('/ncic'):
            if (activeTool !== "NCIC Search")
                setActiveTool("NCIC Search")
            break;
        case ('/tools/stolen_vehicle'):
            if (activeTool !== "Stolen Vehicle")
                setActiveTool("Stolen Vehicle")
            break;
        case ('/tools/duplicate_person'):
            if (activeTool !== "Duplicate Person")
                setActiveTool("Duplicate Person")
            break;
        case ('/tools/GVRTF_Referral'):
            if (activeTool !== "GVRTF Referral")
                setActiveTool("GVRTF Referral")
            break;
        case ('/tools/carjacking_notification'):
            if (activeTool !== "Carjacking Notification")
                setActiveTool("Carjacking Notification")
            break;
        case ('/tools/trace'):
            let toolName = (SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) ? "DC Trace" : "NJ Trace";
            if (activeTool !== toolName)
                setActiveTool(toolName)
            break;
        case ('/tools/ecrawl'):
            if (activeTool !== "eCRAWL")
                setActiveTool("eCRAWL")
            break;
        case ('/tools/preliminary_report'):
            if (activeTool !== "Preliminary Report")
                setActiveTool("Preliminary Report")
            break;
        case ('/tools/missing_person'):
            if (activeTool !== "Missing Person")
                setActiveTool("Missing Person")
            break;
        case ('/tools/field_contact'):
            if (activeTool !== "Field Contact")
                setActiveTool("Field Contact")
            break;
        case ('/tools/supplemental_report'):
            if (activeTool !== "Supplemental")
                setActiveTool("Supplemental")
            break;
        case ('/tools/traffic_crash'):
            if (activeTool !== "Traffic Crash")
                setActiveTool("Traffic Crash")
            break;
        default:
            if (activeTool !== "")
                setActiveTool("")
            break;
    }

    React.useEffect(() => {
        setActiveTool("")
    }, [isActive])

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="mr-5 align-middle">
                    TOOLS <span style={{ color: 'gold' }}> {activeTool}</span>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="bg-blue-900 absolute w-56 mt-2  rounded-md " style={{ "zIndex": 100 }}>
                    <div className="px-1 py-1 ">
                        {(userTools) ? userTools.map((tool, idx) => (
                            <Menu.Item key={'NAVTLSDROPKEY' + idx}>
                                {({ active }) => (
                                    <>
                                        {tool.type && tool.type === 'A' ?
                                            <a href={tool.href} target="_blank" className={`${active ? 'hover:text-blue-200' : 'text-white'} group flex rounded-md items-center w-full text-sm`}><span onMouseEnter={() => { handleMouseEnter(tool.name) }} onMouseLeave={handleMouseLeave} style={isHover && tool.name === hoverTool ? { color: '#1ba5f5' } : {}}>{tool.name}</span></a>
                                            :
                                            <Link to={tool.href} onClick={() => { ActiveTool(isActive + 1) }} className={`group flex rounded-md items-center w-full text-sm`} >
                                                {tool.name === activeTool ? <span style={{ color: 'gold' }}>{tool.name}</span> : <span onMouseEnter={() => { handleMouseEnter(tool.name) }} onMouseLeave={handleMouseLeave} style={isHover && tool.name === hoverTool ? { color: '#1ba5f5' } : {}}>{tool.name}</span>}
                                            </Link>
                                        }
                                    </>
                                )}
                            </Menu.Item>
                        )) : <></>}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

interface ProfileProps {
    isActive: number,
    ActiveProfile: any
}

const Profile: React.FC<ProfileProps> = ({ isActive, ActiveProfile }) => {
    let user: Account = getUser();
    const history = useHistory()
    const [activeProfile, setActiveProfile] = useState<string>("");
    let [userProfiles, setUserProfiles] = useState<UserProfile[]>(getUserProfile())
    const [isHover, setIsHover] = useState(false);
    const [hoverProfile, setHoverProfile] = useState("")

    const handleMouseEnter = (tool: string) => {
        setIsHover(true);
        setHoverProfile(tool)
    };
    const handleMouseLeave = () => {
        setIsHover(false);
        setHoverProfile("")
    };

    switch (history.location.pathname) {
        case ('/users'):
            if (activeProfile !== "" + user.Firstname)
                setActiveProfile("" + user.Firstname)
            break;
        case ('/departments'):
            if (activeProfile !== "Departments")
                setActiveProfile("Departments")
            break;
        case ('/deptUnit'):
            if (activeProfile !== "Dept Unit Level")
                setActiveProfile("Dept Unit Level")
            break;
        case ('/SearchHistory'):
            if (activeProfile !== "Search History")
                setActiveProfile("Search History")
            break;
        case ('/change_password'):
            if (activeProfile !== "Change Password")
                setActiveProfile("Change Password")
            break;
        default:
            if (activeProfile !== "" + user.Firstname)
                setActiveProfile("" + user.Firstname)
            break;
    }

    React.useEffect(() => {
        setActiveProfile("")
    }, [isActive])

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="mr-5 align-middle">
                    PROFILE <span style={{ color: 'gold' }}> {activeProfile}</span>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="bg-blue-900 absolute w-56 mt-2  rounded-md " style={{ "zIndex": 100 }}>
                    <div className="px-1 py-1 ">
                        {(userProfiles) ? userProfiles.map((profile, idx) => (
                            <Menu.Item key={'NAVPFLEDROPKEY' + idx}>
                                {({ active }) => (
                                    <>
                                        {profile.type && profile.type === 'A' ?
                                            <a href={profile.href} target="_blank" className={`${active ? 'hover:text-blue-200' : 'text-white'} group flex rounded-md items-center w-full text-sm`}><span onMouseEnter={() => { handleMouseEnter(profile.name) }} onMouseLeave={handleMouseLeave} style={isHover && profile.name === hoverProfile ? { color: '#1ba5f5' } : {}}>{profile.name}</span></a>
                                            :
                                            <Link to={profile.href} onClick={() => { setActiveProfile(profile.name); ActiveProfile(isActive + 1) }} className={`${active ? 'hover:text-blue-200' : 'text-white'} group flex rounded-md items-center w-full text-sm`}>
                                                {profile.name === activeProfile || (history.location.pathname == '/users' && profile.name === 'User Profile') ? <span style={{ color: 'gold' }}>{profile.name}</span> : <span onMouseEnter={() => { handleMouseEnter(profile.name) }} onMouseLeave={handleMouseLeave} style={isHover && profile.name === hoverProfile ? { color: '#1ba5f5' } : {}}>{profile.name}</span>}
                                            </Link>
                                        }
                                    </>
                                )}
                            </Menu.Item>
                        )) : <></>}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

interface DashboardProps {
    isActive: number,
    ActiveProfile: any
}
const Dashboard: React.FC<DashboardProps> = ({ isActive, ActiveProfile }) => {
    const history = useHistory()
    const [activeProfile, setActiveProfile] = useState<string>("");

    let [dashboards, setDashboards] = useState<DashboardList[]>(getDashboards())

    const [isHover, setIsHover] = useState(false);
    const [hoverDashboard, setHoverDashboard] = useState("")

    const handleMouseEnter = (tool: string) => {
        setIsHover(true);
        setHoverDashboard(tool)
    };
    const handleMouseLeave = () => {
        setIsHover(false);
        setHoverDashboard("")
    };

    React.useEffect(() => {
        setActiveProfile("")
    }, [isActive])

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="mr-5 align-middle">
                    DASHBOARDS
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="bg-blue-900 absolute w-56 mt-2  rounded-md " style={{ "zIndex": 100 }}>
                    <div className="px-1 py-1 ">
                        {(dashboards) ? dashboards.map((dashboard, idx) => (
                            <Menu.Item key={'NAVDBDROPKEY' + idx} >
                                {({ active }) => (
                                    <>
                                        {dashboard.type && dashboard.type === 'A' ?
                                            <a href={dashboard.href} target="_blank" className={`${active ? 'hover:text-blue-200' : 'text-white'} group flex rounded-md items-center w-full text-sm`}><span onMouseEnter={() => { handleMouseEnter(dashboard.name) }} onMouseLeave={handleMouseLeave} style={isHover && dashboard.name === hoverDashboard ? { color: '#1ba5f5' } : {}}>{dashboard.name}</span></a>
                                            :
                                            <Link to={dashboard.href} onClick={() => { setActiveProfile(dashboard.name); ActiveProfile(isActive + 1) }} className={`${active ? 'hover:text-blue-200' : 'text-white'} group flex rounded-md items-center w-full text-sm`}>
                                                {dashboard.name === activeProfile ? <span style={{ color: 'gold' }}>{dashboard.name}</span> : <span onMouseEnter={() => { handleMouseEnter(dashboard.name) }} onMouseLeave={handleMouseLeave} style={isHover && dashboard.name === hoverDashboard ? { color: '#1ba5f5' } : {}}>{dashboard.name}</span>}
                                            </Link>
                                        }
                                    </>
                                )}
                            </Menu.Item>
                        )) : <></>}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

const Port: React.FC<DashboardProps> = ({ isActive, ActiveProfile }) => {
    const history = useHistory()
    const [activeProfile, setActiveProfile] = useState<string>("");
    let user: Account = getUser();

    const [njport, setNJPort] = useState<DashboardList[]>(getNJPort())
    const [isHover, setIsHover] = useState(false);
    const [hoverDashboard, setHoverDashboard] = useState("")

    const handleMouseEnter = (tool: string) => {
        setIsHover(true);
        setHoverDashboard(tool)
    };
    const handleMouseLeave = () => {
        setIsHover(false);
        setHoverDashboard("")
    };

    React.useEffect(() => {
        setActiveProfile("")
    }, [isActive])

    return (
        <Menu as="div" className="relative inline-block text-left">
            
                <div>
                    <Menu.Button className="mr-5 align-middle">
                        PORT
                    </Menu.Button>
                </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="bg-blue-900 absolute w-56 mt-2  rounded-md " style={{ "zIndex": 100 }}>
                    <div className="px-1 py-1 ">
                        {(njport) ? njport.map((njport, idx) => (
                            <Menu.Item key={'NAVDBDROPKEY' + idx} >
                                {({ active }) => (
                                    <>
                                        {njport.type && njport.type === 'A' ?
                                            <a href={njport.href}  className={`${active ? 'hover:text-blue-200' : 'text-white'} group flex rounded-md items-center w-full text-sm`}><span onMouseEnter={() => { handleMouseEnter(njport.name) }} onMouseLeave={handleMouseLeave} style={isHover && njport.name === hoverDashboard ? { color: '#1ba5f5' } : {}}>{njport.name}</span></a>
                                            :
                                            <Link to={njport.href} onClick={() => { setActiveProfile(njport.name); ActiveProfile(isActive + 1) }} className={`${active ? 'hover:text-blue-200' : 'text-white'} group flex rounded-md items-center w-full text-sm`}>
                                                {njport.name === activeProfile ? <span style={{ color: 'gold' }}>{njport.name}</span> : <span onMouseEnter={() => { handleMouseEnter(njport.name) }} onMouseLeave={handleMouseLeave} style={isHover && njport.name === hoverDashboard ? { color: '#1ba5f5' } : {}}>{njport.name}</span>}
                                            </Link>
                                        }
                                    </>
                                )}
                            </Menu.Item>
                        )) : <></>}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

interface SupportProps {
    isActive: number,
    ActiveProfile: any
}
const Support: React.FC<SupportProps> = ({ isActive, ActiveProfile }) => {
    const history = useHistory()
    const [activeProfile, setActiveProfile] = useState<string>("");

    let [supportTabs, setSupportTabs] = useState<SupportList[]>(getSupportTabs())
    const [isHover, setIsHover] = useState(false);
    const [hoverSupport, setHoverSupport] = useState("")

    const handleMouseEnter = (tool: string) => {
        setIsHover(true);
        setHoverSupport(tool)
    };
    const handleMouseLeave = () => {
        setIsHover(false);
        setHoverSupport("")
    };

    React.useEffect(() => {
        setActiveProfile("")
    }, [isActive])

    switch (history.location.pathname) {
        case ('/support/FAQ'):
            if (activeProfile !== "FAQs")
                setActiveProfile("FAQs")
            break;
        case ('/support/Training'):
            if (activeProfile !== "Training Videos")
                setActiveProfile("Training Videos")
            break;
        case ('/support/UserManual'):
            if (activeProfile !== "User Manual")
                setActiveProfile("User Manual")
            break;
        case ('/support/Contact'):
            if (activeProfile !== "Contact GTBM")
                setActiveProfile("Contact GTBM")
            break;
        default:
            if (activeProfile !== "")
                setActiveProfile("")
            break;
    }

    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="mr-5 align-middle">
                    SUPPORT <span style={{ color: 'gold' }}> {activeProfile}</span>
                </Menu.Button>
            </div>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items className="bg-blue-900 absolute w-56 mt-2  rounded-md " style={{ "zIndex": 100 }}>
                    <div className="px-1 py-1 ">
                        {(supportTabs) ? supportTabs.map((support, idx) => (
                            <Menu.Item key={'NAVDBDROPKEY' + idx} >
                                {({ active }) => (
                                    <>
                                        {support.type && support.type === 'A' ?
                                            <a href={support.href} target="_blank" className={`${active ? 'hover:text-blue-200' : 'text-white'} group flex rounded-md items-center w-full text-sm`}><span onMouseEnter={() => { handleMouseEnter(support.name) }} onMouseLeave={handleMouseLeave} style={isHover && support.name === hoverSupport ? { color: '#1ba5f5' } : {}}>{support.name}</span></a>
                                            :
                                            <Link to={support.href} onClick={() => { setActiveProfile(support.name); ActiveProfile(isActive + 1) }} className={`${active ? 'hover:text-blue-200' : 'text-white'} group flex rounded-md items-center w-full text-sm`}>
                                                {support.name === activeProfile ? <span style={{ color: 'gold' }}>{support.name}</span> : <span onMouseEnter={() => { handleMouseEnter(support.name) }} onMouseLeave={handleMouseLeave} style={isHover && support.name === hoverSupport ? { color: '#1ba5f5' } : {}}>{support.name}</span>}
                                            </Link>
                                        }
                                    </>
                                )}
                            </Menu.Item>
                        )) : <></>}
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    )
}

interface NavBarProps {
    count: number
}

const NavBar: React.FC<NavBarProps> = ({ count }) => {
    const history = useHistory()
    let [user, setUser] = useState<Account>(getUser());
    let [home, setHome] = useState<number>(0);
    let [showPOIExpire, setShowPOIExpire] = useState<boolean>(false);
    let [initialRun, setInitialRun] = useState<boolean>(false);
    let [userProfile, setUserProfile] = useState<number>(0);
    let [sessionExp, setSessionExp] = useState<any>(new Date(parseInt("" + getUser().exp) * 1000))
    let [expiry, setExpiry] = useState<string | null>(getExpiry());
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<AlertColor>("success")

    const [showElement, setShowElement] = React.useState(true);
    function getTimeLeft() {
        let releaseDate = Date.parse(BTVERSION_RELEASE_DT.toString());
        return Math.ceil(Date.now() - releaseDate) / 1000;    //Time difference in seconds.
    }

    React.useEffect(() => {
        updateSessionTimeout();
        var timeToShow = getTimeLeft(); //Up to 5 Days from release date of new version should show in navbar (1 Day = 86,400 seconds => 5 Days is 432000 seconds)
        //console.log("timeToShow: " + timeToShow);
        if (timeToShow < 0 || timeToShow > 432000) {
            setShowElement(false);
        }
        //setTimeout(function () {setShowElement(false);}, 30000);  //To Test
    }, [count])
    
    React.useEffect(() => {
        if(!initialRun){
            setInitialRun(true)
            checkPOIExpiry();
        }
    }, [])


    const updateSessionTimeout = async () => {
        if (!expiry || expiry.length === 0) {
            setExpiry(getExpiry())
        }
        setSessionExp(new Date(parseInt("" + getUser().exp) * 1000))
    }
    
    const checkPOIExpiry = async () => {
        if(isUserPermission('POI')){
            let today = new Date();
            let fromday = new Date();
            fromday.setMonth(fromday.getMonth() - 12);
            fromday.setDate(fromday.getDate() + 1);

            let search_query: any = {
                "DateRange": true,
                "DateTypeValue":  0,
                "FromDate": fromday?.toLocaleString(),
                "ToDate": today?.toLocaleString(),
                "SystemCall": true,
            }
            let qRes: any = await RunPOIList(search_query)
            if(qRes && qRes?.AnyData && qRes?.AnyData?.length > 0){
                let count = 0;
                let warning = new Date();
                warning.setDate(warning.getDate() + 10);
                let POIs: any = qRes?.AnyData;
                for(let p of POIs){
                    if(p && p.ExpiryDate && p.ExpiryDate?.length > 0 && p.IgnoreExpiryWarning !== true ){
                        let expiryDate = new Date(p.ExpiryDate);
                        if (expiryDate < warning && expiryDate > new Date()) {
                            count +=1;
                            break; // We found one so it is not necessary to continue the loop.
                        }
                    }
                }
                if(count && count > 0) {
                    setShowPOIExpire(true);
                    setAlertType("warning")
                    setAlertText("Review your expiring POIs!")
                    setAlertOpen(true)
                }
            }   
        }
    }

    return (
        <nav className="bg-white mb-1">
            <Grid container spacing={0}>
                <Grid item xs={4}>
                    <div className="flex justify-start items-center h-full ml-5">
                        {(SiteName === SITE.NJPROD || SiteName === SITE.NJDEMO || SiteName === SITE.NJUAT ) && <img src={njspLogo} style={{ 'height': '80px', 'width': '80px' }} alt={'BackTrace'} />}
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="flex justify-center items-center h-full">
                        {(SiteName === SITE.NJPROD || SiteName === SITE.NJDEMO || SiteName === SITE.NJUAT ) && <img src={cap5Logo} style={{ 'width': '100px', 'height': '100px' }} alt={'NJCAP5'} />}
                        {( SiteName === SITE.LOCAL ) && <img src={btLogo} style={{ 'width': '200px', 'height': '100px' }} alt={'BackTrace'} />}
                        {(SiteName === SITE.DCDEMO || SiteName === SITE.DCPROD || SiteName === SITE.DCLOCAL) && <img src={dcLogo} style={{ 'width': '125px', 'height': '125px' }} alt={'MPD'} />}
                        {(SiteName === SITE.NYPROD || SiteName === SITE.NYDEMO || SiteName === SITE.NYLOCAL) && <img src={nysLogo} style={{ 'height': '100px' }} alt={'NYDCS'} />}
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="flex justify-end items-center h-full mr-5">
                        <span>
                            {showElement ? (
                                <strong>
                                    <a className="w-75 link text-blue-700 underline" style={{ fontSize: 24 }} href="#" onClick={() => { OpenReleaseNotesPDF(user.EmpID, user.Dept) }}>
                                        {/*<img src={newVersion} style={{ 'height': '36px', 'width': '36px' }} alt={'New'} />*/}
                                        <FiberNewIcon className="text-red-700" />
                                        Version&nbsp;{BTVERSION}</a>
                                </strong>
                            ) : (<></>)}
                            {/*<Typography sx={{ fontSize: 24 }} variant="h3" component="div" color="red">*/}
                            {/*    <b>Scheduled Maintenance: Backtrace website will not be available on Dec 20 2023 between 19:00 to 20:00 EST</b>*/}
                            {/*</Typography>*/}
                            {expiry && expiry?.length > 0 ?
                                <div className="w-75 ml-auto text-left mr-16">
                                    {/*<Marquee speed={60} pauseOnHover={true} gradientWidth={0}>*/}
                                    <Typography sx={{ fontSize: 24 }} variant="h3" component="div" color="red">
                                        <b>{expiry}</b>
                                    </Typography>
                                    {/*</Marquee>*/}
                                </div>
                                : <></>}
                        </span>
                        {(SiteName === SITE.NJPROD || SiteName === SITE.NJDEMO || SiteName === SITE.NJUAT ) && (history.location.pathname === '/tools/alpr_vin_search' || history.location.pathname === '/tools/alpr_multiple_occurrence' || history.location.pathname === '/detail/ALPR') ?
                            <img src={NJSnapLogo} style={{ 'height': '70px', 'width': '130px' }} alt={'BackTrace'} />
                            :
                            <></>}
                        {(SiteName === SITE.NJPROD || SiteName === SITE.NJDEMO || SiteName === SITE.NJUAT ) && <img src={uasiLogo} style={{ 'height': '85px', 'width': '85px' }} alt={'BackTrace'} /> }
                    </div>
                </Grid>
            </Grid>

            <div className="w-screen ">
                <Snackbar open={alertOpen} autoHideDuration={7000} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} onClose={() => {
                    setAlertOpen(false)
                }}>
                    <Alert onClose={() => {
                        setAlertOpen(false)
                    }} severity={alertType} sx={{width: '100%'}}>
                        {alertText}
                    </Alert>
                </Snackbar>
                <div className="bg-blue-900 rounded-5 w-auto text-white p-1 flow-root">
                    <Grid container spacing={0}>
                        <Grid item xs={4}>
                            <div className={"flex justify-start items-center h-full"}>
                                <Link to="/" className="ml-5 mr-5 align-middle" onClick={() => { setHome(home + 1); setUserProfile(userProfile + 1) }}><span style={history.location.pathname === '/' ? { color: 'gold' } : {}}>HOME</span></Link>
                                {isUserPermission('Tools') ? <Tools isActive={home} ActiveTool={setUserProfile} /> : <></>}
                                {isUserPermission('NJPort') && <Port isActive={home} ActiveProfile={getNJPort} />}
                                {isUserPermission('Arrest') ? <Dashboard isActive={userProfile} ActiveProfile={setHome} /> : <></>}
                                {isUserPermission('POI') ?
                                    <span>
                                        <span>
                                             <Link to="/poi" className="mr-5 align-middle" onClick={() => { setHome(home + 1); setUserProfile(userProfile + 1) }}>
                                                <span style={history.location.pathname === '/poi' ? { color: 'gold' } : {}}>POI</span>
                                             </Link>
                                        </span>
                                        {showPOIExpire ?
                                            <span style={{color:'orange'}}>
                                                <Tooltip title="Review your expiring POIs. Click here to take action!">
                                                     <TaxiAlertIcon style={{fontSize:'20px'}} onClick={() => { setHome(home + 1); setUserProfile(userProfile + 1); window.open('/poi', '_blank') }}/>
                                                </Tooltip>
                                            </span>
                                        : <> </>
                                        }
                                    </span>
                                    : <></>
                                }
                                { isUserPermission('NJTraceAdd') && (!(SiteName == SITE.DCDEMO || SiteName == SITE.DCPROD || SiteName == SITE.DCLOCAL)) ? 
                                    <span>
                                        <Tooltip title ="Enter Recovery Gun" placement="top">
                                            <span>
                                                <Link to="/AddNJTrace" target="_blank" className={showPOIExpire ? "ml-5 mr-5 align-middle" : "align-middle"}>
                                                    <span>GUN TRACE</span>
                                                </Link>
                                            </span>
                                        </Tooltip>                                                                             
                                    </span>
                                    :<></>
                                } 
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="text-xs text-yellow-300 flex justify-center items-center h-full" style={{ textAlign: 'center' }}>
                                Session Expires: {sessionExp.toLocaleString()}
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className={"flex justify-end items-center h-full"}>
                                {(SiteName == SITE.NJPROD || SiteName == SITE.NJUAT || SiteName == SITE.NJDEMO || SiteName == SITE.LOCAL) && (history.location.pathname === '/tools/alpr_vin_search' || history.location.pathname === '/tools/alpr_multiple_occurrence' || history.location.pathname === '/detail/ALPR' || history.location.pathname === '/poi') ?
                                    <a href="mailto:ALPR@njsp.gov?subject=NJSNAP SUCCESS STORY" className="mr-5 align-middle">
                                        SUBMIT SUCCESS STORY
                                    </a>
                                    :
                                    <></>}
                                <Support isActive={userProfile} ActiveProfile={setHome} />
                                <Profile isActive={userProfile} ActiveProfile={setHome} />
                                <Link to="/logoff" className="mr-5 align-middle">LOGOFF</Link>

                            </div>
                        </Grid>
                    </Grid>
                </div>

            </div>

        </nav>

    );
}

export default NavBar;