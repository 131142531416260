import React from "react";
import AlprMultipleOccurrenceSearch
    from "../../components/tools/alpr_multiple_occurrence/_alprMultipleOccurrenceSearch";
import {
    ALPRMultipleOccurenceQuery,
    ALPRMultipleOccurenceResult,
    DeviceMultipleOccurrenceResult
} from "../../interfaces/getDarInfo_interface";
import {RunDeviceMultipleOccurrence, RunMultipleOccurrence} from "../../services/getDar.service";
import ALPRMultipleOccurrenceResults
    from "../../components/tools/alpr_multiple_occurrence/_alprMultipleOccurrenceResults";
import {useLocation} from "react-router-dom";
import {getDeviceUser, isUserPermission} from "../../services/auth.service";
import {GetDeviceToken} from "../../services/detail.service";
import {Account} from "../../interfaces/auth_interface";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function ALPRMultipleOccurrence() {
    const [ALPRMultiData, setALPRMultiData] = React.useState<ALPRMultipleOccurenceResult>({})
    const [deviceMultiData, setDeviceMultiData] = React.useState<DeviceMultipleOccurrenceResult>({})
    // let [noData, setNoData] = React.useState<boolean>(false)
    const [searchType, setSearchType] = React.useState<string>("Vehicle")
    const [incidents, setIncidents] = React.useState<any>([])

    const SubmitSearch = async (SearchQuery:any, SearchType:string) => {
        setSearchType(SearchType)
        setALPRMultiData({})
        setDeviceMultiData({})
        
        setIncidents([])
        setIncidents(SearchQuery?.incidents)
        
        if(SearchType != 'Device') {
            let res = await RunMultipleOccurrence(SearchQuery)
            setALPRMultiData(JSON.parse(res?.JsonObject))
        }
        
        if (SearchType != 'Vehicle') {
            if (!localStorage.getItem('DLUser')) { //dont have a token? go get one.
                let res: any = await GetDeviceToken()
                if (res) {
                    localStorage.setItem('DLUser', res)
                }
            }
    
            let DLUser: Account = getDeviceUser();
            let today = new Date();
    
            if (DLUser.exp && DLUser.exp < (today.getTime() / 1000)) {
                let res: any = await GetDeviceToken()
                if (res) {
                    localStorage.setItem('DLUser', res)
                }
            }
    
            let DLT = localStorage.getItem("DLUser")
    
            if (DLT && DLT.length > 0) {
                let resD = await RunDeviceMultipleOccurrence(SearchQuery)
                setDeviceMultiData({})
                setDeviceMultiData(resD)
            }
        }
        
        /*let obj = JSON.parse(res?.JsonObject)
        if (obj?.MultipleOccurrenceList?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }*/
    }
    let query = useQuery();
    
    
    return (
        <div>
            {isUserPermission('CODIS')?
                <>
                    {query.get("ALPRList") ?
                        <AlprMultipleOccurrenceSearch SubmitSearch={SubmitSearch} data={query.get("ALPRList")}/>
                        :
                        <AlprMultipleOccurrenceSearch SubmitSearch={SubmitSearch} />
                    }
                    {/*!noData ? <ALPRMultipleOccurrenceResults ALPRData={ALPRMultiData} DeviceData={deviceMultiData} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>*/}
                    <ALPRMultipleOccurrenceResults ALPRData={ALPRMultiData} DeviceData={deviceMultiData} SearchType={searchType} IncidentList={incidents}/>
                </>
            :<></>}
        </div>
        
    );
}

export default ALPRMultipleOccurrence;