import React, {useState} from 'react'
import {Button, ButtonGroup, Tooltip} from "@mui/material";
import VehicleIcon from "../../../assets/Images/Vehicle.png";
import PersonIcon from "../../../assets/Images/Person.png";
import BTPersonReport from "../../shared/_BTPersonReport";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search,
    SortSettingsModel
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import GeoCode from "../../shared/GeoCodes/_GeoCode_Display";
import Checkbox from "@mui/material/Checkbox";
import {
    ALPRMultipleOccurenceQuery,
    GetDarInfo_Query,
    getDarInfoResponse
} from "../../../interfaces/getDarInfo_interface";
import {Account} from "../../../interfaces/auth_interface";
import {getDeviceUser, getUser, isUserPermission} from "../../../services/auth.service";
import {RunDarQuery, RunMultipleOccurrence} from "../../../services/getDar.service";
import MapIcon from "@mui/icons-material/Map";
import {JAOS} from "../../../services/JAOS.service";
import {base64_to_blob, GetDeviceToken, RunShotSpotterQuery} from "../../../services/detail.service";
import DeviceIcon from "../../../assets/Images/DeviceSmall.png";
import AuthenticatedAPIReqSplashDL from "../../../services/AuthenticatedAPIReqSplashDeviceLocation.service";
import {formatDT} from "../../../services/formatDate.service";
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";
import {SiteName} from "../../../services/config.service";


interface SearchResultsProps {
    ShootingInfo: any[],
    AudioFiles: any[]
}

const SearchResults: React.FC<SearchResultsProps> = ({ShootingInfo, AudioFiles})=> {
    const [typedShootings, setTypedShootings] = React.useState<any[]>([])
    const [showGeoCode, setShowGeoCode] = React.useState(false)
    const [allPins, setAllPins] = React.useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    const [alprList, setALPRList] = useState<any[]>([])
    const [mapVersion, setMapVersion] = useState<string>("2")
    const [exportData, setExportData] = useState<any>({})
    
    const handleDataTyping = () =>
    {
        if(ShootingInfo) {
            let tempP: any = [...ShootingInfo]

            for (let p of tempP) {
                if (p.DateTimeIN) {
                    p.DateTimeIN = new Date(p.DateTimeIN)
                }
            }
            setTypedShootings(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping();
        setALPRList([]);
    },[ShootingInfo])

    let grid: Grid | null;
    const selectTemplate = (person: any): any => {
        return (
        <>
            <Checkbox onClick={ () => {buildALPRList(person)} }/>
        </>);
    }
    const NearByTemplate = (person: any): any => {
        return (
            <span>
                <Tooltip title={'Person: 0.25 Mile Range and Last 1 Year'} arrow><Button onClick ={() => {handlePersonGeo(person)}}><img src={PersonIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /></Button></Tooltip>
                <Tooltip title={'Vehicle: 16 minutes & 1.5 Mile Range'} arrow><Button onClick ={() => {handleCarGeo(person)}}><img src={VehicleIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /></Button></Tooltip>
                {isUserPermission('DeviceID') && checkDeviceDate(person) && (SiteName == SITE.NJPROD || SiteName == SITE.NJUAT || SiteName == SITE.NJDEMO || SiteName == SITE.LOCAL) ? <Tooltip title={'Device: 16 minutes & 3000 Feet Range'} arrow><Button onClick ={() => {handleDeviceGeo(person)}}><img src={DeviceIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /></Button></Tooltip> :<></>}
            </span>);
    }

    const checkDeviceDate = (person:any) => {
        let today:Date = new Date();
        let deviceDate = new Date(today.setDate(today.getDate() - 3));
        deviceDate.setHours(0,0,0)
        return Date.parse(deviceDate.toString()) > Date.parse(person.DateTimeIN);

    }
    
    const handleDeviceGeo = async (person:any) => {
        setExportData({})

        if (!localStorage.getItem('DLUser')) { //dont have a token? go get one.
            let res: any = await GetDeviceToken()
            if (res) {
                localStorage.setItem('DLUser', res)
            }
        }

        let DLUser: Account = getDeviceUser();
        let today = new Date();

        if (DLUser.exp && DLUser.exp < (today.getTime() / 1000)) {
            let res: any = await GetDeviceToken()
            if (res) {
                localStorage.setItem('DLUser', res)
            }
        }

        let date = person.DateTimeIN
        let milliseconds = Date.parse(date.toString())
        let start = new Date(milliseconds - (8 * 60 * 1000))
        let end = new Date(milliseconds + (8 * 60 * 1000))

        let search_query: any = {
            "DeviceId": [],
            "DeviceType": [],
            "Inc_DateTime": [formatDT(start)],  //User local timestamp
            "Inc_DateTimeEnd": [formatDT(end)],
            "Inc_Latitude": [person.Incident_Lat],
            "Inc_Longitude": [person.Incident_Lon],
            "Radius": [3000],
            "ExactLocation": ['Y'],
        }

        let DLT = localStorage.getItem("DLUser")

        if (DLT && DLT.length > 0) {
            let r: any = await AuthenticatedAPIReqSplashDL.post('/DeviceLocation/SearchNotification', search_query)
            if(r && r.data && r.data.length > 0)
            {
                let obj = {
                    shooting: [person],
                    devices: r.data
                }

                setExportData(r.data)
                deviceGeoCode(obj)
            }
        }

    }
    
    const handleCarGeo = async (person:any) => {
        setExportData({})
        let search_query:ALPRMultipleOccurenceQuery = { 
            "incidents": [
            {
                date: new Date(person.DateTimeIN).toLocaleString(),
                threshold: 8,
                radius: 1.5, //this needs to move to 1 on prod ***
                location: {
                    address: person.Address,
                    city: person.City,
                    state: person.State,
                    zip: person.Zip,
                    latitude: person.Incident_Lat,
                    longitude: person.Incident_Lon,
                    addressType: ""
                },
                showEdit: true,
                alprServerDB: "",
                alprServerName: ""
            }
        ],
            minOccurrence: 0,
            lookFor: "Car",
            plate: {
            plateNumber: "",
                plateSt: ""
        },
            queryType: "M",
            alprReason: "Suspicious Incident",
            alprCaseNumber: "Automated",
            UTCDate:false,
        }
        let res:any = await RunMultipleOccurrence(search_query)
        let parsed = JSON.parse(res?.JsonObject)
        if(parsed.MultipleOccurrenceList.length > 0) {
            let obj = {
                shooting: [person],
                cars: parsed.MultipleOccurrenceList
            }
            setExportData(parsed.MultipleOccurrenceList)
            carGeoCode(obj)
        }
    }
    
    const handlePersonGeo = async (person:any) => {
        setExportData({})
        let fromDate = new Date(person.DateTimeIN)
        fromDate.setFullYear(fromDate.getFullYear() - 1)

        let search_query: GetDarInfo_Query = {
            "QueryType": "2",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": 1,
            "FromDate": fromDate.toISOString().substring(0,10),
            "ToDate": new Date(person.DateTimeIN).toISOString().substring(0,10),
            "SearchType": "AND",
            "IndicatorOn": true, //why?
            "TopArrestNumber": 0,
            "FNames": [],
            "MNames":  [],
            "LNames":  [],
            "DOBs": [],
            "SEX": [],
            "Address": "",
            "Cities": [],
            "States": [],
            "Zip": "",
            "Statutes": [],
            "Gang": [],
            "FBIs":  [],
            "SBIs": [],
            "DLNos": [],
            "PlateNos":  [],
            "VINs": [],
            "AgencyArrested": [],
            "InfocopDept":  [],
            "Indicators": [],
            "IndicatorOccurrence": null,
            "Latitude": person.Incident_Lat,
            "Longitude": person.Incident_Lon,
            "Radius": 0.25,
            "ResidentCounty": [],
            "ArrestedCounty": [],
            "Arrest_Type":  [],
            "CaseNumbers": [],
            "FTSIDs": [],
        }
        let qRes: getDarInfoResponse = await RunDarQuery(search_query)
        if(qRes.PersonList?.length > 0) {
            let obj = {
                shooting: [person],
                people: qRes.PersonList
            }
            setExportData(qRes.PersonList)
            personGeoCode(obj)
        }
    }

    const deviceGeoCode = (deviceGeo:any) => {
        setMapVersion("device")
        let pins:any[] = []
        for (let p of deviceGeo.shooting)
        {
            if(p.Incident_Lat && p.Incident_Lon)
            {
                pins.push({lat:p.Incident_Lat, lng:p.Incident_Lon, date:p.DateTimeIN, dept:p.Dept, victims:p.NoOfVictims, suspect:p.SuspectDesc, inc_type:p.Inc_Type, tool:"shooting"})
            }
        }
        for (let p of deviceGeo.devices)
        {
            if(p.Latitude && p.Longitude)
            {
                pins.push({lat:p.Latitude, lng:p.Longitude, device:p.DeviceID, date:p.EventDateTimeUTC, distance:p.Distance, accuracy:p.HorizontalAccuracy ,tool:"device"})
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
    }
    
    const buildALPRList = (person:any) => {
        let ALPRListTemp = alprList
        var result = ALPRListTemp.findIndex(x => x.MainID === person.MainID)
        
        if(result === -1) {
            ALPRListTemp.push(person)
           
        }
        else{
            ALPRListTemp.splice(result,1) 
        }
        
        setALPRList(ALPRListTemp)
    } 
    
    const handleALPRListSend = () => {
        if(alprList.length < 1)
        {
            window.alert("Please Select At Least One Incident To Compare (Check Lat & Long)")
        }
        else
        {
            let l = new JAOS()
            let hashID = l.objToStack(alprList)
            window.open('/tools/alpr_multiple_occurrence?ALPRList='+hashID,'_blank')
            
        }
    }

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            grid.excelExport();
        }else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const personGeoCode = (peopleGeo:any) => {
        setMapVersion("person")
        let pins:any[] = []
        for (let p of peopleGeo.shooting)
        {
            if(p.Incident_Lat && p.Incident_Lon)
            {
                pins.push({lat:p.Incident_Lat, lng:p.Incident_Lon, date:p.DateTimeIN, dept:p.Dept, victims:p.NoOfVictims, suspect:p.SuspectDesc, inc_type:p.Inc_Type, tool:"shooting"})
            }
        }
        for (let p of peopleGeo.people)
        {
            if (p.Incident_Latitude && p.Incident_Longitude)
            {
                pins.push({ lat: p.Incident_Latitude, lng: p.Incident_Longitude, name:p.FName + ' ' + p.MName + ' ' + p.LName, date:p.DTOFARREST, statute:p.STATUTE ,tool:"person"})
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
    }

    const carGeoCode = (carGeo:any) => {
        setMapVersion("car")
        let pins:any[] = []
        for (let p of carGeo.shooting)
        {
            if(p.Incident_Lat && p.Incident_Lon)
            {
                pins.push({lat:p.Incident_Lat, lng:p.Incident_Lon, date:p.DateTimeIN, dept:p.Dept, victims:p.NoOfVictims, suspect:p.SuspectDesc, inc_type:p.Inc_Type, tool:"shooting"})
            }
        }
        for (let p of carGeo.cars)
        {
            if(p.Latitude && p.Longitude)
            {
                if (p.POIReason?.toLowerCase() == 'stolen vehicle' || p.POIReason?.toLowerCase() == 'stolen plate' || p?.POIReason?.toLowerCase() == 'felony stolen vehicle')
                    pins.push({ lat: p.Latitude, lng: p.Longitude, plate: p.red_vrm, date: p.red_TimeStamp, alprSource: p.src_Name, tool: "stolenCar" })
                else
                    pins.push({lat:p.Latitude, lng:p.Longitude, plate:p.red_vrm, date:p.red_TimeStamp, alprSource:p.src_Name ,tool:"car"})
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
    }
    
    const openGeoCode = () => {
        setMapVersion("shotSpotter")
        setAllPins([])
        let pins:any[] = []
        for (let p of ShootingInfo)
        {
            if(p.Incident_Lat && p.Incident_Lon)
            {
                pins.push({lat:p.Incident_Lat, lng:p.Incident_Lon, date:p.DateTimeIN, dept:p.Dept, victims:p.NoOfVictims, suspect:p.SuspectDesc, inc_type:p.Inc_Type, tool:"shotSpotter"})
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
    }

    const HandleGeoCodeClose = () => {
        setShowGeoCode(false)
    }


    const handleDataTypingAudioFile = (shootings:any) =>
    {
        if(AudioFiles){
            let tempP:any = [...shootings]

            for(let p of tempP)
            {
                if(p.DOB)
                {
                    p.DOB = new Date(p.DOB)
                }
                if(p.DTOFARREST)
                {
                    p.DTOFARREST = new Date(p.DTOFARREST)
                }
            }
            return tempP
        }

    }

   // ----------------------------------------------------------------- Could be in other component------------------------------------------------------
    let sortSettingsAudio: SortSettingsModel = { columns: [
            {field: 'FileDistance', direction: 'Ascending' }
        ] };

    const playShotSound = async (FileID: any, ShotID: any, FileMeme: any )  => {
        let res:any = await RunShotSpotterQuery(FileID, ShotID)
        let snd = new Audio("data:" + FileMeme + ";base64," + res.AudioFile );
        snd.play();
    }

    const downloadShotFile = async (FileID: any, ShotID: any, FileMeme: any )  => {
        let res:any = await RunShotSpotterQuery(FileID, ShotID)
        if(res.AudioFile) {
            base64_to_blob(res.AudioFile, FileMeme).then(r => {
                var blobUrl = URL.createObjectURL(r);
                window.open(blobUrl, '_blank')
            })
        }
    }
    
    const AudioFileTemplate = (shot: any): any => {
        return (
            <span>
                <Tooltip title={'Play Audio File'} arrow placement={'left'}>
                    <Button onClick={ () => {playShotSound(shot.FileID, shot.ShotID, shot.FileMeme)}} >
                        Play
                    </Button>
                </Tooltip>
                <Tooltip title={'Download Audio File'} arrow placement={'right'}>
                    <Button onClick={ () => {downloadShotFile(shot.FileID, shot.ShotID, shot.FileMeme)}} >
                        Download
                    </Button>
                </Tooltip>
                
            </span>);
    }
    
    const shootingAudioFiles = (cadrms:any) => {

        let temp = handleDataTypingAudioFile(AudioFiles?.filter((x:any)=> x.MainID === cadrms.MainID))

        return (
            <div className={"pl-10 pr-10 mt-5 border-2 border-gray-100 pb-5 pt-5"}>
            <span style={{display:"inline-block", width:"full"}}>
                Shot Spotter Audio Files
                {(temp && temp.length > 0) ? <h4 className={"font-bold pl-5"}> {temp.length} Records Found</h4> : <></>}
            </span>
                <GridComponent
                    dataSource={temp}
                    allowPaging={true}
                    pageSettings={{pageSize: 50, pageSizes: [10,25,50,100]}}
                    allowSorting={true}
                    sortSettings={sortSettingsAudio}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{type: 'CheckBox'}}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"}}
                    allowSelection={false}
                    allowResizing={true}
                    rowHeight={24}
                    ref={g=> grid = g}
                >
                    <ColumnsDirective>
                        {/*<ColumnDirective field='FileSensor' width={140} minWidth={140} maxWidth={140} headerText='Sensor Name' customAttributes={{ class: ['e-attr']}}/>*/}
                        <ColumnDirective field='FileDistance' width={140} minWidth={140} maxWidth={140} headerText='Sensor Distance (Meters)' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='FileMeme' width={140} minWidth={140} maxWidth={140} headerText='Sensor Media Type' customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective template={AudioFileTemplate} headerText='View' width={100}/>
                    </ColumnsDirective>
                    <Inject services={[Sort, Page, Filter]}/>
                </GridComponent>
            </div>
        )
    }
    
    // ----------------------------------------------------------------- Could be in other component------------------------------------------------------
    
    const expandRow = () => {
        if(grid)
        {
            const selectedrowindex: number[] = grid.getSelectedRowIndexes()
            for (let idx of selectedrowindex) {

                grid.detailRowModule.expand(idx)
            }
        }
    }
    
    let sortSettings: SortSettingsModel = { columns: [
            {field: 'DateTimeIN', direction: 'Descending' }
        ] };
    
    
    return (
        <div className={"pl-10 pr-10 mt-5"}>
            {typedShootings && typedShootings.length>0? <>
                <span style ={{display:"inline-block"}} className='mb-5'>
                    <h4 className={"font-bold inline mr-5 text-lg"}> {typedShootings?.length} Records Found</h4>
                    <ButtonGroup size="medium" color="inherit" >
                         <Button variant="outlined" onClick={openGeoCode}>
                            <MapIcon color="success" className ="mr-2"/><b>Map</b>
                        </Button>
                        <Button variant="outlined" onClick={handleALPRListSend}>
                           <img src={VehicleIcon} className={"mr-2"} alt={""} />Incident Connection
                        </Button>
                    </ButtonGroup>
                    {(showGeoCode) ? <div><GeoCode HandleGeoCodeClose={HandleGeoCodeClose} show={showGeoCode} pins={allPins} mapVersion={mapVersion} exportData={exportData}/></div>:<></>}
                </span>
                <GridComponent
                    dataSource={typedShootings}
                    allowPaging={true}
                    pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{type: 'CheckBox'}}
                    detailTemplate={shootingAudioFiles}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport','Search']}
                    toolbarClick={GridToolsClick}
                    ref={g=> grid = g}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"
                    }}
                    rowSelected = {(event:any) => {expandRow()}}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                >
                    <ColumnsDirective>
                        <ColumnDirective template={selectTemplate} headerText='Select' width={120} minWidth={120} maxWidth={120} customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='DateTimeIN' format = {{type:'dateTime', format:'MM/dd/yyyy HH:mm'}} headerText='Shooting Date' width={160} minWidth={160} maxWidth={160} customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='STREETNAME' headerText='Address' width={200} minWidth={200} maxWidth={200} customAttributes={{ class: ['e-attr']}} />
                        <ColumnDirective field='Inc_Type' headerText='Incident Type' width={150} minWidth={150} maxWidth={150} customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='Rounds' headerText='Rounds' width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='Beat'  headerText={`${GetStringNameForSite(SiteName,'BEAT')}`} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='District'  headerText='District' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective field='DeptName'  headerText='Dept' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr']}}/>
                        <ColumnDirective template={NearByTemplate} headerText='Near By Vehicle/Person' maxWidth={220} customAttributes={{ class: ['e-attr']}}/>
                    </ColumnsDirective>
                    <Inject services={[Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search]}/>
                </GridComponent>

            </>:<></>}
        </div>
    );
};

export default SearchResults;

