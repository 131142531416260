import React from "react";
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Slide,
  Snackbar,
  Tab,
  Tooltip,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import {
  ColumnDirective,
  ColumnsDirective,
  ColumnMenu,
  GridComponent,
  Grid,
  Inject,
  Page,
  Sort,
  Filter,
  DetailRow,
  ExcelExport,
  PdfExport,
  Toolbar,
  Search,
  ExcelExportProperties,
  Resize,
  Reorder,
  SortSettingsModel,
  Edit,
  CommandColumn,
} from "@syncfusion/ej2-react-grids";
import { ClickEventArgs } from "@syncfusion/ej2-navigations";
import { PostAdd, List, Add } from "@mui/icons-material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { TransitionProps } from "@mui/material/transitions";
import {
  GetProfileReport,
  GetProfileReportPDF,
} from "../../../services/account.service";
import { useHistory } from "react-router-dom";
import { isUserPermission } from "../../../services/auth.service";
import BTPersonIndicatorGrid from "../../shared/_PersonIndicators";
import { profileObject } from "../../../interfaces/PersonReport.interface";
import VolunteerHireInputForm from "./volunteerInputForm";
import { InvestigationObject } from "../../../interfaces/NJPORT/InvestigationLog.interface";
import {
  DeleteVolunteerHire,
  submitVolunteerHire,
} from "../../../services/njport.service";
import { VolunteerHireObject } from "../../../interfaces/NJPORT/VolunteerHire.interface";
import VolunteerSearchSubResults from "./volunteerSearchSubResult";
import { format } from "date-fns";

interface SearchResultsProps {
  data?: VolunteerHireObject[];
  Persons?: any;
  handleSubmit: any;
  SubmitSearch: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SearchResults: React.FC<SearchResultsProps> = ({
  handleSubmit,
  data,
  
  Persons = null,
  SubmitSearch,
}) => {
  const [typedPersons, setTypedPersons] = React.useState<VolunteerHireObject[]>(
    [],
  );
  //const [params, setParams] = React.useState({})
  const [params, setParams] = React.useState({
    FirstName: "",
    MI: "",
    LastName: "",
    DOB: "",
    FBI: "",
    SBI: "",
    AgeRangeStart: "",
    AgeRangeEnd: "",
    toDate: "",
    fromDate: "",
    Gender: "",
    Status: "",
    ReportNum: "",
  });
  const [tabValue, setTabValue] = React.useState("1");
  const [editTabName, setEditTabName] = React.useState(
    "New VolunteerHire Report",
  );
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  // const [editDialogID, setEditDialogID] = React.useState<number>(0);
  const [editDialogID, setEditDialogID] = React.useState<any>();
  const [deleteDialogID, setDeleteDialogID] = React.useState<number>(0);
  const [duplicateReport, setDuplicateReport] = React.useState<boolean>(false);
  const [reportUrl, setReportUrl] = React.useState<string | undefined>(
    undefined,
  );
  const [editingRecord, setEditingRecord] =
    React.useState<VolunteerHireObject | null>(null);
  const [personDetails, setPersonDetails] =
    React.useState<profileObject | null>(null);
  //const [personDetails, setPersonDetails] = React.useState<any>(null);
  const [alertType, setAlertType] = React.useState<any>("success");
  const [alertOpen, setAlertOpen] = React.useState<boolean>(false);
  const [alertText, setAlertText] = React.useState<string>("");
  const [isSubData, setSubData] = React.useState<boolean>(false);

  let grid: Grid | null;

  const handleDataTyping = () => {
    if (data && data.length > 0) {
      let tempP: any = [...data];

      for (let p of tempP) {
        if (p.Date_Received) {
          p.Date_Received = new Date(p.Date_Received);
        }
        if (p.Date_Status_Change) {
          p.Date_Status_Change = new Date(p.Date_Status_Change);
        }
        if (p.DOB) {
          p.DOB = new Date(p.DOB);
        }
        if (p.Date_Fingerprint_Return) {
          p.Date_Fingerprint_Return = new Date(p.Date_Fingerprint_Return);
        }
        if (p.Date_PAC_Card_Issued) {
          p.Date_PAC_Card_Issued = new Date(p.Date_PAC_Card_Issued);
        }
        if (p.CreateDate) {
          p.CreateDate = new Date(p.CreateDate);
        }
        if (p.UpdateDate) {
          p.UpdateDate = new Date(p.UpdateDate);
        }
      }
      setTypedPersons(tempP);
    } else {
      setTypedPersons([]);
    }
  };

  const cloneReport = async (id: number | null = null) => {
    if ((editDialogID && editDialogID > 0) || (id && id > 0)) {
      let record = {
        ProfileReportObject: { ID: editDialogID ? editDialogID : id },
      };
      let res = await GetProfileReport(record);
      res.newReport = true;
      setEditingRecord(res);
      setEditTabName("New VolunteerHire Report");
      setTabValue("2");
    }
    setOpenEditDialog(false);
  };

  React.useEffect(() => {
    handleDataTyping();
  }, [data]);

  React.useEffect(() => {
    refreshDataSource();
  }, [typedPersons]);

  const showActionView = (rowData: any): any => {
    return (
      <span>
        <ButtonGroup>
          <Tooltip title="Edit" placement="top" arrow>
            <IconButton
              onClick={() => {
                handleEditReport(rowData.ID, false,true, rowData.MainRec);
              }}
            >
              <EditIcon fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete" placement="top" arrow>
            <IconButton
              onClick={() => {
                setOpenDeleteDialog(true);
                setDeleteDialogID(rowData.ID);
              }}
            >
              <DeleteIcon fontSize="small" color="error" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add Sub Details" placement="top" arrow>
            <IconButton
              onClick={() => {
                handleEditReport(rowData.ID, true,false, rowData.MainRec);
              }}
            >
              <Add fontSize="small" color="primary" />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      </span>
    );
  };

  const deleteReport = async () => {
    let id: number = deleteDialogID;
    if (id && id > 0) {
      let data = {
        id: id,
        editStatus: "DELETE",
        deleted: 1,
      };
      let isDeleted = await DeleteVolunteerHire(data);
      //handleSubmit()
      if (isDeleted) {
        setAlertType("success");
        setAlertOpen(true);
        setAlertText(
          "Delete Successful (Changes Will Take Effect After The Next Search)",
        );
      } else {
        setAlertType("error");
        setAlertOpen(true);
        setAlertText("Unable to delete.");
      }
    }
    setOpenDeleteDialog(false);
  };

  const viewReport = async (rowData: any) => {
    setReportUrl(undefined);
    let pdfUrl = await GetProfileReportPDF(rowData);
    if (pdfUrl) {
      setReportUrl(pdfUrl);
      setOpenDialog(true);
    }
  };

  const handleEditReport = async (rowDataID: any, isSubData: boolean, isedit: boolean, rowMainRec: any) => {
    setDuplicateReport(false);
    setSubData(isSubData);
    //console.log("row id" + rowDataID)
    
    if (rowDataID && rowDataID > 0) {
      let res = typedPersons.filter((item) => item.ID === rowDataID);
      //console.log(res[0]);
      if (isedit) {
        if (isSubData) {
          res[0].MainRec = rowMainRec;
        } else {
          res[0].MainRec = null;
        }
      } else {
        if (isSubData) {
          // //console.log("is sub data but for adding");
          res[0].EditStatus = "ADD";
          res[0].MainRec = res[0].ID;
          res[0].ID = null;
          // res[0].ID = null;
        } else {
          res[0].MainRec = null;
        }
      }
      setEditDialogID(rowDataID);
      setEditingRecord(res[0]);
      setEditTabName(
        isSubData
          ? `Edit VolunteerHire Report (${formatDate(res[0]?.HiringDate)})`
          : "Edit VolunteerHire Report",
      );
      setTabValue("2");

    }
  };
  const filterMainRecords = (data: VolunteerHireObject[]) => {
    return data.filter((item) => item.MainRec === null);
  };
  const formatDate = (inputDate: string | null | undefined) => {
    let dateObject;
    //console.log(inputDate);
    
    if (inputDate) {
      dateObject = new Date(inputDate);
      
      if (isNaN(dateObject.getTime())) {
        throw new RangeError("Invalid time value");
      }
    } else {
      dateObject = new Date();
    }
  
    const formattedDate = format(dateObject, "dd-MMM-yyyy");
    //console.log(formattedDate);
    return formattedDate;
  };

  const gridTemplateIndicator = (person: any): any => {
    return (
      <>
        <BTPersonIndicatorGrid person={person} />
      </>
    );
  };

  const GridToolsClick = (args: ClickEventArgs) => {
    if (grid && args.item.text === "Excel Export") {
      let excelProp: ExcelExportProperties = {};
      excelProp.fileName = "Profile_Report.xlsx";

      grid.excelExport(excelProp);
    } else if (grid && args.item.text === "PDF Export") {
      grid.pdfExport();
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    //history.push('/tools/profile_report');
    localStorage.setItem('voluntercreate', 'true');
    if (newValue == "1") {
      setEditTabName("New Report");
      setEditingRecord(null);
      setPersonDetails(null);
      setTabValue("1");
      //handleSubmit();
      setSubData(false);
    }
    setTabValue(newValue);
  };

  const refreshDataSource = () => {
    if (grid) {
      grid.dataSource = filterMainRecords(typedPersons);
    }
  };

  const handleReportSubmit = async (submitObj: VolunteerHireObject) => {
    let saveRequest = await submitVolunteerHire(submitObj);
    if(saveRequest && saveRequest.ErrorMessages?.length){
      setAlertType("error");
      setAlertText(saveRequest.ErrorMessages[0]);
      setAlertOpen(true);

      setTimeout(() => {
        window.location.reload();
      }, 2000);


    } else if (saveRequest) {
      setAlertType("success");
      setAlertText(
        "Report has been saved!(Changes Will Take Effect After The Reload)",
      );

      setAlertOpen(true);
      setTabValue("1");
      setEditTabName("New VolunteerHire Record");
      setEditingRecord({});
         // Reload the page after 2 seconds
      setTimeout(() => {
        window.location.reload();
      }, 2000); 
      
    } else if (saveRequest) {
      setAlertType("success");
      setAlertText("Report has been saved!");
      setAlertOpen(true);
      
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } 
  };

  const handleSentenceCase = (args: any) => {
    args = args.toLowerCase();
    args = args.split(" ");
    for (var i = 0; i < args.length; i++) {
      args[i] = args[i].charAt(0).toUpperCase() + args[i].slice(1);
    }
    return args.join(" ");
  };

  const rowSelected = (rowData: any) => {
    if (rowData && rowData.data.ID) {
      // viewReport(rowData.data.ID);
    }
  };

  let sortSettings: SortSettingsModel = {
    columns: [{ field: "ID", direction: "Ascending" }],
  };

  
  const getVolunterSubDetails = (volunteer: any) => {
    let users: any[] = typedPersons.filter(
      (person) => person?.MainRec === volunteer?.ID,
    );
    //console.log({ users });

    return users?.length > 0 ? (
      <div className={"mt-3 pl-5 pb-5 "}>
        <VolunteerSearchSubResults
          volunteer={users}
          handleEditReport={handleEditReport}
        />
      </div>
    ) : (
      <div className={"m-3 font-bold text-lg"}>No Records Found</div>
    );
  };

  const filteredData = filterMainRecords(typedPersons);
  //console.log("Filtered data" + typedPersons);
  return (
    <div className="m-5 p-5">
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={() => {
          setAlertOpen(false);
        }}
      >
        <Alert
          onClose={() => {
            setAlertOpen(false);
          }}
          severity={alertType}
          sx={{ width: "100%" }}
        >
          {alertText}
        </Alert>
      </Snackbar>
      <Box className="pt-3" style={{ justifyContent: "center" }}>
        <TabContext value={tabValue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleTabChange} variant="fullWidth">
              <Tab icon={<List />} label="List View" value={"1"} />
              {isUserPermission("NJPort") ? (
                <Tab icon={<PostAdd />} label={editTabName} value={"2"} />
              ) : (
                <></>
              )}
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className={"pl-10 pr-10 mt-5"}>
              {typedPersons && typedPersons.length > 0 && filteredData.length > 0 ? (
                <>
                  <h4 className={"font-bold pl-5"}>
                    {filteredData?.length} Records Found
                  </h4>
                  <GridComponent
                    dataSource={filteredData}
                    allowPaging={true}
                    pageSettings={{
                      pageSize: 25,
                      pageSizes: [10, 25, 50, 100],
                    }}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{ type: "CheckBox" }}
                    detailTemplate={getVolunterSubDetails}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: "Both" }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={["ExcelExport", "Search"]}
                    toolbarClick={GridToolsClick}
                    ref={(g) => (grid = g)}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                    rowSelected={rowSelected}
                  >
                    <ColumnsDirective>
                      <ColumnDirective
                        template={showActionView}
                        width={125}
                        minWidth={125}
                        maxWidth={140}
                        headerText="Action"
                      />
                      <ColumnDirective
                        field="ID"
                        width={140}
                        minWidth={140}
                        maxWidth={140}
                        headerText="ID"
                        customAttributes={{ class: ["e-attr"] }}
                      />
                      <ColumnDirective
                        field="HiringDateTime"
                        width={165}
                        minWidth={165}
                        maxWidth={165}
                        headerText="HiringDateTime"
                        customAttributes={{ class: ["e-attr"] }}
                      />
    
                      <ColumnDirective
                        field="PoolBiginning"
                        width={160}
                        minWidth={160}
                        maxWidth={160}
                        headerText="Pool Biginning"
                        customAttributes={{ class: ["e-attr"] }}
                      />
                      <ColumnDirective
                        field="StillWorking"
                        width={150}
                        minWidth={150}
                        maxWidth={150}
                        headerText="Still Working"
                        customAttributes={{ class: ["e-attr"] }}
                      />
                      <ColumnDirective
                        field="DidNotAnswer"
                        width={240}
                        minWidth={240}
                        maxWidth={240}
                        headerText="Declined/Did Not Answer"
                        customAttributes={{ class: ["e-attr"] }}
                      />
                    </ColumnsDirective>
                    <Inject
                      services={[
                        ColumnMenu,
                        Sort,
                        Page,
                        Filter,
                        DetailRow,
                        Toolbar,
                        ExcelExport,
                        PdfExport,
                        Search,
                        Resize,
                        Reorder,
                        Edit,
                        CommandColumn,
                      ]}
                    />
                  </GridComponent>
                </>
              ) : (
                <div className="font-bold text-center text-lg pt-3">
                  NO Matching Records Found
                </div>
              )}
            </div>
          </TabPanel>
          {isUserPermission("NJPort") ? (
            <TabPanel value="2">
              {/* {tabValue && tabValue === '2' ? <ProfileReportInputForm handleReportSubmit={handleReportSubmit} duplicateReport={duplicateReport} person = {hash} editID = {editDialogID} /> : <></>} */}
              {tabValue && tabValue === "2" ? (
                <VolunteerHireInputForm
                  handleReportSubmit={handleReportSubmit}
                  editReport={editingRecord}
                  isSubData={isSubData}
                />
              ) : (
                <></>
              )}
            </TabPanel>
          ) : (
            <></>
          )}
        </TabContext>
      </Box>
      {/* <Dialog
        open={openEditDialog}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={"sm"}
        scroll="paper"
        //onClose={EditDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <h2>Edit report</h2>
          <IconButton
            onClick={() => {
              setOpenEditDialog(false);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ display: "flex", justifyContent: "center" }}>
          <Typography>
            Please select option to edit existing report or import to new report
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleEditReport}>
            Edit Report
          </Button>
          <Button onClick={() => cloneReport()}>Import to new Report</Button>
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={openDeleteDialog}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={"sm"}
        scroll="paper"
        //onClose={handleDeleteDialogClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h2>Delete Report</h2>
          <IconButton
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ display: "flex", justifyContent: "center" }}>
          <Typography>
            Do you really want to delete this profile report? You can't undo
            this action.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={deleteReport}>Delete Report</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SearchResults;
