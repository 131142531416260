import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { DatePicker, DateTimePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
    Autocomplete,
    Box,
    Button,
    ButtonGroup,
    Container,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
    Checkbox, DialogActions, TablePagination, InputLabel, TextareaAutosize
} from '@mui/material';
import { getDropDownCity, getDropDownQuery } from "../../../services/getDropdown.service";
import { GetDefaultStateForSite, GetStringNameForSite } from "../../../interfaces/config.interface";
import { SiteName } from "../../../services/config.service";

import React, { ChangeEvent, useRef, useState } from 'react';
import HomelandSecurityLogo from '../../../assets/Images/DC_Homeland_Security.png';
import "./styles.css";
import {PIRReport, PIRReportCameraList} from "../../../interfaces/PIR.interface";
import {readFileAsDataURL} from "../../../services/formatDate.service";
import {GetGUID, GetPIR, SetPIRFile, SetPIRReport} from "../../../services/pir.service";
import PreliminaryCameraSearch from './_preliminaryCameraSearch';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface PreliminaryReportProps {
    handleReportSubmit: any,
    editReport: any,
    personDetails: any
}

interface dropdown {
    value: string,
    key: string
}


const PreliminaryReportInputForm: React.FC<PreliminaryReportProps> = ({ handleReportSubmit, editReport = null, personDetails = null }) => {
    const [stateList, setStateList] = React.useState<any>([]);
    const [cityList, setCityList] = React.useState<any>([]);
    const [heightDD, setHeightDD] = React.useState<dropdown[]>([]);
    const [report, setReport] = useState<PIRReport>({
        Summary_Requester: "RTCC",
        LprList:[{GUID:GetGUID()}],
        CameraList:[],
        IncidentList:[{}],
        DYRSVeritracks_List:[{GUID:GetGUID()}],
        ShotSpotter_List:[{GUID:GetGUID()}]
    });

    const [isLoading, setIsLoading] = React.useState(true);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [clearOpen, setClearOpen] = React.useState(false);
    const [cctvClearOpen, setCCTVClearOpen] = React.useState(false);
    const [privateClearOpen, setPrivateClearOpen] = React.useState(false);
    const [vehicleClearOpen, setVehicleClearOpen] = React.useState(false);
    const [cadClearOpen, setCadClearOpen] = React.useState(false);
    const [dyrsClearOpen, setDyrsClearOpen] = React.useState(false);
    const [shotSpotterClearOpen, setShotSpotterClearOpen] = React.useState(false);
    const [cameraSearchOpen, setCameraSearchOpen] = React.useState(false);

    const weekdays = [
        { label: "Sunday", value: "Sunday"},
        { label: "Monday", value: "Monday" },
        { label: "Tuesday", value: "Tuesday" },
        { label: "Wednesday", value: "Wednesday" },
        { label: "Thursday", value: "Thursday" },
        { label: "Friday", value: "Friday" },
        { label: "Saturday", value: "Saturday"}
    ];

    const currentDate = new Date();

    const startDate = new Date();
    startDate.setDate(currentDate.getDate() - 90);
    startDate.setHours(0, 0, 0, 0);
    
    React.useEffect(() => {
        handleInitialBuild()
        if(editReport){
            GetPIR(`${editReport}`).then((r:any)=>{
                if(r && r?.length > 0) {
                    var report:PIRReport = r[0]
                    if(report?.Files && report?.Files.length > 0){
                        for(var file of report?.Files){
                            if(file.Origination == "Individual Photo" || file.Origination == "Veritracks Screenshot")
                            {
                                var index = report?.DYRSVeritracks_List?.findIndex(f => f.ID === file?.ParentID)
                                if((index as any) >= 0 && report?.DYRSVeritracks_List && report?.DYRSVeritracks_List[(index as any)]){
                                    switch(file.Origination){
                                        case "Individual Photo":
                                            report.DYRSVeritracks_List[(index as any)].DYRS_IndividualPhoto = file
                                            break;
                                        case "Veritracks Screenshot":
                                            report.DYRSVeritracks_List[(index as any)].DYRS_VeritracksScreenshot = file
                                            break;
                                            
                                    }
                                }
                            }
                            else if(file.Origination == "ShotSpotter Screenshots"){
                                var index = report?.ShotSpotter_List?.findIndex((f:any) => f.ID === file?.ParentID)
                                if((index as any) >= 0 && report?.ShotSpotter_List){
                                    
                                    if(report?.ShotSpotter_List[(index as any)]) {
                                        
                                        if (!report?.ShotSpotter_List[(index as any)]?.Files)
                                            report.ShotSpotter_List[(index as any)].Files = []

                                        if (report?.ShotSpotter_List[(index as any)]?.Files)
                                            report?.ShotSpotter_List[(index as any)]?.Files?.push(file)
                                    }
                                    
                                }
                            }
                            else if(file.Origination == "LPR Screenshots"){
                                var index = report?.LprList?.findIndex((f:any) => f.ID === file?.ParentID)
                                if((index as any) >= 0 && report?.LprList){

                                    if(report?.LprList[(index as any)]) {

                                        if (!report?.LprList[(index as any)]?.Files)
                                            report.LprList[(index as any)].Files = []

                                        if (report?.LprList[(index as any)]?.Files)
                                            report?.LprList[(index as any)]?.Files?.push(file)
                                    }

                                }
                            }
                        }
                    }
                    if(!report?.ShotSpotter_List){
                        report.ShotSpotter_List = [{GUID:GetGUID()}]
                    }
                    setReport(report)
                }
                
                setIsLoading(false)
            })
        }else {
            setReport({
                Summary_Requester: "RTCC",
                LprList:[{}],
                CameraList:[],
                IncidentList:[{}],
                DYRSVeritracks_List:[{GUID:GetGUID()}],
                ShotSpotter_List:[{GUID:GetGUID()}]
            })
            setIsLoading(false)
        }
    }, [personDetails, editReport])
    
    const handleInitialBuild = async () => {
        await handleDropdowns()
    }

    const handleDropdowns = async () => {
        setStateList([])
        var state = await getDropDownQuery('State')
        if (state && state?.length > 0) {
            setStateList(state)
        }
        var city = await getDropDownCity(GetDefaultStateForSite(SiteName))
        let tempList = city?.filter(x => x.State === GetDefaultStateForSite(SiteName))
        const ids = tempList.map(({ City }) => City);
        const filtered = tempList.filter(({ City }, index) =>
            !ids.includes(City, index + 1));
        setCityList(filtered)

        createHeightDropDown()

    }

    const createHeightDropDown = () => {
        let temp = heightDD
        for (var i = 20; i <= 108; i++) {
            let h = "" + Math.floor(i / 12) + "' " + i % 12 + '"'
            temp.push({ value: h, key: h })
        }
        setHeightDD(temp)
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const handleDialog = (section:string, value:boolean) => {
        switch(section){
            case 'CCTV':
                setCCTVClearOpen(value);
                break;
            case 'Private Camera':
                setPrivateClearOpen(value);
                break;
            case 'Incident':
                setClearOpen(value);
                break
            case 'LPR':
                setVehicleClearOpen(value);
                break
            case 'CAD':
                setCadClearOpen(value);
                break
            case 'Shot Spotter':
                setShotSpotterClearOpen(value);
                break
            case 'DYRS':
                setDyrsClearOpen(value);
                break;
            case 'Camera Search':
                setCameraSearchOpen(value);
        }
        
    }

    const handleCameraSubmit = (cameras: PIRReportCameraList[]) => {
        setReport(prevReport => ({
            ...prevReport,
            CameraList: prevReport.CameraList ? [...prevReport.CameraList, ...cameras] : cameras
        }));
    };
    const handleInputChange = (e: { target: { name: string; value: any; }; }, table:string = "", item:any = null) => {
        const { name, value } = e.target;
        let temp:any= {...report}
        
        if(table?.length > 0 && item) {
            let index = temp[table]?.findIndex((f:any) => f === item)
            if (index >= 0) {
                temp[table][index][name] = (value instanceof Date ? value.toLocaleString() : value)
            } 
        }
        else {
            temp[name] = (value instanceof Date ? value.toLocaleString() : value)
        }
        
        setReport(temp)
    };
    
    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>, origination:string) => {
        if (e.target.files && e.target.files.length > 0) {
            let temp = {...report}
            let files = e.target.files
            
            if (!temp?.Files)
                temp.Files = []
            
            for (var index = 0; index < files?.length; index++) {
                let file = files?.item(index)
                if (file && temp?.Files) {
                    let fileData: any = await readFileAsDataURL(file)
                    temp?.Files?.push({Content: fileData?.toString().split('base64,')[1], FileName: file.name, FileEncoding: file.type, Origination: origination});
                }
            }
            setReport(temp)
            e.target.value = '';
        }
    }
    
    const handleShotSpotterFileChange = async (e: ChangeEvent<HTMLInputElement>, item:any) => {
        if (e.target.files && e.target.files.length > 0) {
            let temp = {...report}
            let files = e.target.files
            
            if(temp?.ShotSpotter_List && temp?.ShotSpotter_List?.length > 0) {
                var shotIndex = temp?.ShotSpotter_List?.findIndex((f: any) => f == item)

                if((shotIndex as any) >= 0) {
                    
                    if(!temp?.ShotSpotter_List[(shotIndex as any)]?.Files)
                        temp.ShotSpotter_List[(shotIndex as any)].Files = []
                    
                    for (var index = 0; index < files?.length; index++) {
                        let file = files?.item(index)
                        if (file && temp?.ShotSpotter_List[(shotIndex as any)]?.Files) {
                            let fileData: any = await readFileAsDataURL(file)
                            temp?.ShotSpotter_List[(shotIndex as any)]?.Files?.push({
                                Content: fileData?.toString().split('base64,')[1],
                                FileName: file.name,
                                FileEncoding: file.type,
                                Origination: "ShotSpotter Screenshots"
                            });
                        }
                    }
                }
            }
            setReport(temp)

        }
    }

    const handleLPRFileChange = async (e: ChangeEvent<HTMLInputElement>, item:any) => {
        if (e.target.files && e.target.files.length > 0) {
            let temp = {...report}
            let files = e.target.files

            if(temp?.LprList && temp?.LprList?.length > 0) {
                var lprIndex = temp?.LprList?.findIndex((f: any) => f == item)

                if((lprIndex as any) >= 0) {

                    if(!temp?.LprList[(lprIndex as any)]?.Files)
                        temp.LprList[(lprIndex as any)].Files = []

                    for (var index = 0; index < files?.length; index++) {
                        let file = files?.item(index)
                        if (file && temp?.LprList[(lprIndex as any)]?.Files) {
                            let fileData: any = await readFileAsDataURL(file)
                            temp?.LprList[(lprIndex as any)]?.Files?.push({
                                Content: fileData?.toString().split('base64,')[1],
                                FileName: file.name,
                                FileEncoding: file.type,
                                Origination: "LPR Screenshots"
                            });
                        }
                    }
                }
            }
            setReport(temp)

        }
    }
    
    const handleDYRSFileChange = async (e: ChangeEvent<HTMLInputElement>, item:any, origination:string) => {
        if (e.target.files && e.target.files.length > 0) {
            let temp = {...report}
            let file = e.target.files?.item(0)
            let index = temp?.DYRSVeritracks_List?.findIndex((f:any) => f === item)
            
            if (file && (index as any) >= 0) {
                let fileData: any = await readFileAsDataURL(file)
                switch(origination){
                    case "Veritracks Screenshot":
                        if(temp?.DYRSVeritracks_List && temp?.DYRSVeritracks_List[(index as any)] )
                            temp.DYRSVeritracks_List[(index as any)].DYRS_VeritracksScreenshot = {Content: fileData?.toString().split('base64,')[1], FileName: file.name, FileEncoding: file.type, Origination: origination};
                        break;
                    case "Individual Photo":
                        if(temp?.DYRSVeritracks_List && temp?.DYRSVeritracks_List[(index as any)] )
                            temp.DYRSVeritracks_List[(index as any)].DYRS_IndividualPhoto = {Content: fileData?.toString().split('base64,')[1], FileName: file.name, FileEncoding: file.type, Origination: origination};
                        break;
                        
                }
            }
            setReport(temp)
            e.target.value = '';
        }
    }

    const handleRemoveDYRSFile = (item: any, origination: string) => {
        let temp = { ...report }
        let index = temp?.DYRSVeritracks_List?.findIndex((f: any) => f === item)

        if ((index as any) >= 0) {
            switch (origination) {
                case "Veritracks Screenshot":
                    if (temp?.DYRSVeritracks_List && temp?.DYRSVeritracks_List[(index as any)]) {
                        if (temp.DYRSVeritracks_List[(index as any)].DYRS_VeritracksScreenshot?.ID) {
                            temp!.DYRSVeritracks_List[(index as any)]!.DYRS_VeritracksScreenshot!.deleted = true;
                        } else {
                            temp.DYRSVeritracks_List[(index as any)].DYRS_VeritracksScreenshot = null;
                        }
                    }
                    break;
                case "Individual Photo":
                    if (temp?.DYRSVeritracks_List && temp?.DYRSVeritracks_List[(index as any)]) {
                        if (temp.DYRSVeritracks_List[(index as any)].DYRS_IndividualPhoto?.ID) {
                            temp!.DYRSVeritracks_List[(index as any)]!.DYRS_IndividualPhoto!.deleted = true;
                        } else {
                            temp.DYRSVeritracks_List[(index as any)].DYRS_IndividualPhoto = null;
                        }
                    }
                    break;
            }
        }
        setReport({ ...temp })
    }

    const handleRemoveFile = (file: any, table:string = "", item:any = null) => {
        let temp:any = {...report}
        
        if(item && table?.length > 0){
            let objectIndex = temp[table]?.findIndex((fi:any) => fi === item)
            if((objectIndex as any) >= 0) {
                
                let findIndex = temp[table][objectIndex]?.Files?.findIndex((f: any) => f === file)
                if ((findIndex as any) >= 0) {
                    if (temp[table][objectIndex]?.Files[findIndex]?.ID && (temp[table][objectIndex]?.Files[findIndex].ID as any) > 0) {
                        temp[table][objectIndex].Files[findIndex].deleted = true
                    } else {
                        temp[table][objectIndex]?.Files?.splice(findIndex, 1)
                    }

                    setReport(temp)
                }
            }
        }
        else if(temp?.Files && temp?.Files?.length > 0){
            let findIndex = temp?.Files?.findIndex((f:any) => f === file)
            if((findIndex as any) >= 0){
                if (temp?.Files[findIndex]?.ID && (temp.Files[findIndex].ID as any) > 0 ) {
                    temp.Files[findIndex].deleted = true
                }
                else
                {
                    temp?.Files?.splice(findIndex,1)
                }

                setReport(temp)
            }
        }
    }
    
    const addTableRow = (table:string, field:string = "", value:string = "") => {
        var temp: any = { ...report }

        if (!Array.isArray(temp[table])) {
            temp[table] = [];
        }

        if (table === "CameraList") {
            if (field === "Camera_Type" && value === "CCTV/CAMERA") {
                let newRow = {
                    Camera_Type: "CCTV/CAMERA",
                    CCTV_Distance: "",
                    CCTV_Location: "",
                    CCTV_Department: "",
                };
                (newRow as any).isManualEntry = true;
                temp[table].push(newRow);
            }
            else if (field && value) {
                let newRow = { [field]: value };
                temp[table].push(newRow);
            } else {
                temp[table].push({});
            }

            //Skips to last page in pagination
            const totalRows = temp[table].length;
            const newPage = Math.ceil(totalRows / rowsPerPage) - 1;
            setPage(newPage);
        }
        
        else if (field && value) {
            let newRow = { [field]: value };
            temp[table].push(newRow);
        }
        else {
            temp[table].push({});
        }

        
        setReport(temp)
    };

    const deleteTableRow = (table:string, item:any) => {
        var temp: any = { ...report }
        console.log("Before deletion:", temp[table]);
        if(table?.length > 0 && item) {
            if (temp[table]) {
                let index = temp[table]?.findIndex((f: any) => f === item)
                console.log("Deleting index:", index, "Item:", item);
                if ((index as any) >= 0) {
                    if (temp[table][index]?.ID && (temp[table][index].ID as any) > 0) {
                        temp[table][index].deleted = true
                    } else {
                        temp[table]?.splice(index, 1)
                    }

                    if (table === "DYRSVeritracks_List" && temp[table].length === 0) {
                        temp.DYRS_ReturnedCloseProximity = false;
                        const events = [
                            { target: { name: "DYRS_ReturnedCloseProximity", checked: false } }
                        ];
                        events.forEach(event => handleCheckboxChange(event));
                        temp[table] = [];
                    }
                    console.log("After deletion:", temp[table]);
                    setReport(temp)
                }
            }
        }
    };
 
    //const clearTableRows = (table:string, field:string = "", value:string = "") => {
    //    let temp:any = {...report}
    //    if(temp[table]) {
    //        for (var i of temp[table]?.filter((f:any) => (field?.length > 0 && value?.length > 0 ? f[field] == value : true) && !f?.deleted)) {
    //            if ((i?.ID as any) > 0) {
    //                i.deleted = true
    //            }
    //            else
    //            {
    //                var index = temp[table].findIndex((x:any) => x == i)
    //                if(index >= 0)
    //                    temp[table].splice(index,1)
    //            }
    //        }

    //        //If I could get this to run after setReport its just AddTableRow
    //        if(field?.length > 0 && value?.length > 0){
    //            let item:any = {}
    //            item[field] = value
    //            temp[table]?.push(item)
    //        }
    //        else{
    //            temp[table]?.push({})
    //        }

    //        setReport(temp)
    //    }
    //}

    const clearTableRows = (table: string, field = "", value = "") => {
        let temp: any = { ...report };
        if (temp[table]) {
            if (table === "CameraList") {
                // Clears specific items in CameraList based on field and value
                temp[table] = temp[table]?.filter((item: any) => item[field] !== value || item.deleted);
            } else if (table === "DYRSVeritracks_List") {
                // Clear DYRSVeritracks_List and uncheck the checkbox
                temp.DYRS_ReturnedCloseProximity = false;
                const events = [
                    { target: { name: "DYRS_ReturnedCloseProximity", checked: false } }
                ];
                events.forEach(event => handleCheckboxChange(event));
                temp[table] = [];
            } else {
                temp[table] = [];
            }
            setReport(temp);
        }
    };


    const handleCheckboxChange = (e: { target: { name: any; checked: any; }; }) => {
        const { name, checked } = e.target;
        let temp:any = {...report}
        
        if(name === "CCTV_NoneInArea" && checked){
            var cctvCheckbox = ["CCTV_MPDReviewed","CCTV_DCNotReviewable","CCTV_SchoolNotReviewable","CCTV_MPDNotReviewable"]
            for(var i of cctvCheckbox){
                temp[i] = false
            }
        }
        if(name === "DYRS_ZeroReturns" && checked){
            var dyrsCheckbox = ["DYRS_NoCloseProximity","DYRS_ReturnedCloseProximity"]
            for(var i of dyrsCheckbox){
                temp[i] = false
            }
        }

        if (name.startsWith("LPR_CustomQuery_")) {
            const index = parseInt(name.split("_")[2], 10); // Extract index from name
            if (!isNaN(index) && temp.LprList && temp.LprList.length > index) {
                temp.LprList[index] = {
                    ...temp.LprList[index],
                    LPR_CustomQuery: checked
                };
            }
        }

        else {
            temp[name] = checked
        }
        
        
        setReport(temp)
    };

    const handleSubmit = () => {
        var noFilesReport = JSON.parse(JSON.stringify({...report}))
        if(noFilesReport?.Files && noFilesReport?.Files?.length > 0) {
            noFilesReport.Files = []
        }
        if(noFilesReport?.DYRSVeritracks_List && noFilesReport?.DYRSVeritracks_List?.length > 0)
        {
            for(var i of noFilesReport?.DYRSVeritracks_List)
            {
                if (i.DYRS_VeritracksScreenshot?.deleted) {
                    i.DYRS_VeritracksScreenshot = null
                }
                if (i.DYRS_IndividualPhoto?.deleted) {
                    i.DYRS_IndividualPhoto = null
                }
            }
        }
        if(noFilesReport?.ShotSpotter_List && noFilesReport?.ShotSpotter_List?.length > 0)
        {
            for(var i of noFilesReport?.ShotSpotter_List)
            {
                if (i.Files && i.Files.lenght > 0) {
                    i.Files = []
                }
                
            }
        }
        if(noFilesReport?.LprList && noFilesReport?.LprList?.length > 0)
        {
            for(var i of noFilesReport?.LprList)
            {
                if (i.Files && i.Files.lenght > 0) {
                    i.Files = []
                }

            }
        }
        SetPIRReport(noFilesReport).then(async (res) => {
            await handleSubmitFiles(res)
            handleReportSubmit(res)
        })
    };
    
    const handleSubmitFiles = async (data: any) => {
        let temp = {...report}

        if (!temp?.Files)
            temp.Files = []

        if (temp?.DYRSVeritracks_List && temp?.DYRSVeritracks_List?.length > 0) {
            for (var i of temp?.DYRSVeritracks_List) {
                let index = data?.DYRSVeritracks_List?.findIndex((f: any) => f.GUID === i.GUID)
                if (index >= 0) {
                    if (i.DYRS_VeritracksScreenshot && !i.DYRS_VeritracksScreenshot.deleted) {
                        console.log(i, index)
                        i.DYRS_VeritracksScreenshot.ParentID = data?.DYRSVeritracks_List[index]?.ID
                        temp.Files.push(i.DYRS_VeritracksScreenshot)
                    }
                    if (i.DYRS_IndividualPhoto && !i.DYRS_IndividualPhoto.deleted) {
                        i.DYRS_IndividualPhoto.ParentID = data?.DYRSVeritracks_List[index]?.ID
                        temp.Files.push(i.DYRS_IndividualPhoto)
                    }
                }
            }
        }
        if (temp?.ShotSpotter_List && temp?.ShotSpotter_List?.length > 0) {
            for (var shot  of temp?.ShotSpotter_List) {
                let index = data?.ShotSpotter_List?.findIndex((f: any) => f.GUID === shot.GUID)
                if (index >= 0) {
                    if (shot?.Files && shot?.Files.length > 0) {
                        for(var file of shot?.Files) {
                            file.ParentID = data?.ShotSpotter_List[index]?.ID
                            temp.Files.push(file)
                        }
                    }
                }
            }
        }
        if (temp?.LprList && temp?.LprList?.length > 0) {
            for (var lpr  of temp?.LprList) {
                let index = data?.LprList?.findIndex((f: any) => f.GUID === lpr.GUID)
                if (index >= 0) {
                    if (lpr?.Files && lpr?.Files.length > 0) {
                        for(var file of lpr?.Files) {
                            file.ParentID = data?.LprList[index]?.ID
                            temp.Files.push(file)
                        }
                    }
                }
            }
        }
        if (temp?.Files && temp?.Files.length > 0) {
            for (var f of temp?.Files) {
                f.ReportID = data?.ID
                await SetPIRFile(f)
            }
        }
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Container>
                <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
                    <Typography variant="h4" gutterBottom>
                        Preliminary Investigative Report
                    </Typography>
                    <Typography variant="h5" sx={{fontStyle: 'italic'}} gutterBottom>
                        Metropolitan Police Department
                    </Typography>
                    <img style={{height: "15%", width: "15%"}} src={HomelandSecurityLogo} alt="DC Homeland Security"/>
                </Box>

                {!isLoading &&
                <div>
                    <Grid container spacing={3} marginTop="2%" className="border-double border-2 border-inherit">

                        <div className="container mt-4 ml-4 mr-5">
                            {/* SUMMARY Header */}
                            <Box className="header" display="flex" justifyContent="center" width="100%">
                                <Typography variant="h5">
                                    SUMMARY
                                </Typography>
                            </Box>

                            <Grid container spacing={3} className="pl-2 pr-2 mb-6">

                                {/* Requester */}
                                <Grid item xs={12} style={{ textAlign: 'center' }}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Requested By:
                                        </FormLabel>
                                        <RadioGroup row className="mt-2" aria-label="Requester"
                                            name="Summary_Requester"
                                            value={report?.Summary_Requester}
                                            onChange={handleInputChange}
                                            defaultValue="RTCC">
                                            <FormControlLabel value="RTCC" control={<Radio />} label="RTCC" />
                                            <FormControlLabel value="Detective" control={<Radio />} label="Detective" />
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                {/* Date and Time*/}
                                <Grid item xs={6}>
                                    <DateTimePicker
                                        label="Date and Time"
                                        ampm={false}
                                        value={report?.Summary_DateTime ?? ""}
                                        onChange={(date) => handleInputChange({target: {name:"Summary_DateTime", value:(date ?? "")}})}
                                        renderInput={(params) => <TextField {...params} fullWidth/>}
                                    />
                                </Grid>
                                {/* Incident Type */}
                                <Grid item xs={6}>
                                    <TextField
                                        label="Incident Type"
                                        name="Summary_Type"
                                        value={report?.Summary_Type ?? ""}
                                        onChange={handleInputChange}
                                        fullWidth

                                    />
                                </Grid>

                                {/* Location */}
                                <Grid item xs={5}>
                                    <TextField
                                        label="Location"
                                        name="Summary_Location"
                                        value={report?.Summary_Location ?? ""}
                                        onChange={handleInputChange}
                                        fullWidth

                                    />
                                </Grid>

                                {/* PSA */}
                                <Grid item xs={1}>
                                    <TextField
                                        label="PSA"
                                        name="Summary_PSA"
                                        value={report?.Summary_PSA ?? ""}
                                        onChange={handleInputChange}
                                        fullWidth

                                    />
                                </Grid>

                                {/* CCN */}
                                <Grid item xs={2}>
                                    <TextField
                                        label="CCN"
                                        name="Summary_CCN"
                                        value={report?.Summary_CCN ?? ""}
                                        onChange={handleInputChange}
                                        fullWidth

                                    />
                                </Grid>

                                {/* CRS First Name */}
                                <Grid item xs={2}>
                                    <TextField
                                        label="CRS First Name"
                                        name="Summary_SpecialistFirstName"
                                        value={report?.Summary_SpecialistFirstName ?? ""}
                                        onChange={handleInputChange}
                                        fullWidth

                                    ></TextField>
                                </Grid>

                                <Grid item xs={2}>
                                    <TextField
                                        label="CRS Last Name"
                                        name="Summary_SpecialistLastName"
                                        value={report?.Summary_SpecialistLastName ?? ""}
                                        onChange={handleInputChange}
                                        fullWidth

                                    ></TextField>
                                </Grid>
                            </Grid>

                            {/* Radio */}
                            <Grid item xs={12} className="pl-2 pr-2">
                                {report?.Summary_Requester === "RTCC" && (
                                <div>   
                                <Typography variant="body1" component="span">
                                The RTCC initiated a Preliminary Investigative Report in reference to the above
                                offense based on the CAD call and actively monitoring the
                            </Typography>
                            <TextField
                                name="Summary_Radio"
                                value={report?.Summary_Radio ?? ""}
                                onChange={handleInputChange}
                                rows={1}

                                size="small"
                                style={{width: '8%', margin: '0 10px', verticalAlign: 'middle'}}
                                inputProps={{
                                    style: {
                                        borderRadius: "1px",
                                        height: "10px",
                                        textAlign: 'center'
                                    }
                                }}
                            />
                            <Typography variant="body1" component="span">
                                radio transmissions. Please advise if further investigation reveals additional or
                                    conflicting information necessary to update this initial report. </Typography>
                                </div>)}

                                {report?.Summary_Requester === "Detective" && ( 
                                <div>
                                <Typography
                                    style={{
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                          }}>
                                Detective
                                </Typography>
                                <TextField
                                    name="Summary_Detective"
                                    value={report?.Summary_Detective ?? ""}
                                    onChange={handleInputChange}
                                    rows={1}

                                    size="small"
                                    style={{width: '15%', margin: '0 10px', verticalAlign: 'middle'}}
                                    inputProps={{
                                        style: {
                                            borderRadius: "1px",
                                            height: "10px",
                                            textAlign: 'center'
                                        }
                                    }}
                                />
                                <Typography variant="body1" component="span">
                                    of
                                </Typography>
                                <TextField
                                    name="Summary_Detective_District"
                                    value={report?.Summary_Detective_District ?? ""}
                                    onChange={handleInputChange}
                                    rows={1}
                                    size="small"
                                    style={{width: '15%', margin: '0 10px', verticalAlign: 'middle'}}
                                    inputProps={{
                                        style: {
                                            borderRadius: "1px",
                                            height: "10px",
                                            textAlign: 'center'
                                        }
                                    }}
                                />
                                <Typography variant="body1" component="span">
                                    requested a Preliminary Investigative Report in reference to the incident
                                                listed above.
                                </Typography>
                                </div>
                                )}
                               
                                    <br/>
                                    <br/>
                                    <Typography>
                                    Add any positive results from CCTV/cameras, DYRS, ShotSpotter, or LPR. Do NOT
                                    include any other information (e.g., no more running resume).
                                    </Typography>
                                <TextField
                                    label="Summary of Positive Results"
                                    name="Summary_Narrative"
                                    value={report?.Summary_Narrative ?? ""}
                                    onChange={handleInputChange}
                                    multiline
                                    rows={10}
                                    fullWidth

                                    style={{marginTop: '10px'}}
                                />
                                <Typography variant="body1" component="span">
                                    For any questions or additional requests for information, please contact the Real
                                    Time Crime Center at 202-727-0559 or <a className="a" href="mailto:mpdcrs@dc.gov">mpdcrs@dc.gov</a>
                                </Typography>
                            </Grid>
                        </div>

                        <div className="container mt-4 ml-4 mr-5">
                            {/* CCTV/CAMERAS Header */}
                            <Box className="header" display="flex" justifyContent="center" width="100%">
                                <Typography variant="h5">
                                    CCTV/CAMERAS
                                </Typography>
                            </Box>
                            
                            {/* CCTV Location Map */}
                            <Grid item xs={12} className="pl-2 pr-2">
                                <Typography variant="body1" component="span">
                                    Please upload a screenshot of MPD, DDOT, DCPS, Capital Shield, and other relevant
                                    cameras within
                                    1,250 feet of the incident location.
                                    <br/>
                                    (MPD CCTV and OCTO - CCTV, take out duplicates)
                                    <br/>
                                    (Take off gang/crew layer, schools, private cameras, etc. All layers except OCTO/MPD
                                    cameras)
                                    <br/>
                                </Typography>
                                <Button
                                    variant="contained"
                                    component="label"
                                    sx={{
                                        marginTop: "2%",
                                        marginBottom: "2%",
                                    }}
                                >
                                    Upload Screenshot of Camera Locations
                                    <input
                                        type="file"
                                        accept="image/jpeg, image/png, image/gif"
                                        hidden
                                        multiple
                                        onChange={(e) => handleFileChange(e, "Camera Locations")}
                                    />
                                </Button>
                                <Button
                                        variant="contained"
                                        component="label"
                                        sx={{
                                            marginTop: "2%",
                                            marginBottom: "2%",
                                            marginLeft: "2%"
                                        }}
                                        onClick={() => handleDialog("Camera Search", true)}
                                >
                                    Import Camera(s)
                                </Button>
                                <Dialog
                                        open={cameraSearchOpen}
                                        onClose={() => handleDialog("Camera Search", false)}
                                        aria-labelledby="clear-dialog"
                                        aria-describedby="clear-cameras"
                                        maxWidth="lg"
                                        fullWidth={true}
                                >
                                    <DialogContent className="pb-8">
                                            <PreliminaryCameraSearch handleCameraSubmit={handleCameraSubmit} handleCameraClose={() => handleDialog("Camera Search", false)} />
                                    </DialogContent>
                                </Dialog>
                            </Grid>

                            {/* Display Uploaded Image */}
                            {(report?.Files && report?.Files?.filter(f => f?.Origination === "Camera Locations" && !f?.deleted)?.length > 0) && (
                                <Grid item xs={12}>
                                    <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
                                        {report?.Files?.filter(f => f.Origination === "Camera Locations" && !f?.deleted)?.map((file, index) => (
                                            <Box key={index} position="relative" display="inline-block"
                                                 marginBottom="10px">
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => handleRemoveFile(file)}
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 0,
                                                        zIndex: 1,
                                                        color: 'red'
                                                    }}
                                                >
                                                    <CloseIcon/>
                                                </IconButton>
                                                <img
                                                    src={file?.Content && file?.Content?.length > 0 ? "data:image/png;base64," + file?.Content : ""}
                                                    alt={`Screenshot ${index + 1}`}
                                                    style={{
                                                        width: '75%',
                                                        height: '75%',
                                                        objectFit: 'cover',
                                                        border: '1px solid #ddd',
                                                        padding: '5px'
                                                    }}
                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                </Grid>
                            )}

                            {/* Table */}
                                {report?.CameraList && report?.CameraList?.length > 0 && (
                                    <Grid item xs={12} className="pl-2 pr-2">
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Camera #</TableCell>
                                                    <TableCell>Distance (feet)</TableCell>
                                                    <TableCell>Camera Name</TableCell>
                                                    <TableCell>Department</TableCell>
                                                    <TableCell>Actions</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {report?.CameraList?.filter((f: any) => f.Camera_Type === "CCTV/CAMERA" && !f.deleted)?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{(page === 0 ? index + 1 : index + (rowsPerPage * page) + 1)}</TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name="CCTV_Distance"
                                                                value={row?.CCTV_Distance ?? " feet"}
                                                                onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name="CCTV_Location"
                                                                value={row?.CCTV_Location ?? ""}
                                                                onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name="CCTV_Department"
                                                                value={row?.CCTV_Department ?? "MPDC"}
                                                                onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <Tooltip title="Delete Camera">
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    onClick={() => deleteTableRow("CameraList", row)}
                                                                >
                                                                    <DeleteIcon sx={{ color: "red" }} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 25, 50]}
                                            component="div"
                                            count={report.CameraList ? report?.CameraList?.filter((f: any) => f.Camera_Type === "CCTV/CAMERA").length : 0}
                                            rowsPerPage={rowsPerPage}
                                            page={page}
                                            onPageChange={handleChangePage}
                                            onRowsPerPageChange={handleChangeRowsPerPage}
                                        />
                                    </Grid>
                                ) }
                                

                                    <Grid item xs={12}>
                                        <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                            <ButtonGroup variant="contained" aria-label="Table buttons" size="small"
                                                color="inherit">
                                                <Button
                                                    variant="contained"
                                                    startIcon={<AddIcon />}
                                                    onClick={() => addTableRow("CameraList", "Camera_Type", "CCTV/CAMERA")}
                                                >
                                                    Add Camera Manually
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    startIcon={<ClearIcon />}
                                                    onClick={() => handleDialog("CCTV", true)}
                                                >
                                                    Clear All Cameras
                                                </Button>
                                                <Dialog
                                                    open={cctvClearOpen}
                                                    onClose={() => handleDialog("CCTV", false)}
                                                    aria-labelledby="clear-dialog"
                                                    aria-describedby="clear-cameras"
                                                >
                                                    <DialogTitle id="clear-dialog-title">
                                                        {"Clear all existing cameras?"}
                                                    </DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText id="clear-cameras">
                                                            Are you sure you would like to clear all cameras in the list?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={() => handleDialog("CCTV", false)}>No</Button>
                                                        <Button onClick={() => { clearTableRows("CameraList", "Camera_Type", "CCTV/CAMERA"); handleDialog("CCTV", false) }} autoFocus>
                                                            Yes
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </ButtonGroup>
                                        </Box>
                                    </Grid>
                            
                            {/* Additional CCTV Checkboxes */}
                            <Grid item xs={12} className="pl-2 pr-2" style={{marginTop:'2%'}}>
                                <Typography variant="h6" gutterBottom>
                                    CCTV/Cameras Review
                                </Typography>
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        control={<Checkbox checked={report?.CCTV_NoneInArea ?? false}
                                                           onChange={handleCheckboxChange}
                                                           name="CCTV_NoneInArea"/>}
                                        label="No MPD, DDOT, or Capital Shield cameras in the immediate area of the incident location."
                                        disabled={(report?.CCTV_MPDReviewed || report?.CCTV_MPDNotReviewable) ?? false}
                                    />
                                    <FormControlLabel
                                        control={<Checkbox checked={report?.CCTV_MPDReviewed ?? false}
                                                           onChange={handleCheckboxChange}
                                                           name="CCTV_MPDReviewed"/>}
                                        label="MPD, DDOT, or Capital Shield camera reviewed."
                                        disabled={report?.CCTV_NoneInArea ?? false}
                                    />
                                    {report?.CCTV_MPDReviewed && (
                                        <>
                                            {report?.CameraList?.filter(f => f?.Camera_Type === "MPDReviewed" && !f?.deleted).map((row, index) => (
                                                <Grid container spacing={2} key={index} alignItems="center">
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            label="Camera Location"
                                                            name="CCTV_Location"
                                                            value={row?.CCTV_Location ?? ""}
                                                            onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={4}>
                                                        <TextField
                                                            label="Review Time"
                                                            name="CCTV_ReviewTime"
                                                            value={row?.CCTV_ReviewTime ?? ""}
                                                            onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                            <Tooltip title="Delete Camera">
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    onClick={() => deleteTableRow("CameraList", row)}
                                                                >
                                                                    <DeleteIcon sx={{color: "red"}}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <Button onClick={() => addTableRow("CameraList", "Camera_Type", "MPDReviewed")} startIcon={<AddIcon/>}>
                                                Add Another Camera
                                            </Button>
                                        </>
                                    )}

                                    <FormControlLabel
                                        control={<Checkbox checked={report?.CCTV_DCNotReviewable ?? false}
                                                           onChange={handleCheckboxChange}
                                                           name="CCTV_DCNotReviewable"/>}
                                        label="Possibly a DC Housing Camera but not remotely reviewable"
                                    />
                                    {report?.CCTV_DCNotReviewable && (
                                        <>
                                            {report?.CameraList?.filter(f => f?.Camera_Type === "DCNotReviewable" && !f?.deleted).map((row, index) => (
                                                <Grid container spacing={2} key={index} alignItems="center">
                                                    <Grid item xs={10}>
                                                        <TextField
                                                            label="DC Housing Camera Location"
                                                            name="CCTV_Location"
                                                            value={row?.CCTV_Location ?? ""}
                                                            onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        {index !== 0 && (
                                                            <Tooltip title="Delete Camera">
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    disabled={index === 0}
                                                                    onClick={() => deleteTableRow("CameraList", row)}
                                                                >
                                                                    <DeleteIcon sx={{color: "red"}}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <Button
                                                onClick={() => addTableRow("CameraList", "Camera_Type", "DCNotReviewable")}
                                                startIcon={<AddIcon/>}>
                                                Add Another Camera
                                            </Button>
                                        </>
                                    )}

                                    <FormControlLabel
                                        control={<Checkbox checked={report?.CCTV_SchoolNotReviewable ?? false}
                                                           onChange={handleCheckboxChange}
                                                           name="CCTV_SchoolNotReviewable"/>}
                                        label="Possibly a camera located at School but not remotely reviewable"
                                    />
                                    {report?.CCTV_SchoolNotReviewable && (
                                        <>
                                            {report?.CameraList?.filter(f => f?.Camera_Type === "SchoolNotReviewable" && !f?.deleted).map((row, index) => (
                                                <Grid container spacing={2} key={index} alignItems="center">
                                                    <Grid item xs={10}>
                                                        <TextField
                                                            label="School Camera Location"
                                                            name="CCTV_Location"
                                                            value={row?.CCTV_Location ?? ""}
                                                            onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        {index !== 0 && (
                                                            <Tooltip title="Delete Camera">
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    disabled={index === 0}
                                                                    onClick={() => deleteTableRow("CameraList", row)}
                                                                >
                                                                    <DeleteIcon sx={{color: "red"}}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <Button onClick={() => addTableRow("CameraList", "Camera_Type", "SchoolNotReviewable")} startIcon={<AddIcon/>}>
                                                Add Another Camera
                                            </Button>
                                        </>
                                    )}

                                    <FormControlLabel
                                        control={<Checkbox checked={report?.CCTV_MPDNotReviewable ?? false}
                                                           onChange={handleCheckboxChange}
                                                           name="CCTV_MPDNotReviewable"/>}
                                        label="MPD Photo Enforcement Camera located in close proximity but not remotely reviewable (please click 'Yes' or 'No' for whether this is due to technical difficulties)"
                                        disabled={report?.CCTV_NoneInArea ?? false}
                                    />
                                    {report?.CCTV_MPDNotReviewable && (
                                        <>
                                            {report?.CameraList?.filter(f => f?.Camera_Type === "MPDNotReviewable" && !f?.deleted).map((row, index) => (
                                                <Grid container spacing={2} key={index} alignItems="center">
                                                    <Grid item xs={6}>
                                                        <TextField
                                                            label="MPD Photo Enforcement Camera Location"
                                                            name="CCTV_Location"
                                                            value={row?.CCTV_Location ?? ""}
                                                            onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <RadioGroup
                                                            aria-label="technical difficulties"
                                                            name="CCTV_TechnicalDifficulties"
                                                            value={row?.CCTV_TechnicalDifficulties ?? ""}
                                                            onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                        >
                                                            <FormControlLabel value="Yes" control={<Radio/>}
                                                                              label="Yes"/>
                                                            <FormControlLabel value="No" control={<Radio/>} label="No"/>
                                                        </RadioGroup>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        {index !== 0 && (
                                                            <Tooltip title="Delete Camera">
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    disabled={index === 0}
                                                                    onClick={() => deleteTableRow("CameraList", row)}
                                                                >
                                                                    <DeleteIcon sx={{color: "red"}}/>
                                                                </IconButton>
                                                            </Tooltip>
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            ))}
                                            <Button onClick={() => addTableRow("CameraList", "Camera_Type", "MPDNotReviewable")} startIcon={<AddIcon/>}>
                                                Add Another Camera
                                            </Button>
                                        </>
                                    )}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} className="pl-2 pr-2" style={{marginTop:'2%'}}>
                                <Typography variant="h6" gutterBottom>
                                    Camera Observations
                                </Typography>
                                <TextField
                                    label="Positive Camera Observations"
                                    name="CCTV_Observations"
                                    value={report?.CCTV_Observations ?? ""}
                                    onChange={handleInputChange}
                                    multiline
                                    rows={10}
                                    fullWidth

                                    style={{ marginTop: '5x' }}
                                />
                            </Grid>

                            {/* Screenshots */}
                            <Grid item xs={12} className="pl-2 pr-2" style={{marginTop:'2%'}}>
                                <Typography variant="h6" gutterBottom>
                                    CCTV Screenshots
                                </Typography>
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        component="label"

                                        sx={{
                                            marginBottom: "2%"
                                        }}
                                    >
                                        Upload CCTV Screenshots
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/png, image/gif"
                                            hidden
                                            multiple
                                            onChange={(e) => handleFileChange(e, "CCTV Screenshots")}
                                        />
                                    </Button>
                                </Grid>
                                {(report?.Files && report?.Files?.filter(f => f?.Origination === "CCTV Screenshots" && !f?.deleted)?.length > 0) && (
                                    <Grid item xs={12}>
                                        <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
                                            {report?.Files?.filter(f => f.Origination === "CCTV Screenshots" && !f?.deleted)?.map((file, index) => (
                                                <Box key={index} position="relative" display="inline-block"
                                                     marginBottom="10px">
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => handleRemoveFile(file)}
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 0,
                                                            zIndex: 1,
                                                            color: 'red'
                                                        }}
                                                    >
                                                        <CloseIcon/>
                                                    </IconButton>
                                                    <img
                                                        src={file?.Content && file?.Content?.length > 0 ? "data:image/png;base64," + file?.Content : ""}
                                                        alt={`CCTV Screenshot ${index + 1}`}
                                                        style={{
                                                            width: '75%',
                                                            height: '75%',
                                                            objectFit: 'cover',
                                                            border: '1px solid #ddd',
                                                            padding: '5px'
                                                        }}
                                                    />
                                                </Box>
                                            ))}
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        </div>

                        <div className="container mt-4 ml-4 mr-5">
                            {/* DYRS Veritracks header */}
                            <Box className="header" display="flex" flexDirection="column" alignItems="center"
                                 textAlign="center"
                                 width="100%">
                                <Typography variant="h5">
                                    DYRS VERITRACKS
                                </Typography>
                            </Box>
                            
                            <Grid item xs={12} className="pl-2 pr-2">
                                <Button
                                    variant="contained"
                                    component="label"
                                    style={{marginTop:'2%'}}
                                >
                                    Upload DYRS Screenshot
                                    <input
                                        type="file"
                                        accept="image/png, image/jpeg, image/gif"
                                        hidden
                                        multiple
                                        onChange={(e) => handleFileChange(e, "DYRS Screenshot")}
                                    />
                                </Button>
                            </Grid>

                            {report?.Files?.filter(f => f?.Origination === "DYRS Screenshot" && !f?.deleted)?.map((file, index) => (
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2} position="relative">
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => handleRemoveFile(file)}
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                                zIndex: 1,
                                                color: 'red'
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <img
                                            src={file?.Content && file?.Content?.length > 0 ? "data:image/png;base64," + file?.Content : ""}
                                            alt="Uploaded Screenshot"
                                            style={{
                                                width: '35%',
                                                height: '35%',
                                                objectFit: 'cover',
                                                border: '1px solid #ddd',
                                                padding: '5px'
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            ))}
                            
                            {/* Veritracks Query Information*/}
                            <Grid item xs={12} className="pl-2 pr-2">
                                <TextField
                                    label="Location Queried"
                                    name="DYRS_Location"
                                    value={report?.DYRS_Location ?? ""}
                                    onChange={handleInputChange}
                                    style={{
                                        width: '50%',
                                        marginTop: '2%',
                                        marginBottom: '2%'
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} className="pl-2 pr-2">
                                <Box display="flex" alignItems="center">
                                    <DateTimePicker
                                        label="Query Start Date/Time"
                                        ampm={false}
                                        value={report?.DYRS_SearchStartDate ?? ""}
                                        onChange={(date) => handleInputChange({target: {name:"DYRS_SearchStartDate", value:(date ?? "")}})}
                                        renderInput={(params) => <TextField {...params}
                                                                            style={{marginBottom: '2%'}}/>}
                                    />
                                    <Box sx={{mx: 1, marginBottom: '2%'}}> to </Box>
                                    <DateTimePicker
                                        label="Query End Date/Time"
                                        ampm={false}
                                        value={report?.DYRS_SearchEndDate ?? ""}
                                        onChange={(date) => handleInputChange({target: {name:"DYRS_SearchEndDate", value:(date ?? "")}})}
                                        renderInput={(params) => <TextField {...params}
                                                                            style={{marginBottom: '2%'}}/>}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} className="pl-2 pr-2">
                                <DateTimePicker
                                    label="Time/Date Query Was Conducted"
                                    ampm={false}
                                    value={report?.DYRS_QueryDateTime ?? ""}
                                    onChange={(date) => handleInputChange({target: {name:"DYRS_QueryDateTime", value:(date ?? "")}})}
                                    renderInput={(params) => <TextField {...params}
                                                                        style={{width: '35%', marginBottom: '3%'}}/>}
                                />
                                <FormControl component="fieldset">
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={report?.DYRS_ZeroReturns ?? false}
                                                        disabled={(report?.DYRS_NoCloseProximity || report?.DYRS_ReturnedCloseProximity) ?? false}
                                                        onChange={handleCheckboxChange}
                                                        name="DYRS_ZeroReturns"
                                                    />
                                                }
                                                label="The query of the Juvenile Veritracks System returned ZERO individuals in close proximity to the incident location monitored under DYRS supervision."
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={report?.DYRS_NoCloseProximity ?? false}
                                                        onChange={handleCheckboxChange}
                                                        disabled={(report?.DYRS_ZeroReturns || report?.DYRS_ReturnedCloseProximity) ?? false}
                                                        name="DYRS_NoCloseProximity"
                                                    />
                                                }
                                                label={
                                                    <Box display="flex" alignItems="center">
                                                        <Typography variant="body1" component="span" mr={1}>
                                                            The query of the Juvenile Veritracks System returned
                                                            individuals monitored under
                                                            DYRS supervision. All of the hits that were returned were
                                                            not in close proximity
                                                            to the incident location.
                                                        </Typography>
                                                    </Box>
                                                }
                                            />
                                        </Grid>
                                        {report?.DYRS_NoCloseProximity && (
                                            <Grid item xs={10}>
                                                <TextField
                                                    name="DYRS_ReturnedHitsCount"
                                                    label="# of individuals Returned"
                                                    disabled={!report?.DYRS_NoCloseProximity}
                                                    value={report?.DYRS_ReturnedHitsCount ?? ""}
                                                    onChange={handleInputChange}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={report?.DYRS_ReturnedCloseProximity ?? false}
                                                        onChange={handleCheckboxChange}
                                                        name="DYRS_ReturnedCloseProximity"
                                                        disabled={(report?.DYRS_ZeroReturns || report?.DYRS_NoCloseProximity) ?? false}
                                                    />
                                                }
                                                label={
                                                    <Box display="flex" alignItems="center">
                                                        <Typography variant="body1" component="span" mr={1}>
                                                            The query of the Juvenile Veritracks System returned
                                                            individuals monitored under
                                                            DYRS supervision. There were hits that returned within 200
                                                            feet from the
                                                            incident
                                                            location. The remaining hits returned exceeded 200 feet from
                                                            the incident
                                                            location.
                                                        </Typography>
                                                    </Box>
                                                }
                                            />
                                        </Grid>
                                        {report?.DYRS_ReturnedCloseProximity && (
                                            <Grid item xs={10}>
                                                <TextField
                                                    name="DYRS_ReturnedHitsCount"
                                                    label="# of Individuals Returned"
                                                    disabled={!report?.DYRS_ReturnedCloseProximity}
                                                    value={report?.DYRS_ReturnedHitsCount ?? ""}
                                                    onChange={handleInputChange}
                                                    variant="outlined"
                                                    size="small"
                                                    style={{marginRight: '2%'}}
                                                />
                                                <TextField
                                                    name="DYRS_ReturnedCloseHits"
                                                    label="# of Hits Within 200 Feet"
                                                    disabled={!report?.DYRS_ReturnedCloseProximity}
                                                    value={report?.DYRS_ReturnedCloseHits ?? ""}
                                                    onChange={handleInputChange}
                                                    variant="outlined"
                                                    size="small"
                                                    style={{marginRight: '2%'}}
                                                />
                                            </Grid>
                                        )}

                                        {/*Form opens up when third checkbox is selected*/}
                                        {report?.DYRS_ReturnedCloseProximity && (
                                            <>
                                                <Box display="flex" flexDirection="column" alignItems="center"
                                                     width="100%"
                                                     padding="0 16px">
                                                    {report?.DYRSVeritracks_List?.filter((f) => !f.deleted)?.map((row, index) => (
                                                        <div>
                                                            <Box display="flex" justifyContent="center" width="100%" mt={2}>
                                                                <Typography variant="h4" mt={2}>Hit {index + 1}</Typography>
                                                                    <IconButton
                                                                    aria-label="delete"
                                                                    onClick={() => deleteTableRow("DYRSVeritracks_List", row)}
                                                                    >
                                                                        <DeleteIcon sx={{color: "red"}}/>
                                                                    </IconButton>
                                                            </Box>
                                                        <Box key={index} display="flex" flexDirection="row"
                                                             alignItems="flex-start"
                                                             textAlign="center" mt={2} mb={2}>
                                                            <Grid container spacing={2} xs={9}>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="First Name"
                                                                        name="DYRS_FirstName"
                                                                        value={row?.DYRS_FirstName ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="Last Name"
                                                                        name="DYRS_LastName"
                                                                        value={row?.DYRS_LastName ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="AKA(s)"
                                                                        name="DYRS_Aliases"
                                                                        value={row?.DYRS_Aliases ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <DatePicker
                                                                        label="DOB"
                                                                        value={row?.DYRS_IndividualDOB ?? ""}
                                                                        onChange={(date) => handleInputChange({target: {name:"DYRS_IndividualDOB", value:(date ?? "")}},"DYRSVeritracks_List", row)}
                                                                        renderInput={(params) => <TextField {...params}
                                                                                                            fullWidth/>}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="PDID"
                                                                        name="DYRS_PDID"
                                                                        value={row?.DYRS_PDID ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="Juv. X-ref"
                                                                        name="DYRS_JuvXRef"
                                                                        value={row?.DYRS_JuvXRef ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="FBI"
                                                                        name="DYRS_FBI"
                                                                        value={row?.DYRS_FBI ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Autocomplete
                                                                        id="free-solo-demo"
                                                                        autoComplete={true}
                                                                        size="small"
                                                                        sx={{
                                                                            width: '100%',
                                                                            height: 56,
                                                                            textAlign: "center",
                                                                            justifyContent: "center"
                                                                        }}
                                                                        options={heightDD.map((option) => option.key)}
                                                                        value={row?.DYRS_Height ?? ""}
                                                                        onChange={(event, newValue) => handleInputChange({target: {name: "DYRS_Height", value: newValue as string}}, "DYRSVeritracks_List", row)}
                                                                        renderInput={(params) => (
                                                                            <TextField
                                                                                {...params}
                                                                                label="Height"
                                                                                variant="outlined"
                                                                                fullWidth
                                                                                InputProps={{
                                                                                    ...params.InputProps,
                                                                                    style: {
                                                                                        height: 56
                                                                                    }
                                                                                }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="Weight"
                                                                        name="DYRS_Weight"
                                                                        value={row?.DYRS_Weight ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="SSN"
                                                                        name="DYRS_SSN"
                                                                        value={row?.DYRS_SSN ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="Last Known Address(es)"
                                                                        name="DYRS_LastKnownAddress"
                                                                        value={row?.DYRS_LastKnownAddress ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                {/*<Grid item xs={12}>*/}
                                                                {/*    <TextField*/}
                                                                {/*        label="Phone Number(s)"*/}
                                                                {/*        name="DYRS_PhoneNumber"*/}
                                                                {/*        value={row?.DYRS_PhoneNumber ?? ""}*/}
                                                                {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                {/*        fullWidth*/}
                                                                {/*    />*/}
                                                                {/*</Grid>*/}
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="Gang/Crew"
                                                                        name="DYRS_GangCrew"
                                                                        value={row?.DYRS_GangCrew ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="Homicide Reduction Partnership (HRP) Area POI"
                                                                        name="DYRS_HRPAreaPOI"
                                                                        value={row?.DYRS_HRPAreaPOI ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="GunStat POI"
                                                                        name="DYRS_GunStatPOI"
                                                                        value={row?.DYRS_GunStatPOI ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                {/*<Grid item xs={12}>*/}
                                                                {/*    <TextField*/}
                                                                {/*        label="Target PSA/Area"*/}
                                                                {/*        name="DYRS_TargetPSA"*/}
                                                                {/*        value={row?.DYRS_TargetPSA ?? ""}*/}
                                                                {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                {/*        fullWidth*/}
                                                                {/*    />*/}
                                                                {/*</Grid>*/}
                                                                {/*<Grid item xs={12}>*/}
                                                                {/*    <TextField*/}
                                                                {/*        label="Driver's License #"*/}
                                                                {/*        name="DYRS_LicenseNo"*/}
                                                                {/*        value={row?.DYRS_LicenseNo ?? ""}*/}
                                                                {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                {/*        fullWidth*/}
                                                                {/*    />*/}
                                                                {/*</Grid>*/}
                                                                {/*<Grid item xs={12}>*/}
                                                                {/*    <Autocomplete*/}
                                                                {/*        id="free-solo-demo"*/}
                                                                {/*        autoComplete={true}*/}
                                                                {/*        size="small"*/}
                                                                {/*        sx={{*/}
                                                                {/*            width: '100%',*/}
                                                                {/*            height: 56,*/}
                                                                {/*            textAlign: "center",*/}
                                                                {/*            justifyContent: "center"*/}
                                                                {/*        }}*/}
                                                                {/*        options={stateList.map((option: any) => option.Key)}*/}
                                                                {/*        value={row?.DYRS_LicenseState ?? ""}*/}
                                                                {/*        onChange={(event, newValue) => handleInputChange({target: {name: "DYRS_LicenseState" ,value: newValue as string}}, "DYRSVeritracks_List", row )}*/}
                                                                {/*        renderInput={(params) => (*/}
                                                                {/*            <TextField*/}
                                                                {/*                {...params}*/}
                                                                {/*                label="DL State"*/}
                                                                {/*                variant="outlined"*/}
                                                                {/*                fullWidth*/}
                                                                {/*                InputProps={{*/}
                                                                {/*                    ...params.InputProps,*/}
                                                                {/*                    style: {*/}
                                                                {/*                        height: 56*/}
                                                                {/*                    }*/}
                                                                {/*                }}*/}
                                                                {/*            />*/}
                                                                {/*        )}*/}
                                                                {/*    />*/}
                                                                {/*</Grid>*/}
                                                                {/*<Grid item xs={12}>*/}
                                                                {/*    <TextField*/}
                                                                {/*        label="Vehicles"*/}
                                                                {/*        name="DYRS_Vehicles"*/}
                                                                {/*        value={row?.DYRS_Vehicles ?? ""}*/}
                                                                {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                {/*        fullWidth*/}
                                                                {/*    />*/}
                                                                {/*</Grid>*/}
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="DC Criminal History"
                                                                        name="DYRS_CriminalHistoryDC"
                                                                        value={row?.DYRS_CriminalHistoryDC ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="DC Juvenile Criminal History"
                                                                        name="DYRS_JuvenileCriminalHistoryDC"
                                                                        value={row?.DYRS_JuvenileCriminalHistoryDC ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="Cases and Charges"
                                                                        name="DYRS_CasesAndCharges"
                                                                        value={row?.DYRS_CasesAndCharges ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                {/*<Grid item xs={12}>*/}
                                                                {/*    <TextField*/}
                                                                {/*        label="DOC Information"*/}
                                                                {/*        name="DYRS_DOCInformation"*/}
                                                                {/*        value={row?.DYRS_DOCInformation ?? ""}*/}
                                                                {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                {/*        fullWidth*/}
                                                                {/*    />*/}
                                                                {/*</Grid>*/}
                                                                {/*<Grid item xs={12}>*/}
                                                                {/*    <TextField*/}
                                                                {/*        label="BOP Information"*/}
                                                                {/*        name="DYRS_BOPInformation"*/}
                                                                {/*        value={row?.DYRS_BOPInformation ?? ""}*/}
                                                                {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                {/*        fullWidth*/}
                                                                {/*    />*/}
                                                                {/*</Grid>*/}
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="CSOSA/PSA/DYRS Supervision Information"
                                                                        name="DYRS_Supervision_CSOSA_PSA_DYRS_Information"
                                                                        value={row?.DYRS_Supervision_CSOSA_PSA_DYRS_Information ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="Offender Court Events"
                                                                        name="DYRS_OffenderCourtEvents"
                                                                        value={row?.DYRS_OffenderCourtEvents ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="Active Warrants"
                                                                        name="DYRS_ActiveWarrants"
                                                                        value={row?.DYRS_ActiveWarrants ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="Warrant History"
                                                                        name="DYRS_WarrantHistory"
                                                                        value={row?.DYRS_WarrantHistory ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                {/*<Grid item xs={12}>*/}
                                                                {/*    <TextField*/}
                                                                {/*        label="MD Criminal History"*/}
                                                                {/*        name="DYRS_MDCriminalHistory"*/}
                                                                {/*        value={row?.DYRS_MDCriminalHistory ?? ""}*/}
                                                                {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                {/*        fullWidth*/}
                                                                {/*    />*/}
                                                                {/*</Grid>*/}
                                                                {/*<Grid item xs={12}>*/}
                                                                {/*    <TextField*/}
                                                                {/*        label="Additional History"*/}
                                                                {/*        name="DYRS_AdditionalHistory"*/}
                                                                {/*        value={row?.DYRS_AdditionalHistory ?? ""}*/}
                                                                {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                {/*        fullWidth*/}
                                                                {/*    />*/}
                                                                {/*</Grid>*/}
                                                                {/*<Grid item xs={12}>*/}
                                                                {/*    <TextField*/}
                                                                {/*        label="Possible Associates"*/}
                                                                {/*        name="DYRS_PossibleAssociates"*/}
                                                                {/*        value={row?.DYRS_PossibleAssociates ?? ""}*/}
                                                                {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                {/*        fullWidth*/}
                                                                {/*    />*/}
                                                                {/*</Grid>*/}
                                                                {/*<Grid item xs={12}>*/}
                                                                {/*    <TextField*/}
                                                                {/*        label="Possible Social Media"*/}
                                                                {/*        name="DYRS_PossibleSocialMedia"*/}
                                                                {/*        value={row?.DYRS_PossibleSocialMedia ?? ""}*/}
                                                                {/*        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}*/}
                                                                {/*        fullWidth*/}
                                                                {/*    />*/}
                                                                {/*</Grid>*/}
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        label="Veritracks Summary"
                                                                        name="DYRS_VeritracksSummary"
                                                                        value={row?.DYRS_VeritracksSummary ?? ""}
                                                                        onChange={(e) => handleInputChange(e, "DYRSVeritracks_List", row)}
                                                                        fullWidth
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Button
                                                                        variant="contained"
                                                                        component="label"
                                                                        fullWidth
                                                                    >
                                                                        Upload Veritracks Screenshot
                                                                        <input
                                                                            type="file"
                                                                            hidden
                                                                            onChange={(e) => handleDYRSFileChange(e, row, "Veritracks Screenshot")}
                                                                        />
                                                                    </Button>
                                                                    {row?.DYRS_VeritracksScreenshot && !row?.DYRS_VeritracksScreenshot?.deleted && (
                                                                        <Box mt={2} width={150} height={150} position="relative">
                                                                            <IconButton
                                                                                aria-label="delete"
                                                                                onClick={() => handleRemoveDYRSFile(row, "Veritracks Screenshot")}
                                                                                style={{
                                                                                    position: 'absolute',
                                                                                    top: 0,
                                                                                    right: 0,
                                                                                    zIndex: 1,
                                                                                    color: 'red'
                                                                                }}
                                                                            >
                                                                                <CloseIcon />
                                                                            </IconButton>
                                                                            <img
                                                                                src={row?.DYRS_VeritracksScreenshot?.Content ? "data:image/png;base64," + row?.DYRS_VeritracksScreenshot?.Content : ""}
                                                                                alt={`Person ${index + 1}`}
                                                                                style={{
                                                                                    width: '100%',
                                                                                    height: '100%',
                                                                                    objectFit: 'cover',
                                                                                    border: '1px solid #ddd',
                                                                                    padding: '5px'
                                                                                }}
                                                                            />
                                                                        </Box>
                                                                    )}
                                                                </Grid>
                                                            </Grid>
                                                            <Box display="flex" flexDirection="column"
                                                                 alignItems="center"
                                                                 justifyContent="center" ml={2}>
                                                                <DatePicker
                                                                    label="Image Date"
                                                                    value={row?.DYRS_ImageDate ?? ""}
                                                                    onChange={(date:any) => handleInputChange({target: {name:"DYRS_ImageDate", value:(date ?? "")}}, "DYRSVeritracks_List", row)}
                                                                    renderInput={(params) => <TextField {...params} fullWidth/>}
                                                                />
                                                                <Button
                                                                    variant="contained"
                                                                    component="label"
                                                                    fullWidth
                                                                >
                                                                    Upload Face Photo
                                                                    <input
                                                                        type="file"
                                                                        hidden
                                                                        onChange={(e) => handleDYRSFileChange(e, row, "Individual Photo")}
                                                                    />
                                                                </Button>

                                                                

                                                                {row?.DYRS_IndividualPhoto && !row?.DYRS_IndividualPhoto?.deleted && (
                                                                    <Box mt={2} width={150} height={150} position="relative">
                                                                        <IconButton
                                                                            aria-label="delete"
                                                                            onClick={() => handleRemoveDYRSFile(row, "Individual Photo")}
                                                                            style={{
                                                                                position: 'absolute',
                                                                                top: 0,
                                                                                right: 0,
                                                                                zIndex: 1,
                                                                                color: 'red'
                                                                            }}
                                                                        >
                                                                            <CloseIcon />
                                                                        </IconButton>
                                                                        <img
                                                                            src={row?.DYRS_IndividualPhoto?.Content ? "data:image/png;base64," + row?.DYRS_IndividualPhoto?.Content : ""}
                                                                            alt={`Person ${index + 1}`}
                                                                            style={{
                                                                                width: '100%',
                                                                                height: '100%',
                                                                                objectFit: 'cover',
                                                                                border: '1px solid #ddd',
                                                                                padding: '5px'
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        </div>
                                                    ))}
                                                </Box>

                                                <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                                    <ButtonGroup variant="contained" aria-label="Table buttons" size="small"
                                                        color="inherit">
                                                        <Button
                                                            color="inherit"
                                                            onClick={() => addTableRow("DYRSVeritracks_List", "GUID", GetGUID())}
                                                            startIcon={<AddIcon/>}
                                                            variant="contained"
                                                        >
                                                            Add Another DYRS Veritracks Hit
                                                        </Button>
                                                        <Button
                                                            color="inherit"
                                                            onClick={() => handleDialog("DYRS", true)}
                                                            startIcon={<ClearIcon/>}
                                                            variant="contained"
                                                        >
                                                            Clear All Hits
                                                        </Button>
                                                            <Dialog
                                                                open={dyrsClearOpen}
                                                                onClose={() => handleDialog("DYRS", false)}
                                                                aria-labelledby="clear-dialog"
                                                                aria-describedby="clear-dyrs"
                                                            >
                                                                <DialogTitle id="clear-dialog-title">
                                                                    {"Clear all existing DYRS hits?"}
                                                                </DialogTitle>
                                                                <DialogContent>
                                                                    <DialogContentText id="clear-dyrs">
                                                                        Are you sure you would like to clear all DYRS background hits from the list?
                                                                    </DialogContentText>
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button onClick={() => handleDialog("DYRS", false)}>No</Button>
                                                                    <Button onClick={() => { clearTableRows("DYRSVeritracks_List"); handleDialog("DYRS", false) }} autoFocus>
                                                                        Yes
                                                                    </Button>
                                                                </DialogActions>
                                                            </Dialog>
                                                    </ButtonGroup>
                                                </Box>
                                            </>
                                        )}

                                        <Typography className="italic pl-6">
                                            <br/>
                                            Please note, DYRS Veritracks is experiencing technical difficulties. The
                                            incident was queried
                                            and results were reviewed to the best of the writer's ability.
                                            Please contact the RTCC at a later date if you need the incident re-ran or
                                            you need an analyst to
                                            look at a specific track.
                                        </Typography>
                                    </Grid>
                                </FormControl>
                            </Grid>
                        </div>

                        <div className="container mt-4 ml-4 mr-5">
                            {/* Shotspotter Header */}
                            <Box className="header" textAlign="center" position={"relative"}
                                 width="100%" style={(report?.ShotSpotter_Disabled ? {backgroundColor:'gray'} : {})}>
                                <span style={{position:'absolute', left:'0.5rem', bottom:'0.1rem, top:0.5rem'}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={report?.ShotSpotter_Disabled ?? false}
                                                onChange={handleCheckboxChange}
                                                color={"default"}
                                                name="ShotSpotter_Disabled"
                                                checkedIcon={<VisibilityOffIcon />}
                                                disabled={report?.ShotSpotter_List && report?.ShotSpotter_List?.filter(f => !f.deleted)?.length > 1}
                                            />
                                        }
                                        label="NO SHOTSPOTTER NEEDED"
                                    />
                                </span>
                                <Typography variant="h5">
                                    SHOTSPOTTER
                                </Typography>
                            </Box>

                            {/* Shotspotter Details */}
                            {report?.ShotSpotter_List?.filter(s => !s?.deleted)?.map((row, index) => (
                                <div key={index} className={index > 0 ? "mt-4" : "mt-2"}>
                                    <Grid container spacing={1} columns={16} className="pl-2 pr-2" >
                                        <Grid item xs={15}>
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Shotspotter alerted
                                                </FormLabel>
                                                <RadioGroup row className="mt-2" aria-label="shotspotterAlerted"
                                                            name="ShotSpotter_Alerted"
                                                            value={row?.ShotSpotter_Alerted ?? ""}
                                                            onChange={(e) => handleInputChange(e, "ShotSpotter_List", row)}>
                                                    <FormControlLabel value="Yes" control={<Radio/>} label="Yes" disabled={report?.ShotSpotter_Disabled ?? false}/>
                                                    <FormControlLabel value="No" control={<Radio/>} label="No" disabled={report?.ShotSpotter_Disabled ?? false}/>
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Box display="flex" justifyContent="center" width="100%" mt={2} position="relative">
                                                {index !== 0 && (
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => deleteTableRow("ShotSpotter_List", row)}
                                                        color="error"
                                                        style={{
                                                            position: 'absolute',
                                                            top: 0,
                                                            right: 0,
                                                            zIndex: 1,
                                                            color: 'red'
                                                        }}
                                                    >
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                )}
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    {row?.ShotSpotter_Alerted === 'Yes' && (
                                        <div>
                                            <Table className="pl-2 pr-2">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Shotspotter Findings</TableCell>
                                                        <TableCell>Shotspotter Date Time</TableCell>
                                                        <TableCell width={'40%'}>Shotspotter Location</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            <TextField
                                                                name="ShotSpotter_RoundCount"
                                                                label="# of Rounds"
                                                                disabled={!row?.ShotSpotter_Alerted}
                                                                value={row?.ShotSpotter_RoundCount ?? ""}
                                                                onChange={(e) => handleInputChange(e, "ShotSpotter_List", row)}
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <DateTimePicker
                                                                label="Shotspotter Date/Time"
                                                                ampm={false}
                                                                value={row?.ShotSpotter_DateTime ?? ""}
                                                                onChange={(date) => handleInputChange({target: {name:"ShotSpotter_DateTime", value:(date ?? "")}}, "ShotSpotter_List", row)}
                                                                renderInput={(params) => <TextField {...params} fullWidth/>}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                name="ShotSpotter_Location"
                                                                label="Shotspotter Location"
                                                                disabled={!row?.ShotSpotter_Alerted}
                                                                value={row?.ShotSpotter_Location ?? ""}
                                                                onChange={(e) => handleInputChange(e, "ShotSpotter_List", row)}
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
        
                                            <Grid item xs={12} className="pl-2 pr-2">
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    sx={{
                                                        marginTop: "2%",
                                                        marginBottom: "2%"
                                                    }}
                                                >
                                                    Upload Screenshot of Shotspotter
                                                    <input
                                                        type="file"
                                                        accept="image/jpeg, image/png, image/gif"
                                                        hidden
                                                        onChange={(e) => handleShotSpotterFileChange(e, row)}
                                                    />
                                                </Button>
                                                {/* Display Uploaded Image */}
                                                <Grid item xs={12}>
                                                    <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
                                                        {row?.Files?.filter((f:any) => !f?.deleted)?.map((file, index) => (
                                                            <Box key={index} position="relative" display="inline-block"
                                                                 marginBottom="10px">
                                                                <IconButton
                                                                    aria-label="delete"
                                                                    onClick={() => handleRemoveFile(file, "ShotSpotter_List", row)}
                                                                    style={{
                                                                        position: 'absolute',
                                                                        top: 0,
                                                                        right: 0,
                                                                        zIndex: 1,
                                                                        color: 'red'
                                                                    }}
                                                                >
                                                                    <CloseIcon/>
                                                                </IconButton>
                                                                <img
                                                                    src={file?.Content && file?.Content?.length > 0 ? "data:image/png;base64," + file?.Content : ""}
                                                                    alt={`Screenshot ${index + 1}`}
                                                                    style={{
                                                                        width: '75%',
                                                                        height: '75%',
                                                                        objectFit: 'cover',
                                                                        border: '1px solid #ddd',
                                                                        padding: '5px'
                                                                    }}
                                                                />
                                                            </Box>
                                                        ))}
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    )}
                                    
                                </div>
                            ))}
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                    <ButtonGroup variant="contained" aria-label="Table buttons" size="small"
                                                 color="inherit"
                                                 className="mb-4">
                                        <Button
                                            variant="contained"
                                            startIcon={<AddIcon/>}
                                            disabled={report?.ShotSpotter_Disabled ?? false}
                                            onClick={() => addTableRow("ShotSpotter_List", "GUID", GetGUID())}
                                        >
                                            Add Shot Spotter
                                        </Button>
                                        <Button
                                            variant="contained"
                                            startIcon={<ClearIcon/>}
                                            disabled={report?.ShotSpotter_Disabled ?? false}
                                            onClick={() => handleDialog("Shot Spotter", true)}
                                        >
                                            Clear All Shot Spotters
                                        </Button>
                                        <Dialog
                                            open={shotSpotterClearOpen}
                                            onClose={() => handleDialog("Shot Spotter", false)}
                                            aria-labelledby="clear-dialog"
                                            aria-describedby="clear-cameras"
                                        >
                                            <DialogTitle id="clear-dialog-title">
                                                {"Clear all existing Shot Spotters?"}
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="clear-cameras">
                                                    Are you sure you would like to clear all shot spotters in the list?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() => handleDialog("Shot Spotter", false)}>No</Button>
                                                <Button onClick={() => {clearTableRows("ShotSpotter_List", "GUID", GetGUID());  handleDialog("Shot Spotter", false)} } autoFocus>
                                                    Yes
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </ButtonGroup>
                                </Box>
                            </Grid>
                        </div>

                        <div className="container mt-4 ml-4 mr-5">
                            <Box className="header" display="flex" flexDirection="column" alignItems="center"
                                 textAlign="center"
                                 width="100%">
                                <Typography variant="h5">
                                    PRIVATE SECURITY CAMERA INCENTIVE PROGRAM
                                </Typography>
                            </Box>

                            <Grid item xs={12} className="pl-2 pr-2 mt-4">
                                <Box>
                                    <Typography variant="body1">
                                        Listed below are residential and business cameras registered with
                                        the Government of the District of Columbia's Private Security Camera Incentive
                                        Program.
                                        The RTCC does not have access to these cameras. The detective must contact the
                                        resident or business
                                        owner directly.
                                        <br/>
                                        <br/>
                                        Private Security Camera System Incentive Program cameras within 1,250 feet of
                                        the incident location:
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} className="pl-2 pr-2 mt-2">
                                <Typography variant="body1" component="span">
                                    Please upload a screenshot of Private Cameras within 1,250 feet of the incident
                                    location.
                                    <br/>
                                    (Take off gang/crew layer, schools, CCTV/Octo cameras, etc. Only leave the PSA and
                                    private camera layer)
                                    <br/>
                                </Typography>
                                <Button
                                    variant="contained"
                                    component="label"

                                    sx={{
                                        marginTop: "2%",
                                        marginBottom: "2%"
                                    }}
                                >
                                    Upload Screenshot of Private Camera Locations
                                    <input
                                        type="file"
                                        accept="image/jpeg, image/png, image/gif"
                                        hidden
                                        multiple
                                        onChange={(e) => handleFileChange(e, "Private Camera Locations")}
                                    />
                                </Button>
                            </Grid>

                            {/* Display Uploaded Image */}
                            {(report?.Files && report?.Files?.filter(f => f?.Origination === "Private Camera Locations" && !f?.deleted)?.length > 0) && (
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}
                                         position="relative">
                                        {report?.Files?.filter(f => f?.Origination === "Private Camera Locations" && !f?.deleted)?.map((file, index) => (
                                            <Box key={index} position="relative" display="inline-block"
                                                 marginBottom="10px">
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => handleRemoveFile(file)}
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 0,
                                                        zIndex: 1,
                                                        color: 'red'
                                                    }}
                                                >
                                                    <CloseIcon/>
                                                </IconButton>
                                                <img
                                                    src={file?.Content && file?.Content?.length > 0 ? "data:image/png;base64," + file?.Content : ""}
                                                    alt={`Screenshot ${index + 1}`}
                                                    style={{
                                                        width: '75%',
                                                        height: '75%',
                                                        objectFit: 'cover',
                                                        border: '1px solid #ddd',
                                                        padding: '5px'
                                                    }}
                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                </Grid>
                            )}

                            <Grid item xs={12} className="pl-2 pr-2 mt-2">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Camera #</TableCell>
                                            <TableCell>Distance</TableCell>
                                            <TableCell>Address</TableCell>
                                            <TableCell>Contact First Name</TableCell>
                                            <TableCell>Contact Last Name</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {report?.CameraList?.filter(f => f?.Camera_Type === "Private Camera Locations" && !f?.deleted)?.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell>
                                                    <TextField
                                                        name="CCTV_Distance"
                                                        value={row?.CCTV_Distance ?? ""}
                                                        onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        name="CCTV_Location"
                                                        value={row?.CCTV_Location ?? ""}
                                                        onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        name="ContactFirstName"
                                                        value={row?.ContactFirstName ?? ""}
                                                        onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        name="ContactLastName"
                                                        value={row?.ContactLastName ?? ""}
                                                        onChange={(e) => handleInputChange(e, "CameraList", row)}
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title="Delete Camera">
                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={() => deleteTableRow("CameraList", row)}
                                                            >
                                                                <DeleteIcon sx={{color: "red"}}/>
                                                            </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>

                            {/* Table Buttons */}
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                    <ButtonGroup variant="contained" aria-label="Table buttons" size="small"
                                                 color="inherit"
                                                 className="mb-4">
                                        <Button
                                            variant="contained"
                                            startIcon={<AddIcon/>}
                                            onClick={() => addTableRow("CameraList", "Camera_Type", "Private Camera Locations")}
                                        >
                                            Add Camera
                                        </Button>
                                        <Button
                                            variant="contained"
                                            startIcon={<ClearIcon/>}
                                            onClick={() => handleDialog("Private Camera", true)}
                                        >
                                            Clear All Cameras
                                        </Button>
                                        <Dialog
                                            open={privateClearOpen}
                                            onClose={() => handleDialog("Private Camera", false)}
                                            aria-labelledby="clear-dialog"
                                            aria-describedby="clear-cameras"
                                        >
                                            <DialogTitle id="clear-dialog-title">
                                                {"Clear all existing cameras?"}
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="clear-cameras">
                                                    Are you sure you would like to clear all cameras in the list?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() => handleDialog("Private Camera", false)}>No</Button>
                                                <Button onClick={() => {clearTableRows("CameraList", "Camera_Type", "Private Camera Locations");  handleDialog("Private Camera", false)} } autoFocus>
                                                    Yes
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </ButtonGroup>
                                </Box>
                            </Grid>
                            <Grid item xs={12} className="pl-2 pr-2">
                                <Typography variant="h6" gutterBottom>
                                    Camera Observations
                                </Typography>
                                <TextField
                                    label="Positive Camera Observations"
                                    name="Private_Observations"
                                    value={report?.Private_Observations ?? ""}
                                    onChange={handleInputChange}
                                    multiline
                                    rows={10}
                                    fullWidth

                                    style={{ marginTop: '10px', marginBottom: '5px' }}
                                />
                            </Grid>
                        </div>

                        <div className="container mt-4 ml-4 mr-5">
                            <Box className="header" display="flex" flexDirection="column" alignItems="center"
                                 textAlign="center"
                                 width="100%">
                                <Typography variant="h5">
                                    GANG/CREW INFORMATION
                                </Typography>
                            </Box>

                            <Grid item xs={12} className="pl-2 pr-2">
                                <FormControl component="fieldset">
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={report?.Gang_NonGangArea ?? false}
                                                        disabled={(report?.Gang_GangArea || report?.Gang_GangAreaBlock) ?? false}
                                                        onChange={handleCheckboxChange}
                                                        name="Gang_NonGangArea"
                                                    />
                                                }
                                                label="This incident did not occur in a known Gang/Crew area."
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={report?.Gang_GangArea ?? false}
                                                        onChange={handleCheckboxChange}
                                                        disabled={(report?.Gang_NonGangArea || report?.Gang_GangAreaBlock) ?? false}
                                                        name="Gang_GangArea"
                                                    />
                                                }
                                                label={
                                                    <Box display="flex" alignItems="center">
                                                        <Typography variant="body1" component="span" mr={1}>
                                                            This incident occurred in a Gang/Crew area. If you need any
                                                            additional
                                                            information, please contact the Intelligence Branch.
                                                        </Typography>
                                                    </Box>
                                                }
                                            />
                                        </Grid>
                                        {report?.Gang_GangArea && (
                                            <Grid item xs={10}>
                                                <TextField
                                                    name="Gang_Area"
                                                    label="Gang/Crew Area"
                                                    disabled={!report?.Gang_GangArea}
                                                    value={report?.Gang_Area ?? ""}
                                                    onChange={handleInputChange}
                                                    variant="outlined"
                                                    size="small"
                                                />
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={report?.Gang_GangAreaBlock ?? false}
                                                        onChange={handleCheckboxChange}
                                                        disabled={(report?.Gang_NonGangArea || report?.Gang_GangArea) ?? false}
                                                        name="Gang_GangAreaBlock"
                                                    />
                                                }
                                                label={
                                                    <Box display="flex" alignItems="center">
                                                        <Typography variant="body1" component="span" mr={1}>
                                                            This incident occurred within a block of a Gang/Crew area.
                                                            If you need any
                                                            additional information, please contact the Intelligence
                                                            Branch.
                                                        </Typography>
                                                    </Box>
                                                }
                                            />
                                        </Grid>
                                    </Grid>

                                    {report?.Gang_GangAreaBlock && (
                                        <Grid item xs={10}>
                                            <TextField
                                                name="Gang_Area"
                                                label="Gang/Crew Area"
                                                disabled={!report?.Gang_GangAreaBlock}
                                                value={report?.Gang_Area ?? ""}
                                                onChange={handleInputChange}
                                                variant="outlined"
                                                size="small"
                                                style={{marginBottom: '2%'}}
                                            />
                                        </Grid>
                                    )}
                                </FormControl>
                            </Grid>
                        </div>

                        <div className="container mt-4 ml-4 mr-5">
                            <Box className="header" display="flex" flexDirection="column" alignItems="center"
                                 textAlign="center"
                                 width="100%">
                                <Typography variant="h5">
                                    OPERATION THRIVE
                                </Typography>
                            </Box>

                            <Grid item xs={12} className="pl-2 pr-2">
                                <FormControl component="fieldset">
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={report?.OperationThrive_NonThriveArea ?? false}
                                                        disabled={report?.OperationThrive_ThriveArea ?? false}
                                                        onChange={handleCheckboxChange}
                                                        name="OperationThrive_NonThriveArea"
                                                    />
                                                }
                                                label="This incident did not occur in an Operation THRIVE Area."
                                            />
                                        </Grid>
                                        <Grid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={report?.OperationThrive_ThriveArea ?? false}
                                                        onChange={handleCheckboxChange}
                                                        disabled={report?.OperationThrive_NonThriveArea ?? false}
                                                        name="OperationThrive_ThriveArea"
                                                    />
                                                }
                                                label={
                                                    <Box display="flex" alignItems="center">
                                                        <Typography variant="body1" component="span" mr={1}>
                                                            This incident occurred in an Operation THRIVE area.
                                                        </Typography>
                                                    </Box>
                                                }
                                            />
                                        </Grid>
                                        {report?.OperationThrive_ThriveArea && (
                                            <Grid item xs={10}>
                                                <TextField
                                                    name="OperationThrive_Area"
                                                    label="Operation THRIVE Area"
                                                    disabled={!report?.OperationThrive_ThriveArea}
                                                    value={report?.OperationThrive_Area ?? ""}
                                                    onChange={handleInputChange}
                                                    variant="outlined"
                                                    size="small"
                                                    style={{marginBottom: '8%'}}
                                                />
                                            </Grid>
                                        )}
                                    </Grid>
                                </FormControl>
                            </Grid>
                        </div>

                        <div className="container mt-4 ml-4 mr-5">
                            <Box className="header" display="flex" flexDirection="column" alignItems="center"
                                 textAlign="center"
                                 width="100%">
                                <Typography variant="h5">
                                    INCIDENT ADDRESS INFORMATION
                                </Typography>
                            </Box>

                            <Grid item xs={12} className="pl-2 pr-2">
                                <Box>
                                    <Typography variant="body1">
                                        Listed below are the ADWs, Carjackings, Homicides, Robberies, and Burglaries
                                        that have occurred in
                                        the last 30 days
                                        within 1,250 feet of the incident location. The data refreshes between 0130 and
                                        0230 each day.
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} className="pl-2 pr-2">
                                <Typography variant="body1" component="span">
                                    Please upload a screenshot of crimes that have occurred within 1250 feet of the
                                    incident location over
                                    the past 30 days.
                                    <br/>
                                    (Take off gang/crew layer, cameras, schools, etc.)
                                    <br/>
                                </Typography>
                                <Button
                                    variant="contained"
                                    component="label"

                                    sx={{
                                        marginTop: "2%",
                                        marginBottom: '2%'
                                    }}
                                >
                                    Upload Screenshot of Crime Locations
                                    <input
                                        type="file"
                                        accept="image/jpeg, image/png, image/gif"
                                        hidden
                                        multiple
                                        onChange={(e) => handleFileChange(e, "Crime Locations")}
                                    />
                                </Button>
                            </Grid>

                            {/* Display Uploaded Image */}
                            {(report?.Files && report?.Files?.filter(f => f?.Origination === "Crime Locations" && !f?.deleted)?.length > 0) && (
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                        {report?.Files?.filter(f => f?.Origination === "Crime Locations" && !f?.deleted)?.map((file, index) => (
                                            <Box key={index} position="relative" display="inline-block"
                                                 marginBottom="10px">
                                                <IconButton
                                                    aria-label="delete"
                                                    onClick={() => handleRemoveFile(file)}
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 0,
                                                        zIndex: 1,
                                                        color: 'red'
                                                    }}
                                                >
                                                    <CloseIcon/>
                                                </IconButton>
                                                <img
                                                    src={file?.Content && file?.Content?.length > 0 ? "data:image/png;base64," + file?.Content : ""}
                                                    alt={`Screenshot ${index + 1}`}
                                                    style={{
                                                        width: '75%',
                                                        height: '75%',
                                                        objectFit: 'cover',
                                                        border: '1px solid #ddd',
                                                        padding: '5px'
                                                    }}
                                                />
                                            </Box>
                                        ))}
                                    </Box>
                                </Grid>
                            )}

                            <Grid item xs={12} className="pl-2 pr-2">
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Crime #</TableCell>
                                            <TableCell>CCN</TableCell>
                                            <TableCell>Day of Week</TableCell>
                                            <TableCell>Event Date/Time</TableCell>
                                            <TableCell>Offense</TableCell>
                                            <TableCell>Weapon</TableCell>
                                            <TableCell>Address</TableCell>
                                            <TableCell>PSA</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {report?.IncidentList?.filter(f => !f?.deleted)?.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell style={{width: 150}}>
                                                    <TextField
                                                        name="Incident_CCN"
                                                        value={row?.Incident_CCN ?? ""}
                                                        multiline
                                                        onChange={(e) => handleInputChange(e, "IncidentList", row)}
                                                        inputProps={{
                                                            style: {
                                                                borderRadius: "1px",
                                                                textAlign: 'center'
                                                            }
                                                        }}
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell style={{width: 200}}>
                                                    <Autocomplete
                                                        options={weekdays}
                                                        getOptionLabel={(option) => option.label}
                                                        value={weekdays.find(day => day.value === row?.Incident_DayOfWeek) ?? null}
                                                        onChange={(event, newValue) => handleInputChange({ target: { name: "Incident_DayOfWeek", value: newValue?.value ?? "" } }, "IncidentList", row)}
                                                        renderInput={(params) => <TextField {...params} label="" variant="outlined" fullWidth />}
                                                    />
                                                </TableCell>
                                                <TableCell style={{width: 260}}>
                                                    <DateTimePicker
                                                        value={row?.Incident_EventDateTime ?? ""}
                                                        ampm={false}
                                                        onChange={(date) => handleInputChange({target: {name:"Incident_EventDateTime", value:(date ?? "")}}, "IncidentList", row)}
                                                        renderInput={(params) => <TextField {...params} fullWidth/>}
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        name="Incident_Offense"
                                                        value={row?.Incident_Offense ?? ""}
                                                        multiline
                                                        onChange={(e) => handleInputChange(e, "IncidentList", row)}
                                                        inputProps={{
                                                            style: {
                                                                borderRadius: "1px",
                                                                textAlign: 'center'
                                                            }
                                                        }}
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        name="Incident_Weapon"
                                                        value={row?.Incident_Weapon ?? ""}
                                                        multiline
                                                        onChange={(e) => handleInputChange(e, "IncidentList", row)}
                                                        inputProps={{
                                                            style: {
                                                                borderRadius: "1px",
                                                                textAlign: 'center'
                                                            }
                                                        }}
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        multiline
                                                        name="Incident_Address"
                                                        value={row?.Incident_Address ?? ""}
                                                        onChange={(e) => handleInputChange(e, "IncidentList", row)}
                                                        inputProps={{
                                                            style: {
                                                                borderRadius: "1px",
                                                                textAlign: 'center',
                                                                wordBreak: 'break-word',
                                                                whiteSpace: 'pre-wrap',
                                                                overflowWrap: 'break-word',
                                                            }
                                                        }}
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <TextField
                                                        name="Incident_PSA"
                                                        value={row?.Incident_PSA ?? ""}
                                                        multiline
                                                        onChange={(e) => handleInputChange(e, "IncidentList", row)}
                                                        inputProps={{
                                                            style: {
                                                                borderRadius: "1px",
                                                                textAlign: 'center'
                                                            }
                                                        }}
                                                        fullWidth
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    {index !== 0 && (
                                                        <Tooltip title="Delete Crime">
                                                            <IconButton
                                                                aria-label="delete"
                                                                onClick={() => deleteTableRow("IncidentList", row)}
                                                                disabled={index === 0}
                                                            >
                                                                <DeleteIcon sx={{color: "red"}}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>

                            {/* Table Buttons */}
                            <Grid item xs={12}>
                                <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                    <ButtonGroup variant="contained" className="mb-4" aria-label="Table buttons"
                                                 size="small"
                                                 color="inherit">
                                        <Button
                                            variant="contained"
                                            startIcon={<AddIcon/>}
                                            onClick={() => addTableRow("IncidentList")}
                                        >
                                            Add Crime
                                        </Button>
                                        <Button
                                            variant="contained"
                                            startIcon={<ClearIcon/>}
                                            onClick={() => handleDialog("Incident", true)}
                                        >
                                            Clear All Crimes
                                        </Button>
                                        <Dialog
                                            open={clearOpen}
                                            onClose={() => handleDialog("Incident", false)}
                                            aria-labelledby="clear-dialog"
                                            aria-describedby="clear-crimes"
                                        >
                                            <DialogTitle id="clear-dialog-title">
                                                {"Clear all existing crimes?"}
                                            </DialogTitle>
                                            <DialogContent>
                                                <DialogContentText id="clear-crimes">
                                                    Are you sure you would like to clear all crimes in the list?
                                                </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                                <Button onClick={() => handleDialog("Incident", false)}>No</Button>
                                                <Button onClick={() => {clearTableRows("IncidentList"); handleDialog("Incident", false)}} autoFocus>
                                                    Yes
                                                </Button>
                                            </DialogActions>
                                        </Dialog>
                                    </ButtonGroup>
                                </Box>
                            </Grid>
                        </div>

                        <div className="container mt-4 ml-4 mr-5">
                            <Box className="header" textAlign="center" position={"relative"}
                                 width="100%" style={(report?.LPR_Disabled ? {backgroundColor:'gray'} : {})}>
                                <span style={{position:'absolute', left:'0.5rem', bottom:'0.1rem, top:0.5rem'}}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={report?.LPR_Disabled ?? false}
                                                onChange={handleCheckboxChange}
                                                color={"default"}
                                                name="LPR_Disabled"
                                                checkedIcon={<VisibilityOffIcon />}
                                                disabled={report?.LprList && report?.LprList?.filter(f => !f.deleted)?.length > 1}
                                            />
                                        }
                                        label="NO LPR/RELATED VEHICLES"
                                    />
                                </span>
                                <Typography variant="h5">
                                    LPR/RELATED VEHICLES
                                </Typography>
                            </Box>
                            <Grid item xs={12} className="pl-2 pr-2">
                                {report?.LprList?.filter(f => !f.deleted)?.map((row, index) => (
                                    <div key={index} style={{width: '100%'}}>
                                        <Grid container spacing={2}>
                                            <Box display="flex" justifyContent="center" width="100%" mt={2}>
                                                <Typography variant="h4" mt={2}>Vehicle {index + 1}</Typography>
                                                {index !== 0 && (
                                                    <IconButton
                                                        aria-label="delete"
                                                        onClick={() => deleteTableRow("LprList", row)}
                                                        disabled={index === 0}
                                                    >
                                                        <DeleteIcon sx={{color: "red"}}/>
                                                    </IconButton>
                                                )}
                                            </Box>
                                            <Grid item xs={12} className="pl-2 pr-2">
                                                <Button
                                                    variant="contained"
                                                    component="label"
                                                    disabled={report?.LPR_Disabled ?? false }
                                                >
                                                    Upload LPR/Vehicle Screenshot(s)
                                                    <input
                                                        type="file"
                                                        accept="image/png, image/jpeg, image/gif"
                                                        hidden
                                                        multiple
                                                        onChange={(e) => handleLPRFileChange(e, row)}
                                                    />
                                                </Button>
                                            </Grid>

                                            {row?.Files?.filter(f => f?.Origination === "LPR Screenshots" && !f?.deleted)?.map((file, index) => (
                                                <Grid item xs={12}>
                                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2} position="relative">
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => handleRemoveFile(file, "LprList", row)}
                                                            style={{
                                                                position: 'absolute',
                                                                top: 0,
                                                                right: 0,
                                                                zIndex: 1,
                                                                color: 'red'
                                                            }}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                        <img
                                                            src={file?.Content && file?.Content?.length > 0 ? "data:image/png;base64," + file?.Content : ""}
                                                            alt="Uploaded Screenshot"
                                                            style={{
                                                                width: '75%',
                                                                height: '75%',
                                                                objectFit: 'cover',
                                                                border: '1px solid #ddd',
                                                                padding: '5px'
                                                            }}
                                                        />
                                                    </Box>
                                                </Grid>
                                            ))}
                                            
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label={`Vehicle ${index + 1} Description`}
                                                    name={"LPR_Veh_Description"}
                                                    disabled={report?.LPR_Disabled ?? false}
                                                    value={row?.LPR_Veh_Description ?? ""}
                                                    onChange={(e) => handleInputChange(e, "LprList", row)}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    label="Relationship"
                                                    name={"LPR_Veh_Relationship"}
                                                    disabled={report?.LPR_Disabled ?? false}
                                                    value={row?.LPR_Veh_Relationship ?? ""}
                                                    onChange={(e) => handleInputChange(e, "LprList", row)}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Was LPR queried?</FormLabel>
                                                    <RadioGroup row aria-label="lprHit"
                                                                name={"LPR_Queried"}
                                                                value={row?.LPR_Queried ?? ""}
                                                                onChange={(e) => handleInputChange(e, "LprList", row)}
                                                        >
                                                        <FormControlLabel value="Yes" control={<Radio />} disabled={report?.LPR_Disabled ?? false} label="Yes"/>
                                                        <FormControlLabel value="No" control={<Radio />} disabled={report?.LPR_Disabled ?? false} label="No"/>
                                                    </RadioGroup>
                                                    {row?.LPR_Queried === 'Yes' && (
                                                        <div key={index}>
                                                            <Grid container spacing={2} marginTop='1%'>
                                                                <Grid item xs={12}>
                                                                    <FormControlLabel
                                                                        key={index}
                                                                        control={
                                                                            <Checkbox
                                                                                checked={row?.LPR_CustomQuery ?? false}
                                                                                onChange={handleCheckboxChange}
                                                                                name={`LPR_CustomQuery_${index}`}
                                                                            />
                                                                        }
                                                                        label="Click Here to Type a Custom Summary"
                                                                    />
                                                                </Grid>
                                                                {!row.LPR_CustomQuery && (
                                                                    <div className="ml-4">
                                                                        <Grid item xs={12}>
                                                                            <DateTimePicker
                                                                                label="Date/Time Query Was Made"
                                                                                ampm={false}
                                                                                value={row?.LPR_QueryDateTime ?? ""}
                                                                                onChange={(date) => handleInputChange({target: {name:"LPR_QueryDateTime", value:(date ?? "")}}, "LprList", row)}
                                                                                renderInput={(params) => <TextField {...params}
                                                                                style={{marginBottom: '10px'}}
                                                                                fullWidth/>}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} className="mb-2">
                                                                            <Box display="flex" alignItems="center">
                                                                                <DateTimePicker
                                                                                    label="Query Start Date/Time"
                                                                                    ampm={false}
                                                                                    value={row?.LPR_SearchStartDate ?? startDate}
                                                                                    onChange={(date) => handleInputChange({target: {name:"LPR_SearchStartDate", value:(date ?? "")}}, "LprList", row)}
                                                                                    renderInput={(params) =>
                                                                                        <TextField {...params} style={{ marginBottom: '10px' }} />}
                                                                                    
                                                                                />
                                                                                <Box sx={{mx: 1}}> to </Box>
                                                                                <DateTimePicker
                                                                                    label="Query End Date/Time"
                                                                                    ampm={false}
                                                                                    value={row?.LPR_SearchEndDate ?? currentDate}
                                                                                    onChange={(date) => handleInputChange({target: {name:"LPR_SearchEndDate", value:(date ?? "")}}, "LprList", row)}
                                                                                    renderInput={(params) =>
                                                                                        <TextField {...params} style={{ marginBottom: '10px' }} />}
                                                                                />
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs={12} className="mb-2">
                                                                            <Box display="flex" alignItems="center">
                                                                                <TextField
                                                                                    label="# of Hits"
                                                                                    name={"LPR_CountOfHits"}
                                                                                    value={row?.LPR_CountOfHits ?? ""}
                                                                                    onChange={(e) => handleInputChange(e, "LprList", row)}
                                                                                    style={{ marginBottom: '10px' }}
                                                                                    fullWidth
                                                                                />
                                                                                <DateTimePicker
                                                                                    label="Date/Time of Last Hit"
                                                                                    ampm={false}
                                                                                    value={row?.LPR_LastHitDateTime ?? ""}
                                                                                    onChange={(date) => handleInputChange({ target: { name: "LPR_LastHitDateTime", value: (date ?? "") } }, "LprList", row)}
                                                                                    renderInput={(params) => <TextField {...params} style={{ marginBottom: '10px', marginLeft: '10px' }}
                                                                                        fullWidth />}
                                                                                />
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item xs={12} className="mb-2">
                                                                            <TextField
                                                                                label="Camera Location"
                                                                                name={"LPR_Camera_Location"}
                                                                                value={row?.LPR_Camera_Location ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "LprList", row)}
                                                                                fullWidth
                                                                                style={{ marginBottom: '10px' }}
                                                                            />
                                                                        </Grid>
                                                                        <Grid item xs={12} className="mb-2">
                                                                            <Box display="flex" alignItems="center">
                                                                                <TextField
                                                                                    label="Owner's First Name"
                                                                                    name={"LPR_Veh_OwnerFirstName"}
                                                                                    value={row?.LPR_Veh_OwnerFirstName ?? ""}
                                                                                    onChange={(e) => handleInputChange(e, "LprList", row)}
                                                                                    fullWidth
                                                                                    style={{ marginBottom: '10px' }}
                                                                                />
                                                                                <TextField
                                                                                    label="Owner's Last Name"
                                                                                    name={"LPR_Veh_OwnerLastName"}
                                                                                    value={row?.LPR_Veh_OwnerLastName ?? ""}
                                                                                    onChange={(e) => handleInputChange(e, "LprList", row)}
                                                                                    fullWidth
                                                                                    style={{ marginBottom: '10px', marginLeft: '10px' }}
                                                                                />
                                                                                <DatePicker
                                                                                    label="Owner DOB"
                                                                                    value={row?.LPR_Veh_OwnerDOB ?? ""}
                                                                                    onChange={(date) => handleInputChange({ target: { name: "LPR_Veh_OwnerDOB", value: (date ?? "") } }, "LprList", row)}
                                                                                    renderInput={(params) => <TextField {...params} style={{ marginBottom: '10px', marginLeft: '10px' }}
                                                                                        fullWidth />}
                                                                                    />
                                                                            </Box>
                                                                        </Grid>
                                                                            
                                                                        <Grid item xs={12} className="mb-2">
                                                                            <TextField
                                                                                label="Owner's Address"
                                                                                name={"LPR_Veh_OwnerAddress"}
                                                                                value={row?.LPR_Veh_OwnerAddress ?? ""}
                                                                                onChange={(e) => handleInputChange(e, "LprList", row)}
                                                                                style={{ marginBottom: '10px' }}
                                                                                fullWidth
                                                                            />
                                                                        </Grid>
                                                                    </div>
                                                                )}
                                                                
                                                                
                                                                    
                                                            </Grid>
                                                        </div>
                                                    )}
                                                    
                                                </FormControl>
                                            </Grid>
                                            {row?.LPR_CustomQuery && (
                                                <TextField
                                                    label="LPR Summary"
                                                    name={"LPR_CustomSummary"}
                                                    value={row?.LPR_CustomSummary ?? ""}
                                                    onChange={(e) => handleInputChange(e, "LprList", row)}
                                                    fullWidth
                                                    multiline
                                                    rows={10}
                                                    style={{marginLeft: '15px'} }
                                                />
                                            )}
                                        </Grid>
                                    </div>
                                ))}
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2}>
                                        <ButtonGroup className="mb-4">
                                            <Button
                                                variant="contained"
                                                startIcon={<AddIcon/>}
                                                disabled={report?.LPR_Disabled ?? false}
                                                onClick={() => addTableRow("LprList", "GUID", GetGUID())}
                                                color="inherit"
                                            >
                                                Add Another Vehicle
                                            </Button>
                                            <Button
                                                variant="contained"
                                                startIcon={<ClearIcon/>}
                                                disabled={report?.LPR_Disabled ?? false}
                                                onClick={() => handleDialog("LPR", true)}
                                                color="inherit"
                                            >
                                                Clear All Vehicles
                                            </Button>
                                            <Dialog
                                                open={vehicleClearOpen}
                                                onClose={() => handleDialog("LPR", false)}
                                                aria-labelledby="clear-dialog"
                                                aria-describedby="clear-cameras"
                                            >
                                                <DialogTitle id="clear-dialog-title">
                                                    {"Clear all vehicles?"}
                                                </DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText id="clear-cameras">
                                                        Are you sure you would like to clear all vehicles in the list?
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={() => handleDialog("LPR", false)}>No</Button>
                                                    <Button onClick={() => {clearTableRows("LprList"); handleDialog("LPR", false)}} autoFocus>
                                                        Yes
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </ButtonGroup>
                                    </Box>
                                </Grid>
                            </Grid>
                        </div>

                        <div className="container mt-4 ml-4 mr-5">
                            <Box className="header" display="flex" flexDirection="column" alignItems="center"
                                 textAlign="center"
                                 width="100%">
                                <Typography variant="h5">
                                    CAD EVENT INFORMATION
                                </Typography>
                            </Box>

                            <Grid item xs={12} className="pl-2 pr-2">
                                <Button
                                    variant="contained"
                                    component="label"

                                    sx={{
                                        marginTop: "2%",
                                        marginBottom: '2%'
                                    }}
                                >
                                    Upload CAD Screenshot
                                    <input
                                        type="file"
                                        accept="image/png, image/jpeg, image/gif"
                                        hidden
                                        multiple
                                        onChange={(e) => handleFileChange(e, "CAD")}
                                    />
                                </Button>
                            </Grid>

                            {report?.Files?.filter(f => f?.Origination === "CAD" && !f?.deleted)?.map((file, index) => (
                                <Grid item xs={12}>
                                    <Box display="flex" justifyContent="center" alignItems="center" mt={2} position="relative">
                                        <IconButton
                                            aria-label="delete"
                                            onClick={() => handleRemoveFile(file)}
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                right: 0,
                                                zIndex: 1,
                                                color: 'red'
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                        <img
                                            src={file?.Content && file?.Content?.length > 0 ? "data:image/png;base64," + file?.Content : ""}
                                            alt="Uploaded Screenshot"
                                            style={{
                                                width: '75%',
                                                height: '75%',
                                                objectFit: 'cover',
                                                border: '1px solid #ddd',
                                                padding: '5px'
                                            }}
                                        />
                                    </Box>
                                </Grid>
                            ))}

                            <Grid item xs={12} className="pl-2 pr-2">
                                <div style={{width: '100%', marginBottom:'1vh'}}>
                                    <FormControl fullWidth>
                                        <InputLabel shrink htmlFor="CAD_EventDetails">Event Details</InputLabel>
                                        <TextareaAutosize
                                            id="CAD_EventDetails"
                                            name="CAD_EventDetails"
                                            value={report?.CAD_EventDetails ?? ""}
                                            onChange={handleInputChange}
                                                style={{
                                                    width: '100%', marginTop: '10px',
                                                    border: '1px solid rgba(0, 0, 0, 0.23)',
                                                    borderRadius: '4px',
                                                    padding: '16.5px 14px',
                                                    fontSize: '16px',
                                                    lineHeight: '1.4375em',
                                                    backgroundColor: 'inherit',
                                                    resize: 'vertical'
                                        }}
                                            minRows={10}
                                        />
                                    </FormControl>
                                </div>
                            </Grid>
                        </div>

                        {/* Submit Button */}
                        <Grid item xs={12} className="pr-6 pb-4">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                style={{float: 'right'}}
                                onClick = {() => handleSubmit()}
                            >
                                Submit Report
                            </Button>
                        </Grid>
                    </Grid>
                </div> }
            </Container>
        </LocalizationProvider>
    );

}

export default PreliminaryReportInputForm;
