import * as React from "react";
import { GoogleMap, useJsApiLoader, Marker, HeatmapLayer, LoadScript, InfoWindow, } from '@react-google-maps/api';
import Geocode from "react-geocode";
import {Google_API_Key, SiteName} from "../../../services/config.service";
import Pin from "../../../interfaces/Pin.interface";
import {isPointInPolygon} from "../../../services/dashboard.service";
import p_green from '../../../assets/Images/p_green.png'
import p_red from '../../../assets/Images/p_red.png'
import v_red from '../../../assets/Images/v_red.png'
import v_green from '../../../assets/Images/v_green.png'
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";


interface HeatMapProps {
    data: any,
    county:string,
    city:string,
    tab:string,
}

const SVHeatMap: React.FC<HeatMapProps> = ({data,county, city, tab}) => {
    const [mapKey, setMapKey] = React.useState<number>(0)
    const [map, setMap] = React.useState<any>(null)
    const [heatMap, setHeatMap] = React.useState<any>(null)
    const [heatMapData, setHeatMapData] = React.useState<any[]>([])
    const [pinData, setPinData] = React.useState<any[]>([])
    const [center, setCenter] = React.useState<any>({lat: 40.0352, lng: -74.5844})
    const [radius, setRadius] = React.useState<any>(25);
    const [selectedCenter, setSelectedCenter] = React.useState<any>(null)
    Geocode.setApiKey(Google_API_Key);
    const [zoom, setZoom] = React.useState<number>(8)
    const [boundry, setBoundry] = React.useState<any>(null)
    const [centerLat, setCenterLat] = React.useState<number>((SiteName === SITE.DCDEMO || SiteName === SITE.DCPROD || SiteName === SITE.DCLOCAL) ? 38.9170 : 40.0352)
    const [centerLong, setCenterLong] = React.useState<number>((SiteName === SITE.DCDEMO || SiteName === SITE.DCPROD || SiteName === SITE.DCLOCAL) ? -77.0195 : -74.5844)    

    const getInitialBoundary = () => {
        if (SiteName === SITE.DCDEMO || SiteName === SITE.DCPROD || SiteName === SITE.DCLOCAL) {
            setBoundry(
                [
                    [38.995915112998006, -77.04117383965263],
                    [38.893239776660444, -76.90965127078951],
                    [38.79233551275002, -77.03920346408914],
                    [38.88327082429337, -77.06087759488469],
                    [38.93425112008646, -77.12146664346207]
                ]
            )
            setCenterLat(38.9170); //38.917006246162785, -77.01949970805134
            setCenterLong(-77.0195);
        }
        else {
            setBoundry(
                [
                    [41.362453762936575, -74.6918236783217],
                    [40.990338834229426, -73.91728763616442],
                    [40.6952910099279, -74.01067141429685],
                    [40.486723153439925, -74.26885009501595],
                    [40.49090088462017, -74.01067141429685],
                    [40.06763774018179, -73.8568628385493],
                    [39.48504098031486, -74.21391846082041],
                    [38.89324697993387, -74.93901603220169],
                    [39.47232109991295, -75.55425033519187],
                    [39.62903700481567, -75.55974349861143],
                    [39.852904834638665, -75.40593492286388],
                    [40.23558358525728, -74.79619378329326],
                    [40.557709209883015, -75.20818103975989],
                    [40.98204561057492, -75.15324940556434]
                ]
            )
            setCenterLat(40.0352);
            setCenterLong(-74.5844);
        }
    }
    
    React.useEffect(() => {
        getInitialBoundary();
        if(data.HeatMap && data.HeatMap.length > 0) {
            let temp = []
            setPinData(data.HeatMap)
            for (var x of data.HeatMap.filter((e: any) => getMapFilterData(e))) {
                if (typeof x.latitude !== 'number' || typeof x.longitude !== 'number') {
                    //console.log(typeof x.Latitude == 'number')
                }
                else if (isPointInPolygon(x.latitude, x.longitude, boundry)) {
                    //Do For Loop for Count
                    for (let i = 0; i < x.Count; i++) {
                        temp.push(new google.maps.LatLng(x.latitude, x.longitude))
                    }

                }
            }    
            setHeatMapData(temp)
            if(county === 'ALL COUNTIES' && city === 'ALL CITIES')
            {
                setCenter({ lat: centerLat, lng: centerLong })  
                setZoom(8)
            }
            else
            {
                setCenter({
                    lat: handleCenter().lat,
                    lng: handleCenter().lng
                })
                if(city === "ALL CITIES")
                {
                    setZoom(10)
                }
                else
                {
                    setZoom(13)
                }
            }
            
        }
        else
        {
            setPinData([])
            setHeatMapData([])
            setCenter({ lat: centerLat, lng: centerLong })
            setZoom(8)
        }
        if((SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL))
            setZoom(12)
        
    }, [data,tab]);

    const handleCenter = (): Pin => {
        var centerLat = 0
        var centerLng = 0
        var count = 0

        if (data.HeatMap && data.HeatMap.length > 0) {
            for (var x of data.HeatMap) {
                if (typeof x.Latitude !== 'number' || typeof x.Longitude !== 'number') {
                    //console.log(typeof x.Latitude == 'number')
                }
                else if (isPointInPolygon(x.Latitude, x.Longitude, boundry)) {
                    centerLat += parseFloat(x.Latitude)
                    centerLng += parseFloat(x.Longitude)
                    count++
                }

            }

            centerLat = centerLat / count
            centerLng = centerLng / count
        }

        return { lat: centerLat, lng: centerLng }

    }
    
    
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: Google_API_Key,
        libraries: ["visualization"],
    })

    const containerStyle = {
        width: '100%',
        height: '70vh',
    };

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
        setHeatMap(null)
    }, [])

    
    const onLoad = (mapInstance:any) => {
        setMap(mapInstance)
    }

    const onHeatMapLoad = (mapInstance:any) => {
        setHeatMap(mapInstance)
    }

    const getPinIcon = (pin:any):any  => { 
        let iconPin= {}
        if(pin.Type === 'STOLEN VEHICLE'){
            iconPin= {
                url:v_red,
                scaledSize: new google.maps.Size(20,21),
            };
        }
        else if(pin.Type === 'STOLEN PLATE'){
            iconPin= {
                url:p_red,
                scaledSize: new google.maps.Size(20,21),
            };
        }
        else if(pin.Type === 'RECOVERED VEHICLE'){
            iconPin= {
                url:v_green,
                scaledSize: new google.maps.Size(20,21),
            };
        }
        else if(pin.Type === 'RECOVERED PLATE'){
            iconPin= {
                url:p_green,
                scaledSize: new google.maps.Size(20,21),
            };
        }
        return iconPin
        
    }
    
    const getMapFilterData = (pin:any) => {
        if(tab === '1')
        {
            return (pin.Type === 'STOLEN VEHICLE')
        }
        else if(tab === '2')
        {
            return (pin.Type === 'RECOVERED VEHICLE')
        }
        else if(tab === '4')
        {
            return (pin.Type === 'STOLEN PLATE')
        }
        else if(tab === '5')
        {
            return (pin.Type === 'RECOVERED PLATE')
        }
        else {
            return true
        }
    }

    return (
        <div>
            {(isLoaded) ? (
                <>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={zoom}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        key={mapKey}
                    >
                        { /* Child components, such as markers, info windows, etc. */}
                        <>
                                {(data.HeatMap && data.HeatMap.length > 0 &&
                                    data.HeatMap.filter( (e:any) => getMapFilterData(e)).map((pin:any) =>
                                        <Marker
                                            position={{ lat: pin.latitude, lng: pin.longitude}}
                                            icon={getPinIcon(pin)}
                                            onClick={() => {
                                                setSelectedCenter(pin);
                                             }}
                                        >
                                            {selectedCenter && selectedCenter.latitude === pin.latitude && selectedCenter.longitude === pin.longitude &&
                                            <InfoWindow
                                                position={{ lat: pin.latitude, lng: pin.longitude}}
                                                onCloseClick={()=>{setSelectedCenter(null)}}
                                            >
                                                <div className="p-2">
                                                    <span className="font-bold">Type</span> {pin.Type}
                                                    <hr/>
                                                    <span className="font-bold">{GetStringNameForSite(SiteName, 'County')}:</span> {pin.County}
                                                    <br/>
                                                    <span className="font-bold">{GetStringNameForSite(SiteName, 'City')}:</span> {pin.City}
                                                    <br/>
                                                    <span className="font-bold">Plate Number:</span> {pin.PlateNo}
                                                    <br/>
                                                </div>
                                            </InfoWindow>
                                            }
                                        </Marker>
                                    )
                                )}
                              
                            <HeatmapLayer
                                onLoad={onHeatMapLoad}
                                options={{
                                    opacity:1,
                                    radius:radius,
                                    gradient :[
                                        "rgba(255,96,96,0)",
                                        "rgba(255,85,85,1)",
                                        "rgba(255,75,75,1)",
                                        "rgba(255,67,67,1)",
                                        "rgba(255,47,47,1)",
                                        "rgba(255,30,30,1)",
                                        "rgba(255,18,18,1)",
                                        "rgba(255,7,7,1)",
                                        "rgba(255,0,0,1)",
                                        "rgba(236,0,0,1)",
                                        "rgba(213,1,1,1)",
                                        "rgba(182,0,0,1)",
                                        "rgba(175,0,0,1)",
                                        "rgba(145,0,0,1)",
                                    ],
                                
                                }}
                                
                                // required
                                data={heatMapData}
                            />
                            
                        </>
                    </GoogleMap>
                </>
            ) : <></>}
        </div>
    );
}
export default SVHeatMap