import React, {useState} from "react";
import {DateTimePicker, LocalizationProvider} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {
    Box, Button, ButtonGroup, Checkbox,
    Container,
    FormControl,
    FormControlLabel,
    FormLabel, Grid,
    Grid as MUIGrid, Radio, RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import {SetPIRSupplement} from "../../../services/pir.service";
import {PIRReport, PIRSupplementReport} from "../../../interfaces/PIR.interface";

interface PreliminarySupplementReportProps {
    handleReportSubmit: any,
    Record: any,
    ParentReportId: any,
}

const PreliminarySupplementReportInputForm: React.FC<PreliminarySupplementReportProps> = ({ handleReportSubmit, Record = null, ParentReportId = null }) => {
    const [supplement, setSupplement] = useState<PIRSupplementReport>({Background_UpdatedBackground: false, ShotSpotter_UpdatedShotSpotter:false, Class_UpdatedClassification: false, ReportID: ParentReportId})
    

    React.useEffect(() => {
        if(Record){
            setSupplement(Record)
            
        }else{
            clearSuppForm();
        }
    }, [Record])

    const clearSuppForm = () => {
        setSupplement({Background_UpdatedBackground: false, ShotSpotter_UpdatedShotSpotter:false, Class_UpdatedClassification: false, ReportID: ParentReportId})

    }

    const handleInputChange = (e: { target: { name: string; value: any; }; }) => {
        const { name, value } = e.target;
   
        let temp:any= {...supplement}
        temp[name] = (value instanceof Date ? value.toLocaleString() : value)
        
        setSupplement(temp)
    };
    
    const handleCheckboxInputChange = (e: { target: { name: string; checked: any; }; }) => {
        const { name, checked } = e.target;

        let temp:any= {...supplement}
        temp[name] = checked

        setSupplement(temp)
    }
    
    const handleSuppSubmit = () => {
        SetPIRSupplement(supplement).then((r:any)=>{
            handleReportSubmit(r)
        }).catch((e:any)=>{
            console.log(e)
        })
        
        clearSuppForm();
    };
    
    const closeHandler = () => {
        handleReportSubmit(null)
    }

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Container>
                    <form className="border-double border-2 border-inherit px-4">
                        <div className="container mt-4">
                            {/* SUMMARY Header */}
                            <Box className="header" display="flex" justifyContent="center" width="100%">
                                <Typography variant="h5">
                                    SUMMARY
                                </Typography>
                            </Box>

                            <Grid container spacing={3} marginTop="2%" className="pl-2 pr-2 mb-6">
                                {/* Date and Time*/}
                                <Grid item xs={6}>
                                    <DateTimePicker
                                        label="Date and Time"
                                        ampm={false}
                                        value={supplement?.Summary_DateTime ?? ""}
                                        onChange={(date) => handleInputChange({
                                            target: {
                                                name: "Summary_DateTime",
                                                value: (date ?? "")
                                            }
                                        })}
                                        renderInput={(params) => <TextField {...params} fullWidth/>}
                                    />
                                </Grid>
                                {/* Incident Type */}
                                <Grid item xs={6}>
                                    <TextField
                                        label="Incident Type"
                                        name="Summary_Type"
                                        value={supplement?.Summary_Type ?? ""}
                                        onChange={handleInputChange}
                                        fullWidth

                                    />
                                </Grid>

                                {/* Location */}
                                <Grid item xs={5}>
                                    <TextField
                                        label="Location"
                                        name="Summary_Location"
                                        value={supplement?.Summary_Location ?? ""}
                                        onChange={handleInputChange}
                                        fullWidth

                                    />
                                </Grid>

                                {/* PSA */}
                                <Grid item xs={1}>
                                    <TextField
                                        label="PSA"
                                        name="Summary_PSA"
                                        value={supplement?.Summary_PSA ?? ""}
                                        onChange={handleInputChange}
                                        fullWidth

                                    />
                                </Grid>

                                {/* CCN */}
                                <Grid item xs={2}>
                                    <TextField
                                        label="CCN"
                                        name="Summary_CCN"
                                        value={supplement?.Summary_CCN ?? ""}
                                        onChange={handleInputChange}
                                        fullWidth

                                    />
                                </Grid>

                                {/* CRS First Name */}
                                <Grid item xs={2}>
                                    <TextField
                                        label="CRS First Name"
                                        name="Summary_SpecialistFirstName"
                                        value={supplement?.Summary_SpecialistFirstName ?? ""}
                                        onChange={handleInputChange}
                                        fullWidth

                                    ></TextField>
                                </Grid>

                                <Grid item xs={2}>
                                    <TextField
                                        label="CRS Last Name"
                                        name="Summary_SpecialistLastName"
                                        value={supplement?.Summary_SpecialistLastName ?? ""}
                                        onChange={handleInputChange}
                                        fullWidth

                                    ></TextField>
                                </Grid>
                            </Grid>

                            {/* Radio */}
                            <Grid item xs={12} className="pl-2 pr-2">
                                <Typography variant="body1" component="span">
                                    The RTCC initiated a Preliminary Investigative Report in reference to the above
                                    offense based on the CAD call and actively monitoring the
                                </Typography>
                                <TextField
                                    name="Summary_Radio"
                                    value={supplement?.Summary_Radio ?? ""}
                                    onChange={handleInputChange}
                                    rows={1}

                                    size="small"
                                    style={{width: '8%', margin: '0 10px', verticalAlign: 'middle'}}
                                    inputProps={{
                                        style: {
                                            borderRadius: "1px",
                                            height: "10px",
                                            textAlign: 'center'
                                        }
                                    }}
                                />
                                <Typography variant="body1" component="span">
                                    radio transmissions. Please advise if further investigation reveals additional or
                                    conflicting information necessary to update this initial report. Detective
                                </Typography>
                                <TextField
                                    name="Summary_Detective"
                                    value={supplement?.Summary_Detective ?? ""}
                                    onChange={handleInputChange}
                                    rows={1}

                                    size="small"
                                    style={{width: '15%', margin: '0 10px', verticalAlign: 'middle'}}
                                    inputProps={{
                                        style: {
                                            borderRadius: "1px",
                                            height: "10px",
                                            textAlign: 'center'
                                        }
                                    }}
                                />
                                <Typography variant="body1" component="span">
                                    of the
                                </Typography>
                                <TextField
                                    name="Summary_Detective_District"
                                    value={supplement?.Summary_Detective_District ?? ""}
                                    onChange={handleInputChange}
                                    rows={1}
                                    size="small"
                                    style={{width: '15%', margin: '0 10px', verticalAlign: 'middle'}}
                                    inputProps={{
                                        style: {
                                            borderRadius: "1px",
                                            height: "10px",
                                            textAlign: 'center'
                                        }
                                    }}
                                />
                                <Typography variant="body1" component="span">
                                    District requested a Preliminary Investigative Report in reference to the incident
                                    listed above.
                                    <br/>
                                    <br/>
                                    Add any positive results from CCTV/cameras, DYRS, ShotSpotter, or LPR. Do NOT
                                    include any other information (e.g., no more running resume).
                                </Typography>
                                <TextField
                                    label="Summary of Positive Results"
                                    name="Summary_Narrative"
                                    value={supplement?.Summary_Narrative ?? ""}
                                    onChange={handleInputChange}
                                    multiline
                                    rows={10}
                                    fullWidth

                                    style={{marginTop: '10px'}}
                                />
                                <Typography variant="body1" component="span">
                                    For any questions or additional requests for information, please contact the Real
                                    Time Crime Center at 202-727-0559 or <a className="a" href="mailto:mpdcrs@dc.gov">mpdcrs@dc.gov</a>
                                </Typography>
                            </Grid>
                        </div>

                        <div className={"container mt-4"}>
                            <Box className="header" display="flex" justifyContent="center" width="100%">
                                <Typography variant="h5">
                                    SUPPLEMENTAL INFORMATION
                                </Typography>
                            </Box>
                            <MUIGrid container spacing={3} marginTop="2%" className="pl-2 pr-2 mb-6">
                                <MUIGrid item xs={12}>
                                    <Box className="p-0 w-full" display="flex" width="100%">
                                        <Typography variant="body1">Please choose what you are updating: </Typography>
                                    </Box>
                                </MUIGrid>
                                <MUIGrid item xs={12}>
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Update Options</FormLabel>
                                        <MUIGrid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox 
                                                        name={"Class_UpdatedClassification"}
                                                        checked={supplement?.Class_UpdatedClassification ?? false}
                                                        onChange={handleCheckboxInputChange}
                                                    />}
                                                label="Classification"
                                            />
                                        </MUIGrid>
                                        <MUIGrid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name={"ShotSpotter_UpdatedShotSpotter"}
                                                        checked={supplement?.ShotSpotter_UpdatedShotSpotter ?? false}
                                                        onChange={handleCheckboxInputChange}
                                                    />}
                                                label="ShotSpotter"
                                            />
                                        </MUIGrid>
                                        <MUIGrid item>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name={"Background_UpdatedBackground"}
                                                        checked={supplement?.Background_UpdatedBackground ?? false}
                                                        onChange={handleCheckboxInputChange}
                                                    />}
                                                label="Background"
                                            />
                                        </MUIGrid>
                                    </FormControl>
                                </MUIGrid>
                                <MUIGrid item xs={12}>
                                    {supplement?.Class_UpdatedClassification && (
                                        <div>
                                            <MUIGrid item xs={12}>
                                                <Box className="font-bold mt-2 p-0 w-full" display="flex"
                                                     justifyContent="center" width="100%">
                                                    <Typography variant="h6">Classification</Typography>
                                                </Box>
                                            </MUIGrid>
                                            <MUIGrid container spacing={2}>
                                                <MUIGrid item xs={6}>
                                                    <TextField
                                                        label="New Classification"
                                                        name="Class_NewClassification"
                                                        value={supplement?.Class_NewClassification ?? ""}
                                                        onChange={handleInputChange}
                                                        fullWidth
                                                        margin={"normal"}
                                                    />
                                                </MUIGrid>
                                                <MUIGrid item xs={6}>
                                                    <TextField
                                                        label="CCN"
                                                        name="Class_NewCCN"
                                                        value={supplement?.Class_NewCCN ?? ""}
                                                        onChange={handleInputChange}
                                                        fullWidth
                                                        margin={"normal"}
                                                    />
                                                </MUIGrid>
                                            </MUIGrid>
                                        </div>
                                    )}
                                    {supplement?.ShotSpotter_UpdatedShotSpotter && (
                                        <div>
                                            <MUIGrid item xs={12}>
                                                <Box className="font-bold mt-2 mb-2 p-0 w-full" display="flex"
                                                     justifyContent="center" width="100%">
                                                    <Typography variant="h6">ShotSpotter Response</Typography>
                                                </Box>
                                            </MUIGrid>
                                            <MUIGrid item xs={6}>
                                                <DateTimePicker
                                                    label="Response Date/Time"
                                                    ampm={false}
                                                    value={supplement?.ShotSpotter_DateTime ?? ""}
                                                    onChange={(date) => handleInputChange({
                                                        target: {
                                                            name: "ShotSpotter_DateTime",
                                                            value: (date ?? "")
                                                        }
                                                    })}
                                                    renderInput={(params) => <TextField {...params} fullWidth/>}
                                                />
                                            </MUIGrid>
                                            <MUIGrid item xs={12}>
                                                <TextField
                                                    label="ShotSpotter Details"
                                                    name="ShotSpotter_ResponseDetails"
                                                    value={supplement?.ShotSpotter_ResponseDetails ?? ""}
                                                    onChange={handleInputChange}
                                                    fullWidth
                                                    multiline
                                                    rows={6}
                                                    margin={"normal"}
                                                />
                                            </MUIGrid>
                                        </div>
                                    )}
                                    {supplement?.Background_UpdatedBackground && (
                                        <div>
                                            <MUIGrid item xs={12}>
                                                <Box className="font-bold mt-2 mb-2 p-0 w-full" display="flex"
                                                     justifyContent="center" width="100%">
                                                    <Typography variant="h6">Background</Typography>
                                                </Box>
                                            </MUIGrid>
                                            <FormControl>
                                                <FormLabel>Relationship to Case</FormLabel>
                                                <RadioGroup
                                                    row
                                                    name="Background_CaseRelationship"
                                                    value={supplement?.Background_CaseRelationship ?? ""}
                                                    onChange={handleInputChange}
                                                >
                                                    <FormControlLabel value="Complainant" control={<Radio/>}
                                                                      label="Complainant"/>
                                                    <FormControlLabel value="Defendant" control={<Radio/>}
                                                                      label="Defendant"/>
                                                    <FormControlLabel value="Person of Interest" control={<Radio/>}
                                                                      label="Person of Interest"/>
                                                </RadioGroup>
                                            </FormControl>
                                            {(supplement?.Background_CaseRelationship !== "") && (
                                                <div>
                                                    <MUIGrid container spacing={2}>
                                                        <MUIGrid item xs={6}>
                                                            <TextField
                                                                label="First Name"
                                                                name="Background_FName"
                                                                value={supplement?.Background_FName ?? ""}
                                                                onChange={handleInputChange}
                                                                fullWidth
                                                                margin="normal"
                                                            />
                                                        </MUIGrid>
                                                        <MUIGrid item xs={6}>
                                                            <TextField
                                                                label="Last Name"
                                                                name="Background_LName"
                                                                value={supplement?.Background_LName ?? ""}
                                                                onChange={handleInputChange}
                                                                fullWidth
                                                                margin="normal"
                                                            />
                                                        </MUIGrid>
                                                    </MUIGrid>
                                                    <MUIGrid container spacing={2}>
                                                        <MUIGrid item xs={6}>
                                                            <TextField fullWidth margin='normal' type="date"
                                                                       label="Date of Birth"
                                                                       InputLabelProps={{shrink: true}}
                                                                       name="Background_DOB"
                                                                       value={supplement?.Background_DOB ?? ""}
                                                                       onChange={handleInputChange}
                                                            />
                                                        </MUIGrid>
                                                        <MUIGrid item xs={6}>
                                                            <TextField
                                                                label="PDID"
                                                                name="Background_PDID"
                                                                value={supplement?.Background_PDID ?? ""}
                                                                onChange={handleInputChange}
                                                                fullWidth
                                                                margin="normal"
                                                            />
                                                        </MUIGrid>
                                                    </MUIGrid>
                                                    <MUIGrid item xs={12}>
                                                        <TextField
                                                            label="Background Details"
                                                            name="Background_Information"
                                                            value={supplement?.Background_Information ?? ""}
                                                            onChange={handleInputChange}
                                                            fullWidth
                                                            margin="normal"
                                                            multiline
                                                            rows={30}
                                                            
                                                        />
                                                    </MUIGrid>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </MUIGrid>
                            </MUIGrid>
                        </div>

                        <div className ="my-4" style={{display:'flex', justifyContent:"flex-end", gap:'1%' }}>
                            <Button onClick={closeHandler} color="error" variant="contained" className={"mr-2"}>
                                Cancel
                            </Button>
                            <Button onClick={handleSuppSubmit} color="primary" variant="contained">
                                Submit Supplement
                            </Button>
                        </div>
                        
                    </form>
                </Container>
            </LocalizationProvider>


            
        </>
    );
}


export default PreliminarySupplementReportInputForm;