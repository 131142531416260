import React from "react";
import {
    ColumnDirective,
    ColumnsDirective,
    ColumnMenu,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, ExcelExportProperties,
    Resize, Reorder, SortSettingsModel
} from '@syncfusion/ej2-react-grids';

interface SearchResultsProps {
    NJTraceATF: any[],
}

const NJTraceATFDisplay: React.FC<SearchResultsProps> = ({ NJTraceATF }) => {
    const [typedCase,setTypedCases] = React.useState<any>({})
    let grid: Grid | null;

    const handleDataTyping = () => {
        if (NJTraceATF) {
            let tempP: any = [...NJTraceATF]

            for (let p of tempP) {
                if(p.ATF_Notes){
                    let errString=""
                    try{
                        let tempE = JSON.parse(p.ATF_Notes)
                        if(tempE.errors){
                            for(let err of tempE.errors){
                                errString += 'Error: '+ err.description +"\n"
                            }
                        }
                        else if(tempE.warnings){
                            for(let war of tempE.warnings){
                                errString += 'Warning: ' + war.description +"\n"
                            }
                        }       
                      
                    }
                    catch(e){
                        console.log(e)
                        errString = p.ATF_Notes
                    }
                    p.ATF_Notes = errString

                }
            }
            setTypedCases(tempP)
        }

    }

    React.useEffect(() => {
        handleDataTyping()
    }, [NJTraceATF])

    let sortSettings: SortSettingsModel = {
        columns: [
            { field: 'ATF_trace_SentDate', direction: 'Descending' },
        ]
    };


    return(
        <div className={"pl-10 pr-10 mt-5 border-2 border-gray-100 pb-5 pt-5"}>
            <span style={{display:"inline-block", width:"full"}}>
                NJTrace ATF Information
                {(NJTraceATF && NJTraceATF.length > 0) ? <h4 className={"font-bold pl-5"}> {NJTraceATF.length} Records Found</h4> : <h4 className={"font-bold pl-5"}>No Records Found</h4>}
            </span>
            {NJTraceATF && NJTraceATF?.length > 0 ? <>    
            <GridComponent
                dataSource={typedCase}
                allowPaging={true}
                pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                allowSorting={true}
                sortSettings={sortSettings}
                allowMultiSorting={true}
                allowFiltering={true}
                filterSettings={{ type: 'CheckBox' }}
                allowTextWrap={true}
                textWrapSettings={{ wrapMode: 'Both' }}
                allowExcelExport={true}
                allowPdfExport={true}
                toolbar={['Search']}
                //toolbarClick={GridToolsClick}
                ref={g => grid = g}
                selectionSettings={{
                    persistSelection: true,
                    type: "Multiple",
                    //checkboxOnly: true,
                    mode: "Row"
                }}            
                allowSelection={true}
                allowResizing={true}
                rowHeight={24}
            >
                <ColumnsDirective>
                    <ColumnDirective field='ATF_Trace_ID_Single' headerText='ATF Trace ID' width={110} minWidth={110} maxWidth={110} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='ATF_trace_SentDate'  maxWidth={120} headerText='ATF Sent Date' format={{ type: 'date', format: 'MM/dd/yyyy HH:mm' }} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='ATF_Status' headerText='ATF Status' customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='ATF_Notes' headerText='ATF Received Notes'  customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='ATF_trace_Received' headerText='ATF Response'  customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='ATF_trace_ReceivedNotes' headerText='ATF Response Notes'  customAttributes={{ class: ['e-attr'] }} />
                </ColumnsDirective>
                <Inject services={[ColumnMenu, Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder]} />
            </GridComponent>
            </>
            : <></>}
            
        </div>
    
    
    );



}

export default NJTraceATFDisplay
