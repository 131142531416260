import React, {useState} from 'react'
import {
    Alert,
    Box,
    Button,
    ButtonGroup,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle,
    IconButton,
    List,
    Snackbar,
    Tab,
    Tooltip,
    Typography
} from "@mui/material";
import VehicleIcon from "../../../assets/Images/Vehicle.png";
import PersonIcon from "../../../assets/Images/Person.png";
import DeviceIcon from "../../../assets/Images/DeviceSmall.png";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search,
    SortSettingsModel, ExcelExportProperties
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import GeoCode from "../../shared/GeoCodes/_GeoCode_Display";
import Checkbox from "@mui/material/Checkbox";
import {
    ALPRMultipleOccurenceQuery,
    GetDarInfo_Query,
    getDarInfoResponse
} from "../../../interfaces/getDarInfo_interface";
import {Account} from "../../../interfaces/auth_interface";
import {getDeviceUser, getUser, isUserPermission} from "../../../services/auth.service";
import {
    IWWGetRecord,
    IWWSaveRecord,
    IWWSendEmail,
    RunDarQuery,
    RunMultipleOccurrence
} from "../../../services/getDar.service";
import MapIcon from "@mui/icons-material/Map";
import {JAOS} from "../../../services/JAOS.service";
import {GetDeviceToken} from "../../../services/detail.service";
import AuthenticatedAPIReqSplashDL from "../../../services/AuthenticatedAPIReqSplashDeviceLocation.service";
import {formatDT} from "../../../services/formatDate.service";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {NJTraceMainObject} from "../../../interfaces/Detail/NJTraceDetail.interface";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {PostAdd} from "@mui/icons-material";
import IWWNotificationsInputForm from "../shooting/_iwwNotificationsInputForm";
import { IWWGunShootingMain, IWWGunShootingObject, IWWNotificationsObject } from '../../../interfaces/ShootingInfo.interface';
import Geocode from "react-geocode";
import {Google_API_Key, SiteName} from "../../../services/config.service";
import {ACTION} from "@syncfusion/ej2-react-richtexteditor";
import VisibilityIcon from '@mui/icons-material/Visibility';
import EmailIcon from '@mui/icons-material/Email';
import UpdateUser from "../../Admin/UpdateUser";
import CloseIcon from "@mui/icons-material/Close";
import {send} from "react-ga";
import { Container, border } from '@mui/system';
import PreviewIcon from '@mui/icons-material/Preview';
import { ContentState, convertFromHTML } from 'draft-js';
import {EditorState} from 'draft-js'
import htmlToDraft from 'html-to-draftjs';
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";





interface SearchResultsProps {
    ShootingInfo: any[]
    SubmitSearch: any,
    SelectionClear: any
}

const SearchResults: React.FC<SearchResultsProps> = ({ShootingInfo, SubmitSearch, SelectionClear})=> {
    const [typedPersons, setTypedPersons] = React.useState<any[]>([])
    const [showGeoCode, setShowGeoCode] = React.useState(false)
    const [allPins, setAllPins] = React.useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    const [alprList, setALPRList] = useState<any>([])
    const [mapVersion, setMapVersion] = useState<string>("2")
    const [exportData, setExportData] = useState<any>({})
    const [tabValue, setTabValue] = React.useState('1');
    const [editTabName, setEditTabName] = React.useState('New Shooting Notification');
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [emailDialog, setEmailDialog] = React.useState<boolean>(false)
    const [emailOnSaveDialog, setEmailOnSaveDialog] = React.useState<boolean>(false)
    //const [vhtml, setVhtml] = React.useState<string>("<div><b><li>UPDATE #1: test space <li style= 'margin-left: 20px;'> NUMBER OF VICTIM(S): 2 </li></li></br></b><li>UPDATE #2: test planet <li style= 'margin-left: 20px;'> NUMBER OF VICTIM(S): 4 </li></li></br></div>")
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<any>("success")
    const [shooting, setShooting] = React.useState<IWWGunShootingObject | null>(null);
    const [rowObject, setRowObject] = React.useState<any | null>(null);
    const [previewObject, setPreviewObject] = useState<IWWGunShootingObject | null>(null)
    const [previewDialog, setPreviewDialog] = React.useState<boolean>(false)
    const [editorState, setEditorState] = React.useState<any>(EditorState.createEmpty())
    //const [vhtml, setVhtml] = React.useState<string>("<div><b><ul><li style='list-style-type:disc;'>UPDATE #1: <ul><li style='list-style-type: circle; list-style-position: inside;'>bullet1</li><li style='list-style-type: circle; list-style-position: inside'>bullet2</li></ul></ul></div>")
    Geocode.setApiKey(Google_API_Key);

    let grid: Grid | null;
    const handleDataTyping = () =>
    {
        if(ShootingInfo) {
            let tempP: any = [...ShootingInfo]

            for (let p of tempP) {
                if (p.DateTimeIN) {
                    p.DateTimeIN = new Date(p.DateTimeIN)
                }
            }
            setTypedPersons(tempP)
        }
    }

    React.useEffect(() => {
        if(SelectionClear > 0){
            buildALPRList({}, "CLEAR")
        }
    },[SelectionClear])

    React.useEffect(() => {
        handleDataTyping();
        setALPRList([]);
    },[ShootingInfo])

    
    const selectTemplate = (person: any): any => {
        return (
        <>
            <Checkbox onClick={ () => {buildALPRList(person)} }/>
        </>);
    }

    const actionTemplate = (shooting: any): any => {
        return (
            <span>
                <ButtonGroup>
                    <Tooltip title="Preview" placement="top" arrow>
                        <Box>
                            <IconButton onClick={() => {previewNotifications(shooting); setPreviewDialog(true)}} ><PreviewIcon /></IconButton>
                        </Box>
                    </Tooltip>
                    {isUserPermission('IWW') ?
                        <>
                            <Tooltip title="Edit" placement="top" arrow>
                                <IconButton onClick={() => editShootingNotification(shooting)}><EditIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Send Shooting Notification" placement="top" arrow>
                                <IconButton onClick={() => {setRowObject(shooting); setEmailDialog(true)}}><EmailIcon /></IconButton>
                            </Tooltip>
                            <Tooltip title="Delete" placement="top" arrow>
                                <IconButton onClick={() => {deleteShootingNotification(shooting)}}><DeleteIcon /></IconButton>
                            </Tooltip>
                        </>
                    : <></>}
                </ButtonGroup>
            </span>);
    }

    const previewNotifications = async (shooting: any) => {
        if(shooting)
         {
            //let item = {main: {ID: shooting.DARID}}
            //let record:IWWGunShootingObject = await IWWGetRecord(item);
            //console.log(record)
            //setPreviewObject(record)
            setPreviewObject({});
            let record:IWWGunShootingObject = await IWWSendEmail({
                main: {
                    ID: shooting.DARID
                },
                IsPreview: true 
            });
            setPreviewObject(record)
            // var html = htmlToDraft(record?.Preview ?? '')
            // setEditorState(EditorState.createWithContent(
            //     ContentState.createFromBlockArray(
            //         html.contentBlocks, html.entityMap
            //     )
            //  ))
        }
    }

    const previewNotificationForm = async(shooting: any) =>{
        setPreviewObject({});
            let record:IWWGunShootingObject = await IWWSendEmail({
                main: {
                    ID: shooting.Main.ID
                },
                IsPreview: true 
            });
            setPreviewObject(record)
    }



    const editShootingNotification = async (shooting: any) => {
        if(shooting) {
            let item = {main: {ID: shooting.DARID}}
            let res = await IWWGetRecord(item);
            //console.log(res)
            setShooting(res)
            setEditTabName('Edit Shooting Notification');
            setTabValue('2');
        }
    }
    
    const sendShootingNotification = async (shooting:any) => {
        if(shooting)
        {
            setEmailDialog(false)
            let item = {main: {ID: shooting.DARID}}
            let res = await IWWSendEmail(item);
            if(res.IsValid)
            {
                setAlertType('success')
                setAlertText('Notification Sent!')
                setAlertOpen(true)
            }
            else
            {
                setAlertType('error')
                setAlertText('Notification Failed To Send!')
                setAlertOpen(true)
            }
        }
    }

    const deleteShootingNotification = async (shooting:any) => {
        if(shooting)
        {
            if (window.confirm("Remove This NJTrace Record Permanently?")) {
                let item = {main: {ID: shooting.DARID}}
                let record:IWWGunShootingObject = await IWWGetRecord(item);
                if(record && record.Main){
                    record.Main.Deleted = true

                }
                //console.log(record)
                let res= await IWWSaveRecord(record)
                if(res.IsValid)
                {
                    setAlertOpen(true)
                    setAlertText("Delete Successful (Changes Will Take Effect After The Next Search)")
                }
                else{
                    alert(res.AnyData?.ErrorMessage)
                }
                
                
            }
            

        }
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        if (newValue == '1') {
            setShooting(null);
            setEditTabName('New Shooting Notification');
        }

        setTabValue(newValue);
    };
    const NearByTemplate = (person: any): any => {
        return (
            <span>
                <Tooltip title={'Person: 0.25 Mile Range and Last 1 Year'} arrow><Button onClick ={() => {handlePersonGeo(person)}}><img src={PersonIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /></Button></Tooltip>
                <Tooltip title={'Vehicle: 16 minutes & 1.5 Mile Range'} arrow><Button onClick ={() => {handleCarGeo(person)}}><img src={VehicleIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /></Button></Tooltip>
                {isUserPermission('DeviceID') && checkDeviceDate(person) && (SiteName == SITE.NJPROD || SiteName == SITE.NJUAT || SiteName == SITE.NJDEMO || SiteName == SITE.LOCAL) ? <Tooltip title={'Device: 16 minutes & 3000 Feet Range'} arrow><Button onClick ={() => {handleDeviceGeo(person)}}><img src={DeviceIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /></Button></Tooltip> :<></>}
            </span>);
    }

    const checkDeviceDate = (person:any) => {
        let today:Date = new Date();
        let deviceDate = new Date(today.setDate(today.getDate() - 3));
        deviceDate.setHours(0,0,0)
        return Date.parse(deviceDate.toString()) > Date.parse(person.DateTimeIN);
        
    }
    
    const handleDeviceGeo = async (person:any) => {
        setExportData({})

        if (!localStorage.getItem('DLUser')) { //dont have a token? go get one.
            let res: any = await GetDeviceToken()
            if (res) {
                localStorage.setItem('DLUser', res)
            }
        }

        let DLUser: Account = getDeviceUser();
        let today = new Date();

        if (DLUser.exp && DLUser.exp < (today.getTime() / 1000)) {
            let res: any = await GetDeviceToken()
            if (res) {
                localStorage.setItem('DLUser', res)
            }
        }
        
        let date = person.DateTimeIN
        let milliseconds = Date.parse(date.toString())
        let start = new Date(milliseconds - (8 * 60 * 1000))
        let end = new Date(milliseconds + (8 * 60 * 1000))

        let search_query: any = {
            "DeviceId": [],
            "DeviceType": [],
            "Inc_DateTime": [formatDT(start)],  //User local timestamp
            "Inc_DateTimeEnd": [formatDT(end)],
            "Inc_Latitude": [person.Incident_Lat],
            "Inc_Longitude": [person.Incident_Lon],
            "Radius": [3000],
            "ExactLocation": ['Y'],
        }

        let DLT = localStorage.getItem("DLUser")

        if (DLT && DLT.length > 0) {
            let r: any = await AuthenticatedAPIReqSplashDL.post('/DeviceLocation/SearchNotification', search_query)
            if(r && r.data && r.data.length > 0)
            {
                let obj = {
                    shooting: [person],
                    devices: r.data
                }

                setExportData(r.data)
                deviceGeoCode(obj)
            }
        }
            
    }

    const handleCarGeo = async (item:any) => {
        setExportData({})
        console.log(item)
        let search_query:ALPRMultipleOccurenceQuery = { 
            "incidents": [
            {
                date: new Date(item.DateTimeIN).toLocaleString(),
                threshold: 8,
                radius: 1.5, //this needs to move to 1 on prod ***
                location: {
                    address: item.Address,
                    city: item.City,
                    state: item.State,
                    zip: item.Zip,
                    latitude: item.Incident_Lat,
                    longitude: item.Incident_Lon,
                    addressType: ""
                },
                showEdit: true,
                alprServerDB: "",
                alprServerName: ""
            }
        ],
            minOccurrence: 0,
            lookFor: "Car",
            plate: {
            plateNumber: "",
                plateSt: ""
        },
            queryType: "M",
            alprReason: "Suspicious Incident",
            alprCaseNumber: "Automated",
            UTCDate:false,
        }
        let res:any = await RunMultipleOccurrence(search_query)
        let parsed = JSON.parse(res?.JsonObject)
        if(parsed.MultipleOccurrenceList.length > 0) {
            let obj = {
                shooting: [item],
                cars: parsed.MultipleOccurrenceList
            }
            setExportData(parsed.MultipleOccurrenceList)
            carGeoCode(obj)
        }
    }
    
    const handlePersonGeo = async (person:any) => {
        setExportData({})
        let fromDate = new Date(person.DateTimeIN)
        fromDate.setFullYear(fromDate.getFullYear() -1)

        let search_query: GetDarInfo_Query = {
            "QueryType": "2",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": 1,
            "FromDate": fromDate.toISOString().substring(0,10),
            "ToDate": new Date(person.DateTimeIN).toISOString().substring(0,10),
            "SearchType": "AND",
            "IndicatorOn": true, //why?
            "TopArrestNumber": 0,
            "FNames": [],
            "MNames":  [],
            "LNames":  [],
            "DOBs": [],
            "SEX": [],
            "Address": "",
            "Cities": [],
            "States": [],
            "Zip": "",
            "Statutes": [],
            "Gang": [],
            "FBIs":  [],
            "SBIs": [],
            "DLNos": [],
            "PlateNos":  [],
            "VINs": [],
            "AgencyArrested": [],
            "InfocopDept":  [],
            "Indicators": [],
            "IndicatorOccurrence": null,
            "Latitude": person.Incident_Lat,
            "Longitude": person.Incident_Lon,
            "Radius": 0.25,
            "ResidentCounty": [],
            "ArrestedCounty": [],
            "Arrest_Type":  [],
            "CaseNumbers": [],
            "FTSIDs": [],
        }
        let qRes: getDarInfoResponse = await RunDarQuery(search_query)
        if(qRes.PersonList?.length > 0) {
            let obj = {
                shooting: [person],
                people: qRes.PersonList
            }
            setExportData(qRes.PersonList)
            personGeoCode(obj)
        }
    }

    const handleIWWFormCancel = () => {
        setTabValue('1')
        setAlertType('info')
        setAlertText('Record Canceled!')
        setAlertOpen(true)
        setEditTabName('New Shooting Notification')
        setShooting({})
    }
    
    const handleIWWNotificationsSubmit = async (params : IWWNotificationsObject) => {
        var submitObject : IWWGunShootingObject = {
            Main:{},
            Suspects:[],
            Weapons:[],
            Vehicles:[],
            FileOrImages:[],
            Changes:[],
        }
        
        if(shooting && shooting?.Main && shooting?.Main?.ID){
            submitObject = shooting
            if(!submitObject?.Weapons)
                submitObject.Weapons = []
            if(!submitObject?.Suspects)
                submitObject.Suspects = []
            if(!submitObject?.Vehicles)
                submitObject.Vehicles = []
            if(!submitObject?.FileOrImages)
                submitObject.FileOrImages = []
            if(!submitObject?.Changes)
                submitObject.Changes = []
        }
        
        if(params?.subject){
            for( var subject of params?.subject){
                //console.log(subject)
                if (submitObject.Main){
                    submitObject.Main.Email_Subject = subject?.SUBJECT ? subject?.SUBJECT : ''
                    submitObject.Main.Email_State = subject?.STATE ? subject?.STATE : ''
                    submitObject.Main.Email_County = subject?.COUNTY ? subject?.COUNTY : ''
                    submitObject.Main.Email_City = subject?.CITY ? subject?.CITY : ''
                }       
            }
        }
        if(params?.incidents){
            for( var incident of params?.incidents){
                if (submitObject.Main){
                    if((incident?.INCIDENT_LOCATION != submitObject?.Main?.Inc_Address) || (incident?.REC_COUNTY != submitObject?.Main?.County) || (incident?.REC_STATE != submitObject?.Main?.inc_State) || (incident?.REC_CITY != submitObject?.Main?.Inc_City)) {
                        if (incident?.INCIDENT_LOCATION && incident?.REC_COUNTY && incident?.REC_CITY && incident?.REC_STATE) {
                            let response = await Geocode.fromAddress(incident?.INCIDENT_LOCATION + ',' + incident?.REC_COUNTY + ',' + incident?.REC_CITY + ',' + incident?.REC_STATE)
                            const {lat, lng} = response.results[0].geometry.location
                            if (submitObject.Main) {
                                submitObject.Main.Latitude = lat.toString()
                                submitObject.Main.Longitude = lng.toString()
                            }
                        }
                    }
                    submitObject.Main.Inc_TYPE = incident?.REC_INCIDENT_TYPE ? incident?.REC_INCIDENT_TYPE : ''
                    submitObject.Main.inc_State = incident?.REC_STATE ? incident?.REC_STATE : ''
                    submitObject.Main.County = incident?.REC_COUNTY ? incident?.REC_COUNTY : ''
                    submitObject.Main.Inc_City = incident?.REC_CITY ? incident?.REC_CITY : ''
                    submitObject.Main.Inc_Address = incident?.INCIDENT_LOCATION ? incident?.INCIDENT_LOCATION : ''
                    submitObject.Main.inc_Address2 = incident?.LOCATION_DETAILS ? incident?.LOCATION_DETAILS : ''
                    let datetime = incident?.REC_DATE ? incident?.REC_DATE + ( incident?.REC_TIME ? ' ' + incident?.REC_TIME : '00:00' ) : '1900-01-01 00:00:00'
                    submitObject.Main.Inc_DateTime = datetime
                }       
            }
        }
        if(params?.details){
            for( var details of params?.details){
                if (submitObject.Main){
                    submitObject.Main.Details = details?.DETAILS ? details?.DETAILS : ''
                    submitObject.Main.NoOfVictims = details?.NUMBER_OF_VICTIMS ? details?.NUMBER_OF_VICTIMS : ''
                    // submitObject.Main.SUSPECT_PHYSICAL_DESCRIPTION = details?.PER_PHYSICAL_DESCRIPTION ? details?.PER_PHYSICAL_DESCRIPTION : ''
                    // submitObject.Main.SUSPECT_CLOTHING_DESCRIPTION = details?.PER_CLOTHING_DESCRIPTION ? details?.PER_CLOTHING_DESCRIPTION : ''
                    // submitObject.Main.SUSPECT_DIRECTION_OF_FLIGHT = details?.PER_DIRECTION_OF_FLIGHT ? details?.PER_DIRECTION_OF_FLIGHT : ''
                }       
            }
        }

        if (params?.suspects){
            for( var suspect of params?.suspects){
                //console.log(suspect)
                if (submitObject.Suspects){
                    if ( (!suspect.ID || suspect.ID == 0) && !suspect.Deleted ){
                        submitObject.Suspects.push({
                            PHYSICAL_DESCRIPTION : suspect?.PER_PHYSICAL_DESCRIPTION ? suspect?.PER_PHYSICAL_DESCRIPTION : '',
                            CLOTHING_DESCRIPTION : suspect?.PER_CLOTHING_DESCRIPTION ? suspect?.PER_CLOTHING_DESCRIPTION : '',
                            DIRECTION_OF_FLIGHT : suspect?.PER_DIRECTION_OF_FLIGHT ? suspect?.PER_DIRECTION_OF_FLIGHT : '',
                            Deleted : suspect?.Deleted ? suspect?.Deleted : false
                        })
                    }
                    else if(suspect .ID && suspect.ID !=0){
                        let sIndex = submitObject.Suspects.findIndex(e => e.ID === suspect.ID )
                        submitObject.Suspects[sIndex].PHYSICAL_DESCRIPTION = suspect?.PER_PHYSICAL_DESCRIPTION ? suspect?.PER_PHYSICAL_DESCRIPTION : '';
                        submitObject.Suspects[sIndex].CLOTHING_DESCRIPTION = suspect?.PER_CLOTHING_DESCRIPTION ? suspect?.PER_CLOTHING_DESCRIPTION : '';
                        submitObject.Suspects[sIndex].DIRECTION_OF_FLIGHT = suspect?.PER_DIRECTION_OF_FLIGHT ? suspect?.PER_DIRECTION_OF_FLIGHT : '';
                        submitObject.Suspects[sIndex].Deleted = suspect?.Deleted ? suspect?.Deleted : false;
                    }
                }
            }
        }
      
        if (params?.weapon){
            for( var weapon of params?.weapon){
                if (submitObject.Weapons){
                    if ( (!weapon.ID || weapon.ID == 0) && !weapon.Deleted ){
                        submitObject.Weapons.push({
                            W_Status : weapon?.RECOVERY_STATUS ? weapon?.RECOVERY_STATUS : '',
                            W_Caliber : weapon?.CALIBER ? weapon?.CALIBER : '',
                            W_Type : weapon?.TYPE ? weapon?.TYPE : '',
                            W_Make : weapon?.MAKE ? weapon?.MAKE : '',
                            W_Model : weapon?.MODEL ? weapon?.MODEL : '',
                            W_Desc : weapon?.DESCRIPTION ? weapon?.DESCRIPTION : '',
                            Deleted : weapon?.Deleted ? weapon?.Deleted : false 
                        })
                    }
                    else if(weapon.ID && weapon.ID != 0){
                        let wIndex = submitObject.Weapons.findIndex(e => e.ID === weapon.ID )
                        submitObject.Weapons[wIndex].W_Status =  weapon?.RECOVERY_STATUS ? weapon?.RECOVERY_STATUS : '';
                        submitObject.Weapons[wIndex].W_Caliber =  weapon?.CALIBER ? weapon?.CALIBER : '';
                        submitObject.Weapons[wIndex].W_Type = weapon?.TYPE ? weapon?.TYPE : '';
                        submitObject.Weapons[wIndex].W_Make = weapon?.MAKE ? weapon?.MAKE : '';
                        submitObject.Weapons[wIndex].W_Model = weapon?.MODEL ? weapon?.MODEL : '';
                        submitObject.Weapons[wIndex].W_Desc = weapon?.DESCRIPTION ? weapon?.DESCRIPTION : '';
                        submitObject.Weapons[wIndex].Deleted =  weapon?.Deleted ? weapon?.Deleted : false;
                    }
                }
            }
        }

        if (params?.vehicleDetails){
            for( var vehicle of params?.vehicleDetails){
                //console.log(vehicle)
                if (submitObject.Vehicles){
                    if ( (!vehicle.ID || vehicle.ID == 0) && !vehicle.Deleted ){
                        submitObject.Vehicles.push({
                            PlateNum : vehicle?.PLATE_NUMBER ? vehicle?.PLATE_NUMBER : '',
                            PlateSt : vehicle?.PLATE_STATE ? vehicle?.PLATE_STATE : '',
                            VehicleMake : vehicle?.MAKE ? vehicle?.MAKE : '',
                            VehicleModel : vehicle?.MODEL ? vehicle?.MODEL : '',
                            VehicleColor : vehicle?.COLOR ? vehicle?.COLOR : '',
                            VehicleYear : vehicle?.YEAR ? vehicle?.YEAR : '',
                            VEHICLE_DIRECTION_OF_FLIGHT : vehicle?.VEHICLE_DIR_OF_FLIGHT ? vehicle?.VEHICLE_DIR_OF_FLIGHT : '',
                            INVOLVED_VEHICLE_DESCRIPTION : vehicle?.INVOLVED_VEHICLE_DESCRIPTION ? vehicle?.INVOLVED_VEHICLE_DESCRIPTION : '',
                            Deleted : vehicle?.Deleted ? vehicle?.Deleted : false
                        })
                    }
                    else if(vehicle.ID && vehicle.ID !=0){
                        let vIndex = submitObject.Vehicles.findIndex(e => e.ID === vehicle.ID )
                        submitObject.Vehicles[vIndex].PlateNum = vehicle?.PLATE_NUMBER ? vehicle?.PLATE_NUMBER : '';
                        submitObject.Vehicles[vIndex].PlateSt = vehicle?.PLATE_STATE ? vehicle?.PLATE_STATE : '';
                        submitObject.Vehicles[vIndex].VehicleMake = vehicle?.MAKE ? vehicle?.MAKE : '';
                        submitObject.Vehicles[vIndex].VehicleModel = vehicle?.MODEL ? vehicle?.MODEL : '';
                        submitObject.Vehicles[vIndex].VehicleColor = vehicle?.COLOR ? vehicle?.COLOR : '';
                        submitObject.Vehicles[vIndex].VEHICLE_DIRECTION_OF_FLIGHT = vehicle?.VEHICLE_DIR_OF_FLIGHT ? vehicle?.VEHICLE_DIR_OF_FLIGHT : '';
                        submitObject.Vehicles[vIndex].INVOLVED_VEHICLE_DESCRIPTION = vehicle?.INVOLVED_VEHICLE_DESCRIPTION ? vehicle?.INVOLVED_VEHICLE_DESCRIPTION : '';
                        submitObject.Vehicles[vIndex].Deleted = vehicle?.Deleted ? vehicle?.Deleted : false;
                    }
                }
            }
        }

        // if(params?.vehicleDetails){
        //     for( var vehicleDetails of params?.vehicleDetails){
        //         if (submitObject.Main){
        //             submitObject.Main.INVOLVED_VEHICLE_DESCRIPTION =  vehicleDetails?.INVOLVED_VEHICLE_DESCRIPTION ? vehicleDetails?.INVOLVED_VEHICLE_DESCRIPTION : ''
        //             submitObject.Main.VEHICLE_DIRECTION_OF_FLIGHT = vehicleDetails?.VEHICLE_DIR_OF_FLIGHT ? vehicleDetails?.VEHICLE_DIR_OF_FLIGHT : ''
        //             submitObject.Main.VehicleMake = vehicleDetails?.MAKE ? vehicleDetails?.MAKE : ''
        //             submitObject.Main.VehicleModel = vehicleDetails?.MODEL ? vehicleDetails?.MODEL : ''
        //             submitObject.Main.VehicleColor = vehicleDetails?.COLOR ? vehicleDetails?.COLOR : ''
        //             submitObject.Main.VehicleYear = vehicleDetails?.YEAR ? vehicleDetails?.YEAR : ''
        //             submitObject.Main.PlateNum = vehicleDetails?.PLATE_NUMBER ? vehicleDetails?.PLATE_NUMBER : ''
        //         }       
        //     }
        // }
        if(params?.additionalInfo){
            for( var additionalInfo of params?.additionalInfo){
                if (submitObject.Main){
                    submitObject.Main.Dept = additionalInfo?.SOURCE ? additionalInfo?.SOURCE : ''
                    submitObject.Main.AUTHORITY = additionalInfo?.AUTHORITY ? additionalInfo?.AUTHORITY : ''
                    submitObject.Main.Duty_Officer = additionalInfo?.OFFICER_NAME ? additionalInfo?.OFFICER_NAME : ''
                    submitObject.Main.Duty_Officer_BadgeNo = additionalInfo?.BADGE ? additionalInfo?.BADGE : ''
                    submitObject.Main.Duty_Officer_Contact = additionalInfo?.CONTACT_NUMBER ? additionalInfo?.CONTACT_NUMBER : ''
                    let diss = ''
                    if(additionalInfo?.DISSEMINATION){
                        for(var d = 0; d < additionalInfo?.DISSEMINATION.length; d++){
                            diss += additionalInfo?.DISSEMINATION[d] + ( d != additionalInfo?.DISSEMINATION.length -1 ? ',' : '') 
                        }
                    }
                    submitObject.Main.DISSEMINATION = diss
                    let dissBcc = ''
                    if(additionalInfo?.DISSEMINATION_BCC){
                        for(var d = 0; d < additionalInfo?.DISSEMINATION_BCC.length; d++){
                            dissBcc += additionalInfo?.DISSEMINATION_BCC[d] + ( d != additionalInfo?.DISSEMINATION_BCC.length -1 ? ',' : '') 
                        }
                    }
                    submitObject.Main.DISSEMINATION_BCC = dissBcc
                }
            }
        }
        if (submitObject.Main) {
            submitObject.Main.Status = params?.Status
            submitObject.Main.EditStatus = params?.EditStatus
            submitObject.Main.Update_Message = params?.UpdateMessage
        }
        
        if(params.FileOrImages && submitObject.FileOrImages)
        {
            submitObject.FileOrImages = params.FileOrImages
        }
        
        let saveRequest = await IWWSaveRecord(submitObject)
        if(saveRequest.IsValid){
            //console.log(saveRequest)
            setShooting(saveRequest)
            setEmailOnSaveDialog(true)
        }
        else
        {
            setAlertType('error')
            setAlertText('Record Saved Unsuccessful ')
            setAlertOpen(true)
        }
        
    }
    
    const handleSaveNotificationSubmit = async (conclusion:boolean) => {
        setTypedPersons([])
        let todayDate = new Date();
        let monthAgoDate = new Date();
        monthAgoDate.setMonth(monthAgoDate.getMonth() - 1);
        monthAgoDate.setDate(monthAgoDate.getDate() + 1);
        SubmitSearch({toDate: todayDate, fromDate: monthAgoDate, showDate: false}, 'Entry Date', 'AND', {})
        setEditTabName('New Shooting Notification')
        setTabValue('1')
        setEmailOnSaveDialog(false)
        if(conclusion) {
            let res = await IWWSendEmail(shooting);
            setAlertText('Record Saved Successfully and Notification Sent!')
        }
        else {
            setAlertText('Record Saved Successfully!')
        }
        setAlertType('success')
        setAlertOpen(true)
        setShooting({})
    }
    
    const buildALPRList = (person:any, status:any = null) => {
        let ALPRListTemp = alprList
        
        if(status && status == 'CLEAR') {
            ALPRListTemp.length = 0
            setALPRList([])
            setALPRList(ALPRListTemp)
        }
        else
        {
            console.log(ALPRListTemp)
            var result = ALPRListTemp.findIndex((x: any) => x.DARID === person.DARID)

            if (result === -1) {
                ALPRListTemp.push(person)

            } else {
                ALPRListTemp.splice(result, 1)
            }
            setALPRList(ALPRListTemp)
        }
        
        
    } 
    
    const handleALPRListSend = () => {
        if(alprList.length < 1)
        {
            window.alert("Please Select At Least One Incident To Compare (Check Lat & Long)")
        }
        else
        {
            let l = new JAOS()
            let hashID = l.objToStack(alprList)
            window.open('/tools/alpr_multiple_occurrence?ALPRList='+hashID,'_blank')
            
        }
    }

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            let excelProp:ExcelExportProperties = {}
            excelProp.fileName = 'Shooting.xlsx'
            grid.excelExport(excelProp);
        }else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        } else if (grid && args.item.text === 'Clear Selections') {
            buildALPRList({}, 'CLEAR')
        }
    }

    const personGeoCode = (peopleGeo:any) => {
        setMapVersion("person")
        let pins:any[] = []
        for (let p of peopleGeo.shooting)
        {
            if(p.Incident_Lat && p.Incident_Lon)
            {
                pins.push({lat:p.Incident_Lat, lng:p.Incident_Lon, date:p.DateTimeIN, dept:p.Dept, victims:p.NoOfVictims, suspect:p.SuspectDesc, inc_type:p.Inc_Type, tool:"shooting"})
            }
        }
        for (let p of peopleGeo.people)
        {
            if (p.Incident_Latitude && p.Incident_Longitude)
            {
                pins.push({ lat: p.Incident_Latitude, lng: p.Incident_Longitude, name:p.FName + ' ' + p.MName + ' ' + p.LName, date:p.DTOFARREST, statute:p.STATUTE ,tool:"person"})
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
    }

    const carGeoCode = (carGeo:any) => {
        setMapVersion("car")
        let pins:any[] = []
        for (let p of carGeo.shooting)
        {
            if(p.Incident_Lat && p.Incident_Lon)
            {
                pins.push({lat:p.Incident_Lat, lng:p.Incident_Lon, date:p.DateTimeIN, dept:p.Dept, victims:p.NoOfVictims, suspect:p.SuspectDesc, inc_type:p.Inc_Type, tool:"shooting"})
            }
        }
        for (let p of carGeo.cars)
        {
            if(p.Latitude && p.Longitude)
            {
                if (p.POIReason?.toLowerCase() == 'stolen vehicle' || p.POIReason?.toLowerCase() == 'stolen plate' || p?.POIReason?.toLowerCase() == 'felony stolen vehicle')
                    pins.push({ lat: p.Latitude, lng: p.Longitude, plate: p.red_vrm, date: p.red_TimeStamp, alprSource: p.src_Name, tool: "stolenCar" })
                else
                    pins.push({lat:p.Latitude, lng:p.Longitude, plate:p.red_vrm, date:p.red_TimeStamp, alprSource:p.src_Name ,tool:"car"})
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
    }

    const deviceGeoCode = (deviceGeo:any) => {
        setMapVersion("device")
        let pins:any[] = []
        for (let p of deviceGeo.shooting)
        {
            if(p.Incident_Lat && p.Incident_Lon)
            {
                pins.push({lat:p.Incident_Lat, lng:p.Incident_Lon, date:p.DateTimeIN, dept:p.Dept, victims:p.NoOfVictims, suspect:p.SuspectDesc, inc_type:p.Inc_Type, tool:"shooting"})
            }
        }
        for (let p of deviceGeo.devices)
        {
            if(p.Latitude && p.Longitude)
            {
                pins.push({lat:p.Latitude, lng:p.Longitude, device:p.DeviceID, date:p.EventDateTimeUTC, distance:p.Distance, accuracy:p.HorizontalAccuracy ,tool:"device"})
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
    }
    
    const openGeoCode = async () => {
        setMapVersion("shooting")
        setAllPins([])
        let pins:any[] = []
        let tmpShootingInfo = grid && (grid?.getFilteredRecords() as any[])?.length > 0 ?  (grid.getFilteredRecords() as any[])  : ShootingInfo;
        for (let p of tmpShootingInfo)
        {
            if(p.Incident_Lat && p.Incident_Lon)
            {
                pins.push({lat:p.Incident_Lat, lng:p.Incident_Lon, date:p.DateTimeIN, dept:p.Dept, victims:p.NoOfVictims, suspect:p.SuspectDesc, inc_type:p.Inc_Type, tool:"shooting"})
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
    }

    const HandleGeoCodeClose = () => {
        setShowGeoCode(false)
    }

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'DateTimeIN', direction: 'Descending' }
        ] };
    
    const handleList = () =>{
        const html = "item1"
        return <li>{html}</li>

    }
    
    return (
        <div className={"pl-5 pr-5"}>
            <Snackbar open={alertOpen} autoHideDuration={6000} onClose={ () => { setAlertOpen(false)}}>
                <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                    {alertText}
                </Alert>
            </Snackbar>
            <Box className={isUserPermission('IWW') ? "pt-3" : ''} style={{ justifyContent:'center' }}>
                <TabContext value={tabValue}>
                    {isUserPermission('IWW') ?
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleTabChange} variant="fullWidth">
                             <Tab icon={<List />} label="List View"  value={'1'}/>
                             {(SiteName == SITE.NJPROD || SiteName == SITE.NJUAT || SiteName == SITE.NJDEMO || SiteName == SITE.LOCAL) ? <Tab icon={<PostAdd />} label={editTabName} value={'2'} /> : <></>}
                        </TabList>
                    </Box>
                        :<></>}
                    <TabPanel value='1'>
                        <div>
                            {typedPersons && typedPersons.length>0? <>
                                <span style ={{display:"inline-block"}} className='mb-5'>
                                    <h4 className={"font-bold inline mr-5 text-lg"}> {typedPersons?.length} Records Found</h4>
                                    <ButtonGroup size="medium" color="inherit" >
                                         <Button variant="outlined" onClick={openGeoCode}>
                                            <MapIcon color="success" className ="mr-2"/><b>Map</b>
                                        </Button>
                                        <Button variant="outlined" onClick={handleALPRListSend}>
                                           <img src={VehicleIcon} className={"mr-2"} alt={""} />Incident Connection
                                        </Button>
                                    </ButtonGroup>
                                    {(showGeoCode) ? <div><GeoCode HandleGeoCodeClose={HandleGeoCodeClose} show={showGeoCode} pins={allPins} mapVersion={mapVersion} exportData={exportData}/></div>:<></>}
                                </span>
                                <GridComponent
                                    dataSource={typedPersons}
                                    allowPaging={true}
                                    pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                                    allowSorting={true}
                                    sortSettings={sortSettings}
                                    allowMultiSorting={true}
                                    allowFiltering={true}
                                    filterSettings={{type: 'CheckBox'}}
                                    allowTextWrap={true}
                                    textWrapSettings={{ wrapMode: 'Both' }}
                                    allowExcelExport={true}
                                    allowPdfExport={true}
                                    toolbar={['ExcelExport','Search',  { text: 'Clear Selections', tooltipText: 'Clear all Selected Shootings', prefixIcon: 'e-refresh', id: 'btn_clear' },]}
                                    toolbarClick={GridToolsClick}
                                    ref={(g) => { if (g) { grid = g } }}
                                    allowSelection={false}
                                >
                                    <ColumnsDirective>
                                        <ColumnDirective template={selectTemplate} headerText='Select' width={100} />
                                        {!(SiteName == SITE.DCPROD || SiteName == SITE.DCDEMO || SiteName == SITE.DCLOCAL) &&
                                            <ColumnDirective template={actionTemplate} headerText='Actions' width={300} textAlign='Center'/>
                                        }
                                        <ColumnDirective field='DateTimeIN' format = {{type:'dateTime', format:'MM/dd/yyyy HH:mm'}} headerText='Date'/>
                                        <ColumnDirective field='Inc_Type' headerText='Type'/>
                                        <ColumnDirective field='Address' headerText='Address' />
                                        <ColumnDirective field='County' headerText={`${GetStringNameForSite(SiteName,'COUNTY')}`}/>
                                        <ColumnDirective field='City' headerText={`${GetStringNameForSite(SiteName,'CITY')}`}/>
                                        <ColumnDirective field='NoOfVictims' headerText='Victim(s)'/>
                                        <ColumnDirective field='SuspectDesc'  headerText='Suspect(s)'/>
                                        <ColumnDirective field='Dept'  headerText='Agency'/>
                                        <ColumnDirective template={NearByTemplate} headerText='Near By Vehicle/Person/Device' width={250}/>
                                    </ColumnsDirective>
                                    <Inject services={[Sort, Page, Filter, DetailRow, Toolbar, ExcelExport,PdfExport,Search]}/>
                                </GridComponent>                                
                            </>:<div className="font-bold text-center text-lg pt-3">NO Matching Records Found</div>}
                        </div>
                    </TabPanel>
                    <TabPanel value='2'>
                        <IWWNotificationsInputForm handleReportSubmit={handleIWWNotificationsSubmit} handleFormCancel={handleIWWFormCancel} isEdit={true} shooting={shooting} />
                    </TabPanel>
                </TabContext>
            </Box>
            <Dialog
                open={emailDialog}
                maxWidth={'xl'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className={"flex justify-between"}>
                    <h2>Send Shooting Notification</h2> <IconButton onClick={ () => setEmailDialog(false)}><CloseIcon fontSize='small'/></IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you would like to send out this notification?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={"mr-2 mb-2"}>
                    <Button variant={"outlined"} color="error" onClick={() => setEmailDialog(false)} autoFocus> Cancel </Button>
                    <Button variant={"outlined"} onClick={() => sendShootingNotification(rowObject)} autoFocus> Send Notification </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={emailOnSaveDialog}
                maxWidth={'xl'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className={"flex justify-between"}>
                    <h2>Send Shooting Notification</h2>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Do you want to send the notification now?
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={"mr-2 mb-2"}>
                    <Button style={{color:"black", borderColor:"black"}} variant={"outlined"} onClick={() => {previewNotificationForm(shooting); setPreviewDialog(true);}} > Preview </Button>
                    <Button variant={"outlined"} color="error" onClick={() => handleSaveNotificationSubmit(false)} autoFocus> No </Button>
                    <Button variant={"outlined"} onClick={() => handleSaveNotificationSubmit(true)} autoFocus> Yes </Button>
                </DialogActions>
            </Dialog>
             <Dialog
                 open={previewDialog}
                 maxWidth={'xl'}
                //fullScreen             
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                 <DialogTitle className={"flex justify-between"}>
                    <h2>Shooting Notification Preview</h2> <IconButton onClick={ () => setPreviewDialog(false)}><CloseIcon fontSize='small'/></IconButton>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description">
                            {/*@ts-ignore*/}
                             {/* <Editor
                                toolbarHidden
                                editorState={editorState}      
                            />                          */}
                        <div dangerouslySetInnerHTML={{__html: previewObject?.Preview ?? ''}}>
                        {/* <div dangerouslySetInnerHTML={{__html: vhtml}}> */}
                        </div>
                                
                        {previewObject?.FileOrImages ? <h1><strong>Files Attached to Shooting Notification</strong> {previewObject.FileOrImages?.map((file, idx)=> (<>
                                <div style={{marginLeft:"10px"}}>
                                    {file.FileName}
                                </div>
                            </>))}</h1> : ''}
                        
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={"mr-2 mb-2"}>
                    <Button variant={"outlined"} color="error" onClick={() => setPreviewDialog(false)} autoFocus> Cancel </Button>
                 </DialogActions>
               
            </Dialog>  
        </div>
    );
};

{/*<ColumnDirective field='STATUTEDESCRIPTION' headerText='Details'/>*/}
export default SearchResults;

