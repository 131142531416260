import { useEffect, useState } from "react";
import { NJPortDashboardDataApi } from "../../../services/njport.dashboard.service";

type Props = {
    data: any[];
};

const PDOHireStats = () => {
    const [checkerRequests, setCheckerRequests] = useState<number>(0);
    const [checkerAvailable, setCheckerAvailable] = useState<number>(0);
    const [checkerRemaining, setCheckerRemaining] = useState<number>(0);
    const [lsAvailable, setLSAvailable] = useState<number>(0);
    const [lsRequests, setLSRequests] = useState<number>(0);
    const [lsRemaining, setLSRemaining] = useState<number>(0);
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        const payload = {
            queryType: "PDOHIRE_DAY",
            dateTypeValue: 1,
        };
        NJPortDashboardDataApi(payload).then((qRes) => {
            setData(qRes?.AnyTable);
        });
    }, []);

    useEffect(() => {
        if (data) {
            console.log(data);
            data.map((item: any) => {
                if (item?.JobID === "CK") {
                    if (item.Requested && item.Requested !== null) {
                        setCheckerRequests((prev) => prev + item?.Requested);
                    }
                    if (item.Available && item.Available !== null) {
                        setCheckerAvailable((prev) => prev + item?.Available);
                    }
                    if (item.Remaining && item.Remaining !== null) {
                        setCheckerRemaining((prev) => prev + item?.Remaining);
                    }
                }
                if (item?.JobID === "LS") {
                    if (item.Requested && item.Requested !== null) {
                        setLSRequests((prev) => prev + item?.Requested);
                    }
                    if (item.Available && item.Available !== null) {
                        setLSAvailable((prev) => prev + item?.Available);
                    }
                    if (item.Remaining && item.Remaining !== null) {
                        setLSRemaining((prev) => prev + item?.Remaining);
                    }
                }
            });
        }
    }, [data]);

    if (!data || data?.length <= 0) {
        return (
            <div
                className={"text-center text-base xl:text-3xl mt-6 mb-1"}
                style={{ color: "red", fontWeight: 500 }}
            >
                No Data
            </div>
        );
    }

    return (
        <>
            <div className="flex flex-col items-center">
                <div className="flex flex-col w-full">
                    <div className="flex justify-between p-2 border-b border-gray-200">
                        <span>Checkers Requests</span>
                        <span>{checkerRequests}</span>
                    </div>
                    <div className="flex justify-between p-2 border-b border-gray-200">
                        <span>Checkers Available</span>
                        <span>{checkerAvailable}</span>
                    </div>
                    <div className="flex justify-between p-2 border-b border-gray-200">
                        <span>Checkers Remaining</span>
                        <span>{checkerRemaining}</span>
                    </div>
                    <div className="flex justify-between p-2 border-b border-gray-200">
                        <span>Longshoreman Requested</span>
                        <span>{lsRequests}</span>
                    </div>
                    <div className="flex justify-between p-2 border-b border-gray-200">
                        <span>Longshoreman Available</span>
                        <span>{lsAvailable}</span>
                    </div>

                    <div className="flex justify-between p-2 border-b border-gray-200">
                        <span>Longshoreman Remaining</span>
                        <span>{lsRemaining}</span>
                    </div>

                </div>
            </div>
        </>
    );
};

export default PDOHireStats;
