import {PersonInfo} from "../../../interfaces/getDarInfo_interface";
import React from "react";
import { Disclosure } from '@headlessui/react'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import WeaponTraceInfo from "../../../interfaces/WeaponTraceInfo.interface";
import { TablePagination, CircularProgress, Button } from "@mui/material";
import GunTraceIcon from "../../../assets/Images/WeaponTrace1.png";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent ,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search
} from '@syncfusion/ej2-react-grids';
import {NJTraceMainObject} from "../../../interfaces/Detail/NJTraceDetail.interface";
import {ETraceResults_PDF} from "../../../services/account.service";

interface PanelETraceProps {
    person: PersonInfo,
    WeaponTraces: WeaponTraceInfo[],
    isLoading:boolean
}

const PanelETrace: React.FC<PanelETraceProps> = ({ person, WeaponTraces, isLoading }) => {
    const [FormatData, setFormatData] = React.useState(0);

    const handleDataTyping = () => {
        let tempP: any = [...WeaponTraces]
        for (let p of tempP) {
            if (p.DateTimeIn) {
                p.DateTimeIn = new Date(p.DateTimeIn)
            }
            if (p.PurchaseDate) {
                p.PurchaseDate = new Date(p.PurchaseDate)
            }
        }
        setFormatData(tempP)
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [WeaponTraces])

    const viewATFResults = (njTrace: any) =>{
        window.open('/detail/ETrace?ID=' + njTrace?.MainID + '&Dept=' + njTrace?.ATFNo, '_blank')
        if(njTrace?.ATFNo && njTrace?.ATFNo.length > 0){
            let njTraceMain: NJTraceMainObject = {FTS_ID: njTrace.ATFNo};
            ETraceResults_PDF({main:njTraceMain}).then((r)=>{}).catch((e)=>{});
        }/*else {
            window.open('/detail/ETrace?ID=' + njTrace?.MainID + '&Dept=' + njTrace?.ATFNo, '_blank')
        }*/
       
    }
    return (
        <div className={"m-5 border"}>
            <Disclosure>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="bg-blue-100 p-2 w-auto w-full text-left">
                            <span style={{ "display": "flex", "justifyContent": "space-between" }}>
                                <span>
                                    <KeyboardArrowDownIcon
                                        className={`${open ? "transform rotate-180" : ""}`}
                                    />
                                    <span className={"font-bold"}>E-Trace <img src={GunTraceIcon} alt={''} style={{ display: 'inline' }} /> {(isLoading) ? <CircularProgress size={20} style={{ "marginLeft": "5px" }} /> : <></>}</span>
                                </span>
                                <span className={"font-bold mr-2"} >Name: {person?.FName?.toUpperCase()} {person?.MName?.toUpperCase()} {person?.LName?.toUpperCase()} &nbsp;&nbsp;Records: {WeaponTraces?.length}</span>
                            </span>
                        </Disclosure.Button>

                        <Disclosure.Panel className="text-gray-500 p-4">

                            <GridComponent
                                dataSource={FormatData}
                                allowPaging={true}
                                pageSettings={{ pageSize: 50, pageSizes: [10, 25, 50, 100] }}
                                allowSorting={true}
                                allowMultiSorting={true}
                                allowFiltering={true}
                                filterSettings={{ type: 'CheckBox' }}
                                allowTextWrap={true}
                                textWrapSettings={{ wrapMode: 'Both' }}
                                rowHeight={24}
                            >
                                <ColumnsDirective>
                                    <ColumnDirective field='DateTimeIn' headerText='Recovery Date' format={{ type: 'date', format: 'MM/dd/yyyy' }}/>
                                    <ColumnDirective field='ATFNo' headerText='ATF #' />
                                    <ColumnDirective field='RoleDesc' headerText='Role' />
                                    <ColumnDirective template={(item: any) => (<>({item?.MANUFACTURER_NAME} {item?.MODEL} {item?.SERIAL_NUMBER}</>)} headerText='Weapon (Make, Model, Serial #)' />
                                    <ColumnDirective field='DealerNo' headerText='FFL #' />
                                    <ColumnDirective field='DealerName' headerText='FFL Name' />
                                    <ColumnDirective template={(item: any) => (<>{item?.P_FIRSTNAME} {item?.P_MI} {item?.P_LASTNAME}, {item?.P_Address && item?.P_Address !== '' ? ', Address: ' + item?.P_Address : ''}</>)} headerText='Purchaser(Name, Address)' /> 
                                    <ColumnDirective field='PurchaseDate' headerText='Purchase Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} />
                                    <ColumnDirective field='CrimeHistory' headerText='TTC (in Days)' />
                                    <ColumnDirective template={(item: any) => (<>{item?.S_FIRSTNAME} {item?.S_MI} {item?.S_LASTNAME} {item?.S_Address && item?.S_Address !== '' ? ', Address: '+ item?.S_Address : '' }</>)} headerText='Suspect(Name, Address)' /> 
                                    <ColumnDirective template={(item: any) => (<>({item?.IncidentAddress} {item?.City} {item?.ST})</>)} headerText='Recovery Address' />
                                    <ColumnDirective field='Latitude' headerText='Latitude' visible={false}/>
                                    <ColumnDirective field='Longitude' headerText='Longitude' visible={false}/>
                                    {/*<ColumnDirective template={(item: any) => (<>({item.Latitude}, {item.Longitude})</>)} headerText='MSP (Latitude, Longitude)' visible={false} />*/}
                                    <ColumnDirective template={(item: any) => (<Button onClick={() => { viewATFResults(item)  }} >View</Button>)} headerText='View' />
                                    {/* <ColumnDirective template={(item: any) => (<Button onClick={() => { console.log(item)}} >View</Button>)} headerText='View' /> */}

                                </ColumnsDirective>
                                <Inject services={[Sort, Page, Filter]} />
                            </GridComponent>

                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
        </div>

    )
};

export default PanelETrace;