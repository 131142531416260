import React, { useState } from "react";
import SearchResults from "../../components/tools/carjacking/_searchResultsCarjacking";
import CarjackingSearch from "../../components/tools/carjacking/_carjackingSearch";
import { GetDarInfo_Query, PersonInfo, getDarInfoResponse } from "../../interfaces/getDarInfo_interface";
import { RunDarQuery } from "../../services/getDar.service";
import { Account } from "../../interfaces/auth_interface";
import {getUser, isUserPermission} from "../../services/auth.service";
import {handleSearchTime} from "../../services/formatDate.service";

function CarjackingNotification() {
    let [carjackings, setCarjackings] = useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    let [noData, setNoData] = useState<boolean>(false)

    const SubmitSearch = async (params: any, dateType: string, dropDowns: any, dateRange:boolean) => {
        let search_query: GetDarInfo_Query = {
            "QueryType": "O",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": dateRange,
            "DateTypeValue": (dateType === "Entry Date") ? 0 : 1,
            "FromDate": handleSearchTime(params?.fromDate, 'fromDate')?.toLocaleString(),
            "ToDate": handleSearchTime(params?.toDate, 'toDate')?.toLocaleString(),
            "SearchType": 'AND',
            "IndicatorOn": false,
            "TopArrestNumber": 0,
            "FNames": [],
            "MNames":  [],
            "LNames":  [],
            "DOBs": [],
            "SEX": [],
            "Address": "",
            "Cities": dropDowns.City ? dropDowns.City : [],
            "States": [],
            "Zip": "",
            "Statutes": [],
            "Gang": [],
            "FBIs":  [],
            "SBIs": [],
            "DLNos": [],
            "PlateNos":  [],
            "VINs": [],
            "AgencyArrested": dropDowns.Agency.AgencyArrested ? dropDowns.Agency.AgencyArrested : [],
            "InfocopDept":  [],
            "Indicators": [],
            "IndicatorOccurrence": null,
            "Latitude": null,
            "Longitude": null,
            "Radius": 0,
            "ResidentCounty": dropDowns.County.ResidentCounty ? dropDowns.County.ResidentCounty : [],
            "ArrestedCounty": [],
            "Arrest_Type":  [],
            "CaseNumbers": [],
            "FTSIDs": [],
            "SystemCall": params.systemCall ?? false,
        }
        let qRes: getDarInfoResponse = await RunDarQuery(search_query)
        setCarjackings([])
        setCarjackings(qRes.AnyTable)
        if (qRes?.AnyTable?.length === 0) {
            setNoData(true)
        }
        else {
            setNoData(false)
        }
    }
    return (
        <div id="ToolsCarJack">
            {isUserPermission('Arrest')?
                <>
                    <CarjackingSearch SubmitSearch={SubmitSearch} /> 
                    {!noData ? <SearchResults CarjackingInfo={carjackings} /> : <div className="font-bold text-lg ml-5">NO Matching Records Found</div>}
                </>
            :<></>}
        </div>
    );
}

export default CarjackingNotification;
