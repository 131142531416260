import React from 'react'
import {
    Tooltip, Alert, AlertColor, Avatar, Button, ButtonGroup, Checkbox, Snackbar,
} from "@mui/material";
import { PersonInfo } from "../../../interfaces/getDarInfo_interface";
import BTPersonReport from "../../shared/_BTPersonReport";
import {
    ColumnDirective,
    ColumnsDirective,
    GridComponent,
    Grid,
    Inject,
    Page,
    Sort,
    Filter,
    DetailRow,
    ExcelExport,
    PdfExport,
    Toolbar,
    Search, SortSettingsModel, ExcelExportProperties, Column
} from '@syncfusion/ej2-react-grids';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import {showCrumbScore, SiteName} from '../../../services/config.service';
import BTPersonGridImage from "../../shared/_PersonGridImage";
import { JAOS } from '../../../services/JAOS.service';
import NCICIcon from '../../../assets/Images/NCIC.png';
import { isUserPermission } from '../../../services/auth.service';
import BTPersonIndicatorGrid from "../../shared/_PersonIndicators";
import {getValue} from "@syncfusion/ej2-base";
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";


interface SearchResultsProps {
    Persons: PersonInfo[]
}

const SearchResults: React.FC<SearchResultsProps> = ({ Persons }) => {
    const [typedPersons, setTypedPersons] = React.useState<any[]>([])
    //Alerts
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<string>("")
    const [alertType, setAlertType] = React.useState<AlertColor>("error")
    const [ncicList, setNCICList] = React.useState<any[]>([])

    let grid: Grid | null;
    let selectedRows: any[] = [];

    const handleDataTyping = () => {
        if (Persons) {
            let tempP: any = [...Persons]
            //reset NCIClist if they search again
            setNCICList([])
            for (let p of tempP) {
                if (p.DOB) {
                    p.DOB = new Date(p.DOB)
                }
                if (p.DTOFARREST) {
                    p.DTOFARREST = new Date(p.DTOFARREST)
                }
                if (p.Start_Date) {
                    p.Start_Date = new Date(p.Start_Date)
                }
                if (p.End_Date) {
                    p.End_Date = new Date(p.End_Date)
                }
            }
            setTypedPersons(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [Persons])

    const dataBound = () => {
        if (grid) {
            grid.autoFitColumns([])
        }
    }

    const expandRow = (args: any) => {
         //expand if a row is selected except select checkbox
        //  if(args?.name === 'rowSelected' && args?.target.ariaLabel != null || args?.target.alt){
        //     if(grid){
        //         const selectedrowindex= grid.getSelectedRowIndexes()
        //         for (let idx of selectedrowindex) {
        //             grid.detailRowModule.expand(idx)
        //         }
        //     }
        // }
        let rows = [...selectedRows];
        if (args?.isHeaderCheckboxClicked){
            rows = [];
            for (let idx = 0; idx < typedPersons.length; idx++) {
                let person: any = typedPersons[idx];
                if (person && person.PersonID) {
                    rows.push(person)
                }
            }
        }
        else {
            if  (args?.data && args?.data?.length !== typedPersons.length) {
                var result = rows.findIndex(x => x.PersonID === args?.data.PersonID)
                if (result === -1) {
                    let person = args?.data;
                    if (person && person.PersonID) {
                        rows.push(person)
                    }
                }
                else {
                    rows.splice(result,1)
                }
            }
        }
        selectedRows=rows
    }

    const rowDeselect = (args: any) => {
        // if(args?.name === 'rowDeselected'){
        //     if(grid){
        //         grid.detailRowModule.collapseAll()
        //     }
        // }
        let rows = [...selectedRows];
        if(args?.isHeaderCheckboxClicked){
            rows= [];
        }
        else {
            if (args?.data && args?.data?.length !== typedPersons.length) {
                var result = rows.findIndex(x => x.TableID === args?.data.TableID && x.TableType === args?.data.TableType)

                if (result === -1) {
                    let person = args?.data;
                    if (person && person.PersonID) {
                        rows.push(person)
                    }
                }
                else {
                    rows.splice(result, 1)
                }
            }
        }
        selectedRows = rows;
    }

    const handleNCICSearch = () => {
        let tempSelectedRows = []
        for(let idx in selectedRows){
            tempSelectedRows.push({
                PersonID: selectedRows[idx].PersonID,
                TableID: selectedRows[idx].TableID,
                DLNo: selectedRows[idx].DLNo,
                FName: selectedRows[idx].FName,
                MName: selectedRows[idx].MName,
                LName: selectedRows[idx].LName,
                DOB: selectedRows[idx].DOB,
                FBI: selectedRows[idx].FBI,
                SBINo: selectedRows[idx].SBINo,
            })
        }
        if (tempSelectedRows.length > 0 && tempSelectedRows.length < 14001) {

            let l = new JAOS()
            let hashID = l.objToStack(tempSelectedRows);
            window.open('/tools/ncic_multiple_occurrence?person=' + hashID, '_blank')
        }
        else if (tempSelectedRows.length >= 14001){
            setAlertType("error");
            setAlertText("Please reduce selection size to 14000 to run NCIC search");
            setAlertOpen(true);
            grid?.clearSelection()
        }
        else {
            setAlertType("error");
            setAlertText("Please select at least one offender to run NCIC search");
            setAlertOpen(true);
        }
    }

    let sortSettings: SortSettingsModel = { columns: [
            {field: 'FName', direction: 'Ascending' }
        ] };
    

    const miniPersonImage = (person: any): any => {
        return (
            <BTPersonGridImage person={person} />
        )
    }
    const gridTemplate = (person: any): any => {
        return (
            <BTPersonIndicatorGrid person={person} />
        );
    }

    //  const selectTemplate = (person: any): any => {
    //     return (
    //         <>
    //             <Checkbox  onClick={() => { buildNCICList(person) }} />
    //         </>);
    // }

    // const buildNCICList = (person: any) =>{
    //     //Not all records have PersonID so it also checks for TableID
    //     let NCICListTemp = ncicList
    //     if (person){
    //         if(person.PersonID){
    //             var result = NCICListTemp.findIndex(x => x.PersonID === person?.PersonID)
            
    //             if (result === -1) {
    //                 NCICListTemp.push(person)
    //             }
    //             else {
    //                 NCICListTemp.splice(result, 1)
    //             }
    //         }
    //         else if (person.TableID){
    //             var result = NCICListTemp.findIndex(x => x.TableID === person?.TableID)
            
    //             if (result === -1) {
    //                 NCICListTemp.push(person)
    //             }
    //             else {
    //                 NCICListTemp.splice(result, 1)
    //             }
    //         }
    //     }
    //     setNCICList(NCICListTemp)    
    // }

    const getPersonSummary = (p: any) => {
        return (<BTPersonReport person={p} />)
    }

    const GridToolsClick = (args: ClickEventArgs) => {
        
        if (grid && args.item.text === "Excel Export") {

            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'Parole.xlsx';
            
            (grid.getColumnByField("image") as Column).visible = false;
            (grid.getColumnByField("FBI") as Column).visible = true;
            (grid.getColumnByField("SBINo") as Column).visible = true;
            (grid.getColumnByField("SSN1") as Column).visible = true;
            (grid.getColumnByField("FoundALPR") as Column).visible = true;
            (grid.getColumnByField("FoundArrest") as Column).visible = true;
            (grid.getColumnByField("FoundCADRMS") as Column).visible = true;
            (grid.getColumnByField("FoundCarTheft") as Column).visible = true;
            (grid.getColumnByField("FoundCarTheftRecent") as Column).visible = true;
            (grid.getColumnByField("FoundDomViolence") as Column).visible = true;
            (grid.getColumnByField("FoundNIBIN") as Column).visible = true;
            (grid.getColumnByField("FoundPhoto") as Column).visible = true;
            (grid.getColumnByField("FoundSexCrime") as Column).visible = true;
            (grid.getColumnByField("FoundShooting") as Column).visible = true;

            if(!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL)){
                (grid.getColumnByField("FoundCODIS") as Column).visible = true;
                (grid.getColumnByField("FoundETicket") as Column).visible = true;
                (grid.getColumnByField("FoundGunTrace") as Column).visible = true;
                (grid.getColumnByField("FoundHumanTraffic") as Column).visible = true;
                (grid.getColumnByField("FoundInfoCop") as Column).visible = true;
                (grid.getColumnByField("FoundJail") as Column).visible = true;
                (grid.getColumnByField("FoundNJGunTrace") as Column).visible = true;
                (grid.getColumnByField("FoundParkMobile") as Column).visible = true;
                (grid.getColumnByField("FoundPrisonParole") as Column).visible = true;
                (grid.getColumnByField("FoundPrisonRelease") as Column).visible = true;
                (grid.getColumnByField("FoundProfession") as Column).visible = true;
                (grid.getColumnByField("FoundProfile") as Column).visible = true;
                (grid.getColumnByField("FoundReferral") as Column).visible = true;
                (grid.getColumnByField("FoundTowing") as Column).visible = true;
                (grid.getColumnByField("FoundViolent") as Column).visible = true;
                (grid.getColumnByField("FoundNJSTAD") as Column).visible = true;
                (grid.getColumnByField("FoundNJTrace_Pos") as Column).visible = true;
                (grid.getColumnByField("FoundNJTrace_Pos") as Column).visible = true;
                (grid.getColumnByField("FoundNJTrace_Asc") as Column).visible = true;
                (grid.getColumnByField("FoundETrace_Pur") as Column).visible = true;
                (grid.getColumnByField("FoundNJTrace_Ghg") as Column).visible = true;


            }
            else if(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL){
                (grid.getColumnByField("FoundCarJacking") as Column).visible = true;
                (grid.getColumnByField("FoundGunArrests") as Column).visible = true;
            }

            grid.excelExport(excelProp);

        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
        else if (grid && args.item.text === 'Clear Selected'){
            grid.clearSelection()
            selectedRows=[]       
        }
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.getColumnByField("image") as Column).visible = true;
            (grid.getColumnByField("FBI") as Column).visible = false;
            (grid.getColumnByField("SBINo") as Column).visible = false;
            (grid.getColumnByField("SSN1") as Column).visible = false;
            (grid.getColumnByField("FoundALPR") as Column).visible = false;
            (grid.getColumnByField("FoundArrest") as Column).visible = false;
            (grid.getColumnByField("FoundCADRMS") as Column).visible = false;
            (grid.getColumnByField("FoundCarTheft") as Column).visible = false;
            (grid.getColumnByField("FoundCarTheftRecent") as Column).visible = false;
            (grid.getColumnByField("FoundDomViolence") as Column).visible = false;
            (grid.getColumnByField("FoundNIBIN") as Column).visible = false;
            (grid.getColumnByField("FoundPhoto") as Column).visible = false;
            (grid.getColumnByField("FoundSexCrime") as Column).visible = false;
            (grid.getColumnByField("FoundShooting") as Column).visible = false;

            if(!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL)){
                (grid.getColumnByField("FoundCODIS") as Column).visible = false;
                (grid.getColumnByField("FoundETicket") as Column).visible = false;
                (grid.getColumnByField("FoundGunTrace") as Column).visible = false;
                (grid.getColumnByField("FoundHumanTraffic") as Column).visible = false;
                (grid.getColumnByField("FoundInfoCop") as Column).visible = false;
                (grid.getColumnByField("FoundJail") as Column).visible = false;
                (grid.getColumnByField("FoundNJGunTrace") as Column).visible = false;
                (grid.getColumnByField("FoundParkMobile") as Column).visible = false;
                (grid.getColumnByField("FoundPrisonParole") as Column).visible = false;
                (grid.getColumnByField("FoundPrisonRelease") as Column).visible = false;
                (grid.getColumnByField("FoundProfession") as Column).visible = false;
                (grid.getColumnByField("FoundProfile") as Column).visible = false;
                (grid.getColumnByField("FoundReferral") as Column).visible = false;
                (grid.getColumnByField("FoundTowing") as Column).visible = false;
                (grid.getColumnByField("FoundViolent") as Column).visible = false;
                (grid.getColumnByField("FoundNJSTAD") as Column).visible = false;
                (grid.getColumnByField("FoundNJTrace_Pos") as Column).visible = false;
                (grid.getColumnByField("FoundNJTrace_Pos") as Column).visible = false;
                (grid.getColumnByField("FoundNJTrace_Asc") as Column).visible = false;
                (grid.getColumnByField("FoundETrace_Pur") as Column).visible = false;
                (grid.getColumnByField("FoundNJTrace_Ghg") as Column).visible = false;
            }
            else if(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL){
                (grid.getColumnByField("FoundCarJacking") as Column).visible = false;
                (grid.getColumnByField("FoundGunArrests") as Column).visible = false;
            }
        }
    }

    const toolbarOptions: object[] = [
        {text:"Excel Export" , prefixIcon:"e-excelexport", tooltipText:"Excel Export",type: "Button",}, {text:"Search"}, {text:"Clear Selected", prefixIcon: "e-collapse-2", tooltipText:"Clear Selected", type: "Button"}
    ]


    return (
        <div className={"pl-10 pr-10 mt-5"}>
            {typedPersons && typedPersons.length > 0 ? <>
                <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={alertOpen} autoHideDuration={6000} onClose={() => { setAlertOpen(false) }}>
                    <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                        {alertText}
                    </Alert>
                </Snackbar>
                <span style={{ display: "inline-block" }} className='mb-5'>
                    <h4 className={"font-bold inline mr-5 text-lg"}> {typedPersons?.length} Offender(s) Found</h4> 
                    {isUserPermission('NCIC') ?
                        <ButtonGroup size="medium" color="inherit" className='mb-2 mr-2'>
                            <Tooltip title="Search NCIC Warrant Deatils for Select Offender(s)" placement="top" arrow>
                                <Button variant="outlined" onClick={handleNCICSearch}>
                                    <img src={NCICIcon} className={"mr-2"} alt={""} />NCIC Search
                                </Button>
                            </Tooltip>
                        </ButtonGroup> : <></>
                    }
                </span>
                <GridComponent
                    dataSource={typedPersons}
                    dataBound={dataBound }
                    allowPaging={true}
                    pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{ type: 'CheckBox' }}
                    detailTemplate={getPersonSummary}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    //toolbar={['ExcelExport', 'Search']}
                    toolbar={toolbarOptions}
                    toolbarClick={GridToolsClick}
                    excelExportComplete={excelExportComplete}
                    ref={g => grid = g}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        checkboxOnly: true,
                        mode: "Row"
                    }}
                    //rowSelecting={(event: any) => { }}
                    rowSelected={(event: any) => { expandRow(event) }}
                    rowDeselected={(event: any) => { rowDeselect(event) }}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                    rowDataBound={(args : any) => {
                        if (args.row) {
                            if (getValue('FoundJail', args.data)  === 2){
                                args.row.classList.add('red');
                            }
                        }
                    }}
                >
                <ColumnsDirective>
                        <ColumnDirective field='PersonID' width={140} minWidth={140} maxWidth={140} headerText='Person ID' visible={false} isPrimaryKey={true} />
                        {isUserPermission('NCIC') ? <ColumnDirective type='checkbox' width='50' customAttributes={{ class: ['e-checkbox-custom'] }} /> : <></>}
                        {/* { isUserPermission('NCIC') ? <ColumnDirective template={selectTemplate} headerText='Select' width={100} /> : <></>} */}
                        <ColumnDirective field='image' template={miniPersonImage} maxWidth={120} headerText='Image' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='FName' headerText='First Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='MName' headerText='MI' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='LName' headerText='Last Name' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='DOB' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='DOB' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='FBI' width={110} minWidth={110} maxWidth={110} headerText='FBI' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='SBINo' width={110} minWidth={110} maxWidth={110} headerText={`${GetStringNameForSite(SiteName,'SBI #')}`} customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='SSN1' width={110} minWidth={110} maxWidth={110} headerText='SSN' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='SEX' headerText='Sex' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        { showCrumbScore ? <ColumnDirective field='CrumbScorePercentile' width={180} minWidth={180} maxWidth={180} headerText={`${GetStringNameForSite(SiteName,'SCOR_TBL')}`} customAttributes={{ class: ['e-attr'] }}/>:<></>}
                        <ColumnDirective field='City' headerText={`${GetStringNameForSite(SiteName,'CITY')}`} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='State' headerText='State' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective template={gridTemplate} headerText='Indicator' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='STATUTE' headerText={`${GetStringNameForSite(SiteName,'STATUTE')}`} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='STATUTEDESCRIPTION' headerText='Statute Description' width={240} minWidth={240} maxWidth={240} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='Start_Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Start Date' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='End_Date' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='End Date' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='STATUS' headerText='Status' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='DTOFARREST' format={{ type: 'date', format: 'MM/dd/yyyy' }} headerText='Date of Arrest' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                        <ColumnDirective field='ORIGINATINGORINAME' headerText='Agency Arrested' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}/>
                    {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) &&<ColumnDirective field='Dept' maxWidth={200} headerText='InfoCop Dept' customAttributes={{ class: ['e-attr'] }}/>}
                        <ColumnDirective field='FoundALPR' width={110} minWidth={110} maxWidth={110} headerText='Associated ALPR' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundArrest' width={110} minWidth={110} maxWidth={110} headerText='Associated Arrest' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundCADRMS' width={110} minWidth={110} maxWidth={110} headerText={`Associated ${GetStringNameForSite(SiteName,'CADRMS')}`} customAttributes={{ class: ['e-attr'] }} visible={false} />
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundCODIS' width={110} minWidth={110} maxWidth={110} headerText='Associated CODIS' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundCarJacking' width={110} minWidth={110} maxWidth={110} headerText='Associated CarJacking' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        <ColumnDirective field='FoundCarTheft' width={110} minWidth={110} maxWidth={110} headerText='Associated Car Theft' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundCarTheftRecent' width={110} minWidth={110} maxWidth={110} headerText='Associated Car Theft Recent' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundDomViolence' width={110} minWidth={110} maxWidth={110} headerText='Associated Domestic Violence' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundETicket' width={110} minWidth={110} maxWidth={110} headerText='Associated ETicket' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundGunArrests' width={110} minWidth={110} maxWidth={110} headerText='Associated Gun Arrests' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundGunTrace' width={110} minWidth={110} maxWidth={110} headerText='Associated Gun Trace' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundHumanTraffic' width={110} minWidth={110} maxWidth={110} headerText='Associated Human Traffic' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundInfoCop' width={110} minWidth={110} maxWidth={110} headerText='Associated Info Cop' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundJail' width={110} minWidth={110} maxWidth={110} headerText='Associated Jail' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        <ColumnDirective field='FoundNIBIN' width={110} minWidth={110} maxWidth={110} headerText='Associated NIBIN' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundNJGunTrace' width={110} minWidth={110} maxWidth={110} headerText='Associated NJGunTrace' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundParkMobile' width={110} minWidth={110} maxWidth={110} headerText='Associated ParkMobile' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        <ColumnDirective field='FoundPhoto' width={110} minWidth={110} maxWidth={110} headerText='Associated Photo' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundPrisonParole' width={110} minWidth={110} maxWidth={110} headerText='Associated Prison/Parole' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundPrisonRelease' width={110} minWidth={110} maxWidth={110} headerText='Associated Prison Release' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundProfession' width={110} minWidth={110} maxWidth={110} headerText='Associated Profession' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundProfile' width={110} minWidth={110} maxWidth={110} headerText='Associated Profile' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundReferral' width={110} minWidth={110} maxWidth={110} headerText='Associated Referral' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        <ColumnDirective field='FoundSexCrime' width={110} minWidth={110} maxWidth={110} headerText='Associated Sex Crime' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        <ColumnDirective field='FoundShooting' width={110} minWidth={110} maxWidth={110} headerText='Associated Shooting' customAttributes={{ class: ['e-attr'] }} visible={false} />
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundTowing' width={110} minWidth={110} maxWidth={110} headerText='Associated Towing' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundViolent' width={110} minWidth={110} maxWidth={110} headerText='Associated Violent' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundNJSTAD' width={110} minWidth={110} maxWidth={110} headerText='Associated NJSTAD' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundNJTrace_Pos' width={110} minWidth={110} maxWidth={110} headerText='Associated NJ Gun Trace Possessor' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundNJTrace_Pos' width={110} minWidth={110} maxWidth={110} headerText='Associated NJ Gun Trace Possessor' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundNJTrace_Asc' width={110} minWidth={110} maxWidth={110} headerText='Associated NJ Gun Trace Associate' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundETrace_Pur' width={110} minWidth={110} maxWidth={110} headerText='Associated Gun Trace Purchaser' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                        {!(SiteName==SITE.DCDEMO || SiteName==SITE.DCPROD || SiteName==SITE.DCLOCAL) && <ColumnDirective field='FoundNJTrace_Ghg' width={110} minWidth={110} maxWidth={110} headerText='Associated NJ Gun Trace Ghost Gun' customAttributes={{ class: ['e-attr'] }} visible={false} />}
                </ColumnsDirective>
                    <Inject services={[Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search]} />
                </GridComponent>

            </> : <></>}
        </div>
    );
};

export default SearchResults;