import React, {useEffect, useState} from "react";
import NoVehImg from "../../../assets/Images/NoVehImg.jpg";
import {Account} from "../../../interfaces/auth_interface";
import {getUser} from "../../../services/auth.service";
import { RunALPRDetailQuery, RunALPRImageQuery } from "../../../services/detail.service";
import ALPRDetail from "../../../interfaces/Detail/ALPRDetail.interface";
import {Dialog, Tooltip} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";
interface BTVehImageProps {
    lprRecord: any,
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const BTVehGridImage: React.FC<BTVehImageProps> = ({ lprRecord }) => {
    let [carImageInfo, setCarImageInfo] = useState<ALPRDetail>({})
    let [carMiniImage, setCarMiniImage] = useState<string>(NoVehImg)
    let [plateMiniImage, setPlateMiniImage] = useState<string>(NoVehImg)
    const [enlarged, setEnlarged] = React.useState<any>(NoVehImg)
    const [open, setOpen] = React.useState<boolean>(false)

    React.useEffect(()=>{
        if(lprRecord.LPRID) {
            RunALPRImageQuery(lprRecord?.LPRID, (lprRecord?.LPRServerName == "ETicketCentral" ? lprRecord?.Trim_Level : lprRecord?.LPRCityName), true).then(response => {
                if (response)
                    setCarImageInfo(response);
                if (response && response.PlateImage) {
                    setPlateMiniImage(response?.PlateImage)
                }
                if (response && response.CarImage) {
                    setCarMiniImage(response?.CarImage)
                }
            });
        }
    }, [lprRecord])

    return (<>
        {enlarged ?
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                onClose={()=>{setEnlarged(null)}}
                open={open}
                TransitionComponent={Transition}

            >
                <Tooltip title={"Click to Shrink"}>
                    <img src={enlarged} width="full" height="full" onClick={()=>{setEnlarged(null)}}/>
                </Tooltip>
            </Dialog>
            :
            <></>
        }
        <div className="grid grid-cols-2 gap-x-2" >
            <img src={plateMiniImage} style={{ "width": "120px", "height": "100px" }} alt={"Lpr Plate Image"} onClick={() => { setEnlarged(carImageInfo?.PlateImage); setOpen(true) }} />
            <img src={carMiniImage} style={{ "width": "120px", "height": "100px" }} alt={"Lpr Vehcile Image"} onClick={() => { setEnlarged(carImageInfo?.CarImage); setOpen(true) }} />
        </div>
    </>)
};

export default BTVehGridImage;