import React, {useState, useEffect} from "react";

import {TextField, Checkbox, Button, ToggleButtonGroup, ToggleButton, ButtonGroup} from "@mui/material";
import ArrestDropDowns from "../arrest/_arrestDropDowns";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import {formatInputDate} from "../../../services/formatDate.service";

interface ShootingSearchProps {
    SubmitSearch: any,
    ClearSelected:any
}

const ShootingSearch: React.FC<ShootingSearchProps> = ({ SubmitSearch, ClearSelected}) => {
    let today = new Date();
    let fromday = new Date();
    fromday.setMonth(fromday.getMonth() - 1);
    fromday.setDate(fromday.getDate() + 1);
    const [params, setParams] = React.useState({ toDate: formatInputDate(today), fromDate: formatInputDate(fromday), showDate: false, systemCall:false})
    const [dateType, setDateType] = React.useState<string>("Entry Date");
    const [andOrType, setAndOrType] = React.useState<string>("AND");
    const [dropDownParams, setDropDownParams] = React.useState<any>({});
    const [clear, setClear] = React.useState<number>(0);
    const [initialRun, setInitialRun] = React.useState<boolean>(false)

    React.useEffect(() => {
        if(!initialRun)
        {
            setInitialRun(true)
            let p = {...params}
            p.systemCall = true
            SubmitSearch(p, dateType, andOrType, dropDownParams)
        }
    }, [])

    const handleDateType = (event: React.MouseEvent<HTMLElement>, dateType: string) => {
        if (dateType) {
            setDateType(dateType);
        }
    };

    const HandleDropDowns = (dropDowns: any) => {
        setDropDownParams(dropDowns)
    }

    const handleClear = () => {
        setParams({ ...params, toDate: formatInputDate(today), fromDate: formatInputDate(fromday), showDate: false, systemCall:false})
        setDateType("Entry Date")
        setAndOrType("AND")
        setClear(clear + 1)
        ClearSelected(clear + 1)
    }

    const handleSearch = () => {
        let dropDownParam = {...dropDownParams};
        dropDownParam.City = Object?.keys(dropDownParam.City)?.filter((key) => !key?.includes('BT_UNIQUE_GROUP'))
        SubmitSearch(params, dateType, andOrType, dropDownParam)
    }

    return (
        <div className="bg-gray-100 ml-5 mr-5 rounded p-5">
            <div className="grid grid-cols-6 gap-x-4 gap-y-4" >
                <div className="col-span-1">
                    <ToggleButtonGroup
                        color="primary"
                        value={dateType}
                        exclusive
                        size="small"
                        fullWidth
                        onChange={handleDateType}
                    >
                        <ToggleButton value="Shooting Date" sx={{ pr: 2, pl: 2}} >Shooting Date</ToggleButton>
                        <ToggleButton value="Entry Date" sx={{ pr: 2, pl: 2 }} >Entry Date</ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div >
                    <TextField variant="outlined" margin='none' type={"date"} fullWidth
                               size={"small"} label="From Date"
                               InputLabelProps={{shrink: true}}
                               inputProps={{style: {textAlign: 'center'}}}
                               value={params.fromDate}
                               onChange={(e: any) => {
                                   setParams({...params, fromDate: e.target.value})
                               }}
                    />
                </div>
                <div >
                    <TextField variant="outlined" margin='none' type={"date"} fullWidth
                               size={"small"} label="To Date"
                               InputLabelProps={{shrink: true}}
                               inputProps={{style: {textAlign: 'center'}}}
                               value={params.toDate}
                               onChange={(e: any) => {
                                   setParams({...params, toDate: e.target.value})
                               }}
                    />
                </div>                
            </div>
            <div className="grid grid-cols-1 gap-0.5 gap-y-2" style={{ "marginTop": "25px" }}>
                <div><ArrestDropDowns HandleDropDowns={HandleDropDowns} clear={clear} tool={"shooting"} /></div>
            </div>
            <div className={"pt-3"} style={{"display":"flex", "justifyContent":"end"}}>
                <ButtonGroup size="large" color="inherit" >
                    <Button  type="submit" onClick={handleSearch}>Search</Button>
                    <Button  onClick={handleClear}>CLEAR</Button>
                </ButtonGroup>
            </div>
        </div>
    );
}

export default ShootingSearch;
