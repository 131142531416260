import React from 'react'
import {
    Alert,
    Button, ButtonGroup, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Menu, MenuItem, Snackbar
} from "@mui/material";
import {
    Column,
    ColumnDirective,
    ColumnMenu,
    ColumnsDirective,
    DetailRow, ExcelExport, ExcelExportProperties,
    Filter, Grid, GridComponent, InfiniteScroll,
    Inject,
    Page, PdfExport, Reorder, Resize, Search,
    Sort, SortSettingsModel, Toolbar
} from "@syncfusion/ej2-react-grids";
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import Marquee from "react-fast-marquee";
import MapIcon from "@mui/icons-material/Map";
import GeoCode from "../../shared/GeoCodes/_GeoCode_Display";
import {getValue} from "@syncfusion/ej2-base";
import BTVehGridImage from "./_VehImg";
import NJSnapIcon from '../../../assets/Images/NJSnapIcon.png';
import { JAOS } from '../../../services/JAOS.service';
import StartIcon from '@mui/icons-material/Start';
import {SiteName} from "../../../services/config.service";
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";

interface SearchResultsProps {
    VINInfo: any
    Errors: any[]
    NextPage: any
}

const SearchResults: React.FC<SearchResultsProps> = ({ VINInfo , Errors, NextPage}) => {
    const [allData, setAllData] = React.useState<any[]>([]);
    const [errorList, setErrorList] = React.useState<string>("");
    const [recordCount, setRecordCount] = React.useState<any>("");
    const [showGeoCode, setShowGeoCode] = React.useState(false)
    const [paginationEnd, setPaginationEnd] = React.useState(false)
    const [allPins, setAllPins] = React.useState<any[]>([])
    const [alertOpen, setAlertOpen] = React.useState<boolean>(false)
    const [alertText, setAlertText] = React.useState<String>('')
    const [alertType, setAlertType] = React.useState<any>("success")

    
    
    React.useEffect(() => {
        buildErrorList()
    },[Errors])
    
    const buildErrorList = async () => {
        setErrorList("")
        let p = ""
        if(Errors?.length > 0) {
            for (const [key, value] of Object.entries(Errors[0])) {
                if(key === "Record"){
                    setRecordCount(value)
                }
                else
                {
                    p = p + " | " + key + " : " + value
                }
            }
            setErrorList(p)
        }
    }
    
    const openGeoCode = () => {
        let pins:any[] = []
        if(grid){
            for (let p of allData)
            {
                if(p.LPRLocation && p?.LPRLocation?.length > 0) {
                    let LatLong = p.LPRLocation.split(',')
                    if (LatLong.length > 0) {
                        if (p.POIReason?.toLowerCase() == 'stolen vehicle' || p.POIReason?.toLowerCase() == 'stolen plate' || p?.POIReason?.toLowerCase() == 'felony stolen vehicle')
                            pins.push({ 
                                lat: LatLong[0],
                                lng: LatLong[1],
                                plate: p.PlateNum,
                                date: p.LPRTimeStamp,
                                alprSource: p.LPRCameraName, tool: "stolenCar" })
                        else
                        pins.push({
                            lat: LatLong[0],
                            lng: LatLong[1],
                            plate: p.PlateNum,
                            date: p.LPRTimeStamp,
                            alprSource: p.LPRCameraName,
                            tool: "car"
                        })
                    }
                }
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
    }

    const HandleGeoCodeClose = () => {
        setShowGeoCode(false)
    }
    
    let grid: Grid | null;
    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            (grid.getColumnByField("LPRID") as Column).visible = false;
            (grid.getColumnByField("image") as Column).visible = false;

            let excelProp:ExcelExportProperties = {}
            excelProp.fileName = 'ALPRVin.xlsx'
            grid.excelExport(excelProp);

        }else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const excelExportComplete = () => {
        if (grid) {
            (grid.getColumnByField("LPRID") as Column).visible = true;
            (grid.getColumnByField("image") as Column).visible = true;

        }
    }

    const handleDataTyping = () => {
        if(VINInfo) {
            let temp: any = {...VINInfo}
            setPaginationEnd(temp.PaginationEnd ?? true) //if paginationEnd is null will default to not trying again
            if(temp.JsonObject) {
                let tempP = JSON.parse(temp?.JsonObject)
                for (let p of tempP) {
                    if (p.LPRTimeStamp) {
                        p.LPRTimeStamp = new Date(p.LPRTimeStamp)
                    }
                }
                setAllData(tempP)
                if (grid) {
                    grid.dataSource = tempP
                    grid?.refresh()
                }
            }
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [VINInfo])
    
    const dataBound = () => {
        if(grid){
            grid.autoFitColumns([])
        }
    }
    
    const checkStolen = () => {
        return true
    }

    const viewTemplate = (item: any): any => {

        return (
            <span>
                {(item?.LPRID && item?.LPRID != 0 ? <Button onClick={() => { window.open((item?.LPRServerName == "ETicketCentral" ? "/detail/ETicket" : item?.LPRServerName == "ParkMobileCentral" ? "/detail/ParkMobile" : "/detail/ALPR") + '?ID=' + item?.LPRID + '&Dept=' + (item?.LPRServerName == "ETicketCentral" ? item?.Trim_Level : item?.LPRCityName), '_blank') }} >View</Button> : <div></div>)}
            </span>
        );
    }

    const rowDataBound = (data:any) => {
        if (data.row) {
            if( getValue('POIReason', data.data) && (getValue('POIReason', data.data).length > 0) ){
                data.row.classList.add('redText');
            }
        }
    }

    const miniVehImage = (lprRecord: any): any => {
        return (
            <BTVehGridImage lprRecord={lprRecord} />
        )
    }
    
    const handlePaging = (event:any) => {
        if(event.requestType === 'paging'){
            let currentPage = event.currentPage
            let pageSize = grid?.pageSettings.pageSize ?? 1
            let totalRecordCount = grid?.pageSettings.totalRecordsCount ?? 1
            let lastPage = totalRecordCount / pageSize
            
            if(currentPage === lastPage && grid && !paginationEnd){
                let temp:any = grid?.dataSource
                let tGrid = grid
                NextPage().then((res:any) => {
                    if(res.JsonObject) {
                        setPaginationEnd(res.PaginationEnd ?? true)
                        let tempP = JSON.parse(res?.JsonObject)
                        for (let p of tempP) {
                            if (p.LPRTimeStamp) {
                                p.LPRTimeStamp = new Date(p.LPRTimeStamp)
                            }
                        }
                        let newList = temp.concat(tempP)
                        setAllData(newList)
                        if (tGrid) {
                            tGrid.dataSource = newList
                            tGrid.pageSettings.currentPage = lastPage
                        }
                    }
                })
            }
        }
    }
    
    const callNextPage = () => {
        let tGrid = grid
        
        if(tGrid) {
            let currentPage = tGrid.pageSettings.currentPage
            let temp: any = tGrid?.dataSource
            
            NextPage().then((res: any) => {
                if (res.JsonObject) {
                    setPaginationEnd(res.PaginationEnd ?? true)
                    let tempP = JSON.parse(res?.JsonObject)
                    for (let p of tempP) {
                        if (p.LPRTimeStamp) {
                            p.LPRTimeStamp = new Date(p.LPRTimeStamp)
                        }
                    }
                    let newList = temp.concat(tempP)
                    setAllData(newList)
                    if(tGrid) {
                        tGrid.dataSource = newList
                        tGrid.refresh()
                        tGrid.pageSettings.currentPage = currentPage
                    }
                
                }
            })
        }
    }
    const createReport = async () =>{
        //check that we got a result from the search for the same plate number
        //create string of ids returned
        let maxRecords = 500

        let flag = false
        let temp = allData.map(x => x.LPRID)
        if (temp.length > maxRecords) {
            temp = temp.slice(0, maxRecords);            
            flag = true
        }

        let idListStr = temp.join(',')
        if (idListStr.trim().length > 0) {
            let l = new JAOS()
            let hashID = l.objToStack(idListStr);
            let apiCall = "/detail/ALPRPDFReport" + '?ID=' + hashID + '&Dept='
            if (flag === true)
                apiCall += '&maxRecords=' + maxRecords
            window.open(apiCall, '_blank')
        }
    }

    return (
        <div className={"pl-10 pr-10 mt-5"}> 
            <Snackbar open={alertOpen} autoHideDuration={7000} onClose={() => { setAlertOpen(false) }}>
                        <Alert onClose={() => { setAlertOpen(false) }} severity={alertType} sx={{ width: '100%' }}>
                            {alertText}
                        </Alert>
            </Snackbar>       
            {Errors.length > 0 && errorList.length > 0 ?
                <Marquee speed = {20} pauseOnHover={true}>
                   <span className = "font-bold text-red-700">{errorList?.length > 0 ? errorList : ""}</span>
                </Marquee>
            : <></>}
            {VINInfo ?
                <span  style ={{display:"inline-block"}} className='mb-5'>
                    <div className={"font-bold inline mr-3"}>
                        {allData.length > 0 ? allData.length + (!paginationEnd ? "+" : "") : 'NO'} Records Found
                    </div>
                    
                    {allData.length > 0 ? 
                    <span>
                        <ButtonGroup size="medium" color="inherit" >
                             <Button variant="outlined" onClick={callNextPage} disabled={paginationEnd}>
                                <b>Next 1000</b><StartIcon color="inherit" className ="ml-2"/>
                             </Button>  
                             <Button variant="outlined" onClick={openGeoCode}>
                                <MapIcon color="success" className ="mr-2"/><b>Map</b>
                             </Button> 
                             <Button variant="outlined" onClick={createReport}>
                                    {(SiteName === SITE.NJPROD || SiteName === SITE.NJDEMO || SiteName === SITE.NJUAT) && <img src={NJSnapIcon} className={"mr-2"} alt='' />}<b>Create Report</b>
                             </Button>                                 
                        </ButtonGroup>
                        {(showGeoCode) ? <div><GeoCode HandleGeoCodeClose={HandleGeoCodeClose} show={showGeoCode} pins={allPins} mapVersion={'VINcar'} exportData={allData}/></div>:<></>}                       
                    </span>                 
                    : <></>}                                   
                </span>
                : <></>}
            {VINInfo  ? 
            <GridComponent
                dataSource={[]}
                dataBound={dataBound}
                rowDataBound={rowDataBound}
                allowPaging={true}
                pageSettings={{pageSize: 25, pageSizes: [10,25,50,100]}}
                allowSorting={true}
                allowMultiSorting={true}
                allowFiltering={true}
                filterSettings={{type: 'CheckBox'}}
                allowTextWrap={true}
                textWrapSettings={{ wrapMode: 'Both' }}
                allowExcelExport={true}
                allowPdfExport={true}
                toolbar={['ExcelExport','Search']}
                toolbarClick={GridToolsClick}
                ref={(g) => { if (g) { grid = g } }}
                selectionSettings={{
                    persistSelection: true,
                    type: "Multiple",
                    //checkboxOnly: true,
                    mode: "Row"}}
                allowSelection={true}
                allowResizing={true}
                rowHeight={30}
                excelExportComplete={excelExportComplete}
                style={{color:'red'}}
                actionBegin={(e:any) => {handlePaging(e)}}
            >
                <ColumnsDirective>
                    <ColumnDirective field='LPRID' template={viewTemplate} headerText='View' />
                    <ColumnDirective field='image' template={miniVehImage} minWidth={120} maxWidth={240}  headerText='Image' customAttributes={{ class: ['e-attr'] }}/>
                    <ColumnDirective field='LPRTimeStamp'   headerText='Time Stamp'  format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm:ss' }} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr']}} />
                    <ColumnDirective field='LPRCameraName'  headerText='Zone/ Camera'    width={150} minWidth={150} maxWidth={150} customAttributes={{ class: ['e-attr'] }} />
                    <ColumnDirective field='PlateNum'       headerText='Plate #'        width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='PlateState'     headerText='Plate State'    width={115} minWidth={115} maxWidth={115} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='LPRCityName'    headerText={`${GetStringNameForSite(SiteName,'CITY')}`}           width={150} minWidth={150} maxWidth={150} customAttributes={{ class: ['e-attr'] }}  />
                    <ColumnDirective field='LPRLocation'    headerText='ALPR Location'  width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}  />
                    <ColumnDirective field='VendorName'     headerText='Vendor Name'    width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }}  />
                    <ColumnDirective field='PlateYear'      headerText='Plate Year'     width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='VehMake'        headerText='Dmv Make'       width={115} minWidth={115} maxWidth={115} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='VehModel'       headerText='Dmv Model'      width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='VehBody'        headerText='Dmv Trim'       width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='VehColor'       headerText='Dmv Color'      width={115} minWidth={115} maxWidth={115} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='VIN'            headerText='Vin'            width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='Model_Year'     headerText='Vin Year'       width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='Make'           headerText='Vin Make'       width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='Model'          headerText='Vin Model'      width={130} minWidth={130} maxWidth={130} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='Trim_Level'     headerText='Trim/ID'        width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='Body_Style'     headerText='Body_Style'     width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='Exterior_Color' headerText='Vin Color'      width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr']}}   />
                    <ColumnDirective field='POIReason'      headerText='POI Purpose'     width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr']}}   />

                </ColumnsDirective>
                    <Inject services={[ColumnMenu, Sort, Page, Filter, Toolbar, ExcelExport, PdfExport, Search, Resize, Reorder]} />
            </GridComponent>
                : <></>}
        </div>
    );
    {/*<ColumnDirective template={(item: any) => (<>({item.LPRLocation})</>)} headerText='MSP (Latitude, Longitude)' visible={false} />*/ }
    {/*<ColumnDirective template={(item: any) => (<Button onClick={() => { window.open('/detail/ALPR?ID=' + item?.LPRID + '&Dept=' + item?.LPRServerName, '_blank') }} >View</Button>)} headerText='View' />*/ }

};

export default SearchResults