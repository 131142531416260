import React, {useState} from 'react'
import {Button, ButtonGroup, Tooltip} from "@mui/material";
import VehicleIcon from "../../../assets/Images/Vehicle.png";
import PersonIcon from "../../../assets/Images/Person.png";
import BTPersonReport from "../../shared/_BTPersonReport";
import {
    ColumnDirective,
    ColumnsDirective,
    DetailRow,
    ExcelExport,
    ExcelExportProperties,
    Filter,
    Grid,
    GridComponent,
    Inject,
    Page,
    PdfExport,
    Search,
    Sort,
    SortSettingsModel,
    Toolbar
} from '@syncfusion/ej2-react-grids';
import {ClickEventArgs} from '@syncfusion/ej2-navigations';
import GeoCode from "../../shared/GeoCodes/_GeoCode_Display";
import Checkbox from "@mui/material/Checkbox";
import {
    ALPRMultipleOccurenceQuery,
    GetDarInfo_Query,
    getDarInfoResponse
} from "../../../interfaces/getDarInfo_interface";
import {Account} from "../../../interfaces/auth_interface";
import {getDeviceUser, getUser, isUserPermission} from "../../../services/auth.service";
import {RunDarQuery, RunMultipleOccurrence} from "../../../services/getDar.service";
import MapIcon from "@mui/icons-material/Map";
import {JAOS} from "../../../services/JAOS.service";
import {showCrumbScore, SiteName} from '../../../services/config.service';
import {GetDeviceToken} from "../../../services/detail.service";
import AuthenticatedAPIReqSplashDL from "../../../services/AuthenticatedAPIReqSplashDeviceLocation.service";
import DeviceIcon from "../../../assets/Images/DeviceSmall.png";
import {formatDT} from "../../../services/formatDate.service";
import BTPersonGridImage from "../../shared/_PersonGridImage";
import BTPersonIndicatorGrid from "../../shared/_PersonIndicators";
import {getValue} from "@syncfusion/ej2-base";
import {GetStringNameForSite, SITE} from "../../../interfaces/config.interface";

interface SearchResultsProps {
    MissingPersonReports: any[],
    Persons: any[],
}

const SearchResults: React.FC<SearchResultsProps> = ({ MissingPersonReports, Persons }) => {
    const [typedPersons, setTypedPersons] = React.useState<any[]>([])
    const [showGeoCode, setShowGeoCode] = React.useState(false)
    const [allPins, setAllPins] = React.useState<any[]>([])
    let [user, setUser] = useState<Account>(getUser())
    const [alprList, setALPRList] = useState<any[]>([])
    const [mapVersion, setMapVersion] = useState<string>("2")
    const [exportData, setExportData] = useState<any>({})

    const handleDataTyping = () => {
        if (MissingPersonReports) {
            let tempP: any = [...MissingPersonReports]

            for (let p of tempP) {
                if (p.Start_Date) {
                    p.Start_Date = new Date(p.Start_Date)
                }
            }
            setTypedPersons(tempP)
        }
    }

    React.useEffect(() => {
        handleDataTyping()
    }, [MissingPersonReports])

    let grid: Grid | null;
    const miniPersonImage = (person: any): any => {
        return (
            <BTPersonGridImage person={person} />
        )
    }
    const gridTemplate = (person: any): any => {
        return (
            <BTPersonIndicatorGrid person={person} />
        );
    }

    const selectTemplate = (person: any): any => {
        return (
            <>
                <Checkbox onClick={() => { buildALPRList(person) }} />
            </>);
    }
    const NearByTemplate = (person: any): any => {
        return (
            <span>
                <Tooltip title={'Person: 0.25 Mile Range and Last 1 Year'} arrow><Button onClick={() => { handlePersonGeo(person) }}><img src={PersonIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /></Button></Tooltip>
                <Tooltip title={'Vehicle: 16 minutes & 1.5 Mile Range'} arrow><Button onClick={() => { handleCarGeo(person) }}><img src={VehicleIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /></Button></Tooltip>
                {isUserPermission('DeviceID') && checkDeviceDate(person) && (SiteName == SITE.NJPROD || SiteName == SITE.NJUAT || SiteName == SITE.NJDEMO || SiteName == SITE.LOCAL) ? <Tooltip title={'Device: 16 minutes & 3000 Feet Range'} arrow><Button onClick={() => { handleDeviceGeo(person) }}><img src={DeviceIcon} style={{ "display": "inline", "marginRight": "3px" }} alt={""} /></Button></Tooltip> : <></>}
            </span>);
    }

    const checkDeviceDate = (person: any) => {
        let today: Date = new Date();
        let deviceDate = new Date(today.setDate(today.getDate() - 3));
        deviceDate.setHours(0, 0, 0)
        return Date.parse(deviceDate.toString()) > Date.parse(person.DateTimeIN);
    }

    const handleDeviceGeo = async (person: any) => {
        setExportData({})

        if (!localStorage.getItem('DLUser')) { //dont have a token? go get one.
            let res: any = await GetDeviceToken()
            if (res) {
                localStorage.setItem('DLUser', res)
            }
        }

        let DLUser: Account = getDeviceUser();
        let today = new Date();

        if (DLUser.exp && DLUser.exp < (today.getTime() / 1000)) {
            let res: any = await GetDeviceToken()
            if (res) {
                localStorage.setItem('DLUser', res)
            }
        }

        let date = person.DateTimeIN
        let milliseconds = Date.parse(date.toString())
        let start = new Date(milliseconds - (8 * 60 * 1000))
        let end = new Date(milliseconds + (8 * 60 * 1000))

        let search_query: any = {
            "DeviceId": [],
            "DeviceType": [],
            "Inc_DateTime": [formatDT(start)],  //User local timestamp
            "Inc_DateTimeEnd": [formatDT(end)],
            "Inc_Latitude": [person.Incident_Lat],
            "Inc_Longitude": [person.Incident_Lon],
            "Radius": [3000],
            "ExactLocation": ['Y'],
        }

        let DLT = localStorage.getItem("DLUser")

        if (DLT && DLT.length > 0) {
            let r: any = await AuthenticatedAPIReqSplashDL.post('/DeviceLocation/SearchNotification', search_query)
            if (r && r.data && r.data.length > 0) {
                let obj = {
                    shooting: [person],
                    devices: r.data
                }

                setExportData(r.data)
                deviceGeoCode(obj)
            }
        }
    }

    const handleCarGeo = async (caseInfo: any) => {
        setExportData({})
        let search_query: ALPRMultipleOccurenceQuery = {
            "incidents": [
                {
                    date: caseInfo.DateTimeIN.toLocaleString(),
                    threshold: 8,
                    radius: 1.5, //this needs to move to 1 on prod ***
                    location: {
                        address: caseInfo.Address2,
                        city: caseInfo.City2,
                        state: caseInfo.ST2,
                        zip: "",
                        latitude: caseInfo.Incident_Lat,
                        longitude: caseInfo.Incident_Lon,
                        addressType: ""
                    },
                    showEdit: true,
                    alprServerDB: "",
                    alprServerName: ""
                }
            ],
            minOccurrence: 0,
            lookFor: "Car",
            plate: {
                plateNumber: "",
                plateSt: ""
            },
            queryType: "M",
            alprReason: "Suspicious Incident",
            alprCaseNumber: "Automated",
            UTCDate: false,
        }
        let res: any = await RunMultipleOccurrence(search_query)
        let parsed = JSON.parse(res?.JsonObject)
        if (parsed.MultipleOccurrenceList.length > 0) {
            let obj = {
                caseInfo: [caseInfo],
                cars: parsed.MultipleOccurrenceList
            }
            setExportData(parsed.MultipleOccurrenceList)
            carGeoCode(obj)
        }
    }

    const handlePersonGeo = async (caseInfo: any) => {
        setExportData({})
        let fromDate = new Date(caseInfo.DateTimeIN)
        fromDate.setFullYear(fromDate.getFullYear() - 1)

        let search_query: GetDarInfo_Query = {
            "QueryType": "2",
            "Dept": user.Dept,
            "EmpID": user.EmpID,
            "DeptORI": user.DeptORI,
            "DateRange": true,
            "DateTypeValue": 1,
            "FromDate": fromDate.toISOString().substring(0, 10),
            "ToDate": caseInfo.DateTimeIN,
            "SearchType": "AND",
            "IndicatorOn": true, //why?
            "TopArrestNumber": 0,
            "FNames": [],
            "MNames": [],
            "LNames": [],
            "DOBs": [],
            "SEX": [],
            "Address": "",
            "Cities": [],
            "States": [],
            "Zip": "",
            "Statutes": [],
            "Gang": [],
            "FBIs": [],
            "SBIs": [],
            "DLNos": [],
            "PlateNos": [],
            "VINs": [],
            "AgencyArrested": [],
            "InfocopDept": [],
            "Indicators": [],
            "IndicatorOccurrence": null,
            "Latitude": caseInfo.Incident_Lat,
            "Longitude": caseInfo.Incident_Lon,
            "Radius": 0.25,
            "ResidentCounty": [],
            "ArrestedCounty": [],
            "Arrest_Type": [],
            "CaseNumbers": [],
            "FTSIDs": [],
        }
        let qRes: getDarInfoResponse = await RunDarQuery(search_query)
        if (qRes.PersonList?.length > 0) {
            let obj = {
                caseInfo: [caseInfo],
                people: qRes.PersonList
            }
            setExportData(qRes.PersonList)
            personGeoCode(obj)
        }
    }

    const deviceGeoCode = (deviceGeo: any) => {
        setMapVersion("device")
        let pins: any[] = []
        for (let p of deviceGeo.shooting) {
            if (p.Incident_Lat && p.Incident_Lon) {
                pins.push({ lat: p.Incident_Lat, lng: p.Incident_Lon, date: p.DateTimeIN, dept: p.Dept, victims: p.NoOfVictims, suspect: p.SuspectDesc, inc_type: p.Inc_Type, tool: "shooting" })
            }
        }
        for (let p of deviceGeo.devices) {
            if (p.Latitude && p.Longitude) {
                pins.push({ lat: p.Latitude, lng: p.Longitude, device: p.DeviceID, date: p.EventDateTimeUTC, distance: p.Distance, accuracy: p.HorizontalAccuracy, tool: "device" })
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
    }

    const buildALPRList = (caseInfo: any) => {
        let ALPRListTemp = alprList
        var result = ALPRListTemp.findIndex(x => x.AgencyCaseNo === caseInfo.AgencyCaseNo)

        if (result === -1) {
            ALPRListTemp.push(caseInfo)
        }
        else {
            ALPRListTemp.splice(result, 1)
        }

        setALPRList(ALPRListTemp)
    }

    const handleALPRListSend = () => {
        if (alprList.length < 1) {
            window.alert("Please Select At Least One Incident To Compare (Check Lat & Long)")
        }
        else {
            let l = new JAOS()
            let hashID = l.objToStack(alprList)
            window.open('/tools/alpr_multiple_occurrence?ALPRList=' + hashID, '_blank')
        }
    }

    const getPersonSummary = (p: any) => {
        return (<BTPersonReport person={p} />)
    }

    const GridToolsClick = (args: ClickEventArgs) => {
        if (grid && args.item.text === "Excel Export") {
            let excelProp: ExcelExportProperties = {}
            excelProp.fileName = 'OpenCase.xlsx'
            grid.excelExport(excelProp);
        } else if (grid && args.item.text === 'PDF Export') {
            grid.pdfExport();
        }
    }

    const personGeoCode = (peopleGeo: any) => {
        setMapVersion("person")
        let pins: any[] = []
        for (let p of peopleGeo.caseInfo) {
            if (p.Incident_Lat && p.Incident_Lon) {
                pins.push({ lat: p.Incident_Lat, lng: p.Incident_Lon, date: p.DateTimeIN, offense: p.AgencyCaseNo, tool: "case" })
            }
        }
        for (let p of peopleGeo.people) {
            if (p.Incident_Latitude && p.Incident_Longitude) {
                pins.push({ lat: p.Incident_Latitude, lng: p.Incident_Longitude, name: p.FName + ' ' + p.MName + ' ' + p.LName, date: p.DTOFARREST, statute: p.STATUTE, tool: "person" })
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
    }

    const carGeoCode = (carGeo: any) => {
        setMapVersion("car")
        let pins: any[] = []
        for (let p of carGeo.caseInfo) {
            if (p.Incident_Lat && p.Incident_Lon) {
                pins.push({ lat: p.Incident_Lat, lng: p.Incident_Lon, date: p.DateTimeIN, offense: p.AgencyCaseNo, tool: "case" })
            }
        }
        for (let p of carGeo.cars) {
            if (p.Latitude && p.Longitude) {
                if (p.POIReason?.toLowerCase() == 'stolen vehicle' || p.POIReason?.toLowerCase() == 'stolen plate' || p?.POIReason?.toLowerCase() == 'felony stolen vehicle')
                    pins.push({ lat: p.Latitude, lng: p.Longitude, plate: p.red_vrm, date: p.red_TimeStamp, alprSource: p.src_Name, tool: "stolenCar" })
                else
                    pins.push({ lat: p.Latitude, lng: p.Longitude, plate: p.red_vrm, date: p.red_TimeStamp, alprSource: p.src_Name, tool: "car" })
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
    }

    const openGeoCode = () => {
        setMapVersion("openCase")
        setAllPins([])
        let pins: any[] = []
        for (let p of MissingPersonReports) {
            if (p.Incident_Lat && p.Incident_Lon) {
                pins.push({ lat: p.Incident_Lat, lng: p.Incident_Lon, date: p.DateTimeIN, offense: p.AgencyCaseNo, inc_type: p.NIBRSDesc, tool: "openCase" })
            }
        }
        setAllPins(pins)
        setShowGeoCode(true)
    }

    const HandleGeoCodeClose = () => {
        setShowGeoCode(false)
    }

    const handleDataTypingPerson = (persons: any) => {
        if (Persons) {
            let tempP: any = [...persons]

            for (let p of tempP) {
                if (p.DOB) {
                    p.DOB = new Date(p.DOB)
                }
                if (p.DTOFARREST) {
                    p.DTOFARREST = new Date(p.DTOFARREST)
                }
            }
            return tempP
        }
    }

    //this can be put into a new component
    const casePersonInformation = (missing_person: any) => {
        let temp = handleDataTypingPerson(Persons?.filter((x) => x.AgencyCaseNo === missing_person.AgencyCaseNo))
        let personGrid: Grid | null;


        const personExpandRow = () => {
            if (personGrid) {
                const selectedrowindex: number[] = personGrid.getSelectedRowIndexes()
                for (let idx of selectedrowindex) {
                    personGrid.detailRowModule.expand(idx)
                }
            }
        }

        return (
            <div className={"pl-10 pr-10 mt-5 border-2 border-gray-100 pb-5 pt-5"}>
                <span style={{ display: "inline-block", width: "full" }}>
                    Missing Person Information <img className={"inline ml-2"} src={PersonIcon} alt="ai" />
                    {(temp && temp.length > 0) ? <h4 className={"font-bold pl-5"}> {temp.length} Records Found</h4> : <h4 className={"font-bold pl-5"}></h4>}
                </span>
                <GridComponent
                    dataSource={temp}
                    allowPaging={true}
                    pageSettings={{ pageSize: 50, pageSizes: [10, 25, 50, 100] }}
                    allowSorting={true}
                    allowMultiSorting={true}
                    detailTemplate={getPersonSummary}
                    allowFiltering={true}
                    filterSettings={{ type: 'CheckBox' }}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"
                    }}
                    //rowSelecting={(event: any) => { console.log(event) }}
                    rowSelected={(event: any) => { personExpandRow() }}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                    ref={g => personGrid = g}
                    rowDataBound={(args : any) => {
                        if (args.row) {
                            if (getValue('FoundJail', args.data)  === 2){
                                args.row.classList.add('red');
                            }
                        }
                    }}
                >
                    <ColumnsDirective>
                        <ColumnDirective field='image' template={miniPersonImage} maxWidth={120} headerText='Image' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='FName' width={140} minWidth={140} maxWidth={140} headerText='First Name' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='MName' width={110} minWidth={110} maxWidth={110} headerText='MI' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='LName' width={140} minWidth={140} maxWidth={140} headerText='Last Name' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='DOB' width={115} minWidth={115} maxWidth={115} headerText='DOB' format={{ type: 'date', format: 'MM/dd/yyyy' }}
                            customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='SEX' width={110} minWidth={110} maxWidth={110} headerText='Sex' customAttributes={{ class: ['e-attr'] }} />
                        {showCrumbScore ? <ColumnDirective field='CrumbScorePercentile' width={180} minWidth={180} maxWidth={180} headerText={`${GetStringNameForSite(SiteName,'SCOR_TBL')}`} customAttributes={{ class: ['e-attr'] }} /> : <></>}
                        <ColumnDirective field='City' width={140} minWidth={140} maxWidth={140} headerText={`${GetStringNameForSite(SiteName,'CITY')}`} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='State' width={110} minWidth={110} maxWidth={110} headerText='State' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='RoleDesc' width={110} minWidth={110} maxWidth={110} headerText='Role' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective template={gridTemplate} maxWidth={300} headerText='Indicator' customAttributes={{ class: ['e-attr'] }} />
                        {(SiteName == SITE.NJPROD || SiteName == SITE.NJUAT || SiteName == SITE.NJDEMO || SiteName == SITE.LOCAL) &&<ColumnDirective field='STATUTE' width={140} minWidth={140} maxWidth={140} headerText={`${GetStringNameForSite(SiteName,'STATUTE')}`} customAttributes={{ class: ['e-attr'] }} />}
                        {(SiteName == SITE.NJPROD || SiteName == SITE.NJUAT || SiteName == SITE.NJDEMO || SiteName == SITE.LOCAL) &&<ColumnDirective field='STATUTEDESCRIPTION' maxWidth={250} headerText='Description' customAttributes={{ class: ['e-attr'] }} />}
                        <ColumnDirective field='DTOFARREST' width={140} minWidth={140} maxWidth={140} headerText='Arrest Date' format={{ type: 'date', format: 'MM/dd/yyyy' }}
                            customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='ORIGINATINGORINAME' maxWidth={200} headerText='Agency Arrested' customAttributes={{ class: ['e-attr'] }} />
                        {(SiteName == SITE.NJPROD || SiteName == SITE.NJUAT || SiteName == SITE.NJDEMO || SiteName == SITE.LOCAL) && <ColumnDirective field='Dept' maxWidth={200} headerText='InfoCop Dept' customAttributes={{ class: ['e-attr'] }} /> }
                    </ColumnsDirective>
                    <Inject services={[Sort, Page, Filter]} />
                </GridComponent>
            </div>
        )
    }

    const expandRow = (event:any) => {
        if (grid) {
            const selectedrowindex: number[] = grid.getSelectedRowIndexes()
            for (let idx of selectedrowindex) {
                //grid.detailRowModule.collapseAll()
                grid.detailRowModule.expand(idx)
            }
        }
    }

    let sortSettings: SortSettingsModel = {
        columns: [
            { field: 'Start_Date', direction: 'Descending' }
        ]
    };

    return (
        <div className={"pl-10 pr-10 mt-5"}>
            {typedPersons && typedPersons.length > 0 ? <>
                <span style={{ display: "inline-block" }} className='mb-5'>
                    <h4 className={"font-bold inline mr-5 text-lg"}> {typedPersons?.length} Records Found</h4>
                    {/*<ButtonGroup size="medium" color="inherit" >
                        <Button variant="outlined" onClick={openGeoCode}>
                            <MapIcon color="success" className="mr-2" /><b>Map</b>
                        </Button>
                        <Button variant="outlined" onClick={handleALPRListSend}>
                            <img src={VehicleIcon} className={"mr-2"} alt={""} />Vehicle Connection
                        </Button>
                    </ButtonGroup>*/}
                    {(showGeoCode) ? <div><GeoCode HandleGeoCodeClose={HandleGeoCodeClose} show={showGeoCode} pins={allPins} mapVersion={mapVersion} exportData={exportData} /></div> : <></>}
                </span>
                <GridComponent
                    dataSource={typedPersons}
                    allowPaging={true}
                    pageSettings={{ pageSize: 25, pageSizes: [10, 25, 50, 100] }}
                    allowSorting={true}
                    sortSettings={sortSettings}
                    allowMultiSorting={true}
                    allowFiltering={true}
                    filterSettings={{ type: 'CheckBox' }}
                    detailTemplate={casePersonInformation}
                    allowTextWrap={true}
                    textWrapSettings={{ wrapMode: 'Both' }}
                    allowExcelExport={true}
                    allowPdfExport={true}
                    toolbar={['ExcelExport', 'Search']}
                    toolbarClick={GridToolsClick}
                    ref={g => grid = g}
                    selectionSettings={{
                        persistSelection: true,
                        type: "Multiple",
                        //checkboxOnly: true,
                        mode: "Row"
                    }}
                    //rowSelecting={(event: any) => { console.log(event) }}
                    rowSelected={(event: any) => { expandRow(event) }}
                    allowSelection={true}
                    allowResizing={true}
                    rowHeight={24}
                >
                    <ColumnsDirective>
                        <ColumnDirective template={selectTemplate} headerText='Select' width={100} />
                        <ColumnDirective field='Start_Date' format={{ type: 'dateTime', format: 'MM/dd/yyyy HH:mm' }} headerText='Report Date' width={200} />
                        <ColumnDirective field='AgencyCaseNo' headerText={GetStringNameForSite(SiteName,"Case #")} width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='STATUS' headerText='Status' width={140} minWidth={140} maxWidth={140} customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective field='LastKnownLocation' headerText='Last Known Location' width={150} />
                        <ColumnDirective field='ReturnedLocation' headerText='Returned Location' width={150} />
                        <ColumnDirective template={gridTemplate} maxWidth={300} headerText='Indicator' customAttributes={{ class: ['e-attr'] }} />
                        <ColumnDirective template={NearByTemplate} headerText='Near By Vehicle/Person' width={200} />
                    </ColumnsDirective>
                    <Inject services={[Sort, Page, Filter, DetailRow, Toolbar, ExcelExport, PdfExport, Search]} />
                </GridComponent>

            </> : <></>}
        </div>
    );
};

export default SearchResults;