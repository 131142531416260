import * as React from "react";
import {
    GoogleMap,
    useJsApiLoader,
    Marker,
    HeatmapLayer,
    InfoWindow,
} from "@react-google-maps/api";
import Geocode from "react-geocode";
import { Google_API_Key, SiteName } from "../../../services/config.service";
import Pin from "../../../interfaces/Pin.interface";
import { isPointInPolygon } from "../../../services/dashboard.service";
import p_green from "../../../assets/Images/p_green.png";
import p_red from "../../../assets/Images/p_red.png";
import v_red from "../../../assets/Images/v_red.png";
import maher from "../../../assets/Images/maher.png";
import port from "../../../assets/Images/port.png";
import portliberty from "../../../assets/Images/portliberty.png";
import apm from "../../../assets/Images/apm.png";
import redhook from "../../../assets/Images/redhook.png";
import ssamarine from "../../../assets/Images/ssamarine.png";
import pnct from "../../../assets/Images/pnct.png";
import v_green from "../../../assets/Images/v_green.png";
import { SITE } from "../../../interfaces/config.interface";

interface HeatMapProps {
    data: any;
    county: string;
    city: string;
    tab: string;
}

const NJPortMap: React.FC<HeatMapProps> = ({ data, county, city, tab }) => {
    const [mapKey, setMapKey] = React.useState<number>(0);
    const [map, setMap] = React.useState<any>(null);
    const [heatMap, setHeatMap] = React.useState<any>(null);
    const [heatMapData, setHeatMapData] = React.useState<any[]>([]);
    const [pinData, setPinData] = React.useState<any[]>([]);
    const [center, setCenter] = React.useState<any>({
        lat: 40.680431,
        lng: -74.106876,
    });
    const [radius, setRadius] = React.useState<any>(25);
    const [selectedCenter, setSelectedCenter] = React.useState<any>(null);
    Geocode.setApiKey(Google_API_Key);
    const [zoom, setZoom] = React.useState<number>(13);
    const [boundry, setBoundry] = React.useState<any>(null);

    const [centerLat, setCenterLat] = React.useState<number>(
        SiteName === SITE.DCDEMO ||
            SiteName === SITE.DCPROD ||
            SiteName === SITE.DCLOCAL
            ? 38.917
            : 40.680431,
    );
    const [centerLong, setCenterLong] = React.useState<number>(
        SiteName === SITE.DCDEMO ||
            SiteName === SITE.DCPROD ||
            SiteName === SITE.DCLOCAL
            ? -77.0195
            : -74.106876,
    );

    const getInitialBoundary = () => {
        if (
            SiteName === SITE.DCDEMO ||
            SiteName === SITE.DCPROD ||
            SiteName === SITE.DCLOCAL
        ) {
            setBoundry([
                [38.995915112998006, -77.04117383965263],
                [38.893239776660444, -76.90965127078951],
                [38.79233551275002, -77.03920346408914],
                [38.88327082429337, -77.06087759488469],
                [38.93425112008646, -77.12146664346207],
            ]);
            setCenterLat(38.917); //38.917006246162785, -77.01949970805134
            setCenterLong(-77.0195);
        } else {
            setBoundry([
                [41.362453762936575, -74.6918236783217],
                [40.990338834229426, -73.91728763616442],
                [40.6952910099279, -74.01067141429685],
                [40.486723153439925, -74.26885009501595],
                [40.49090088462017, -74.01067141429685],
                [40.06763774018179, -73.8568628385493],
                [39.48504098031486, -74.21391846082041],
                [38.89324697993387, -74.93901603220169],
                [39.47232109991295, -75.55425033519187],
                [39.62903700481567, -75.55974349861143],
                [39.852904834638665, -75.40593492286388],
                [40.23558358525728, -74.79619378329326],
                [40.557709209883015, -75.20818103975989],
                [40.98204561057492, -75.15324940556434],
                [40.683941, -74.163109],
                [40.668652, -74.160019],
                [40.690239, -74.151901],
                [40.69976, -74.14048],
                [40.694901, -74.15023],
                [40.675529, -74.080681],
                [40.66497, -74.07263],
                [40.6637, -74.06922],
            ]);
            setCenterLat(40.0352);
            setCenterLong(-74.5844);

        }
    };

    React.useEffect(() => {
        getInitialBoundary();
        if (data && data.length > 0) {
            let temp = [];
            setPinData(data);
            for (var x of data) {
                if (typeof x.Latitude !== "number" || typeof x.Longitude !== "number") {
                } else if (isPointInPolygon(x.Latitude, x.Longitude, boundry)) {
                    for (let i = 0; i < x.Count; i++) {
                        temp.push(new google.maps.LatLng(x.Latitude, x.Longitude));
                    }
                }
            }
        } else {
            setPinData([]);
            setHeatMapData([]);
            setCenter({ lat: centerLat, lng: centerLong });
            setZoom(13);
        }
        if (
            SiteName == SITE.DCDEMO ||
            SiteName == SITE.DCPROD ||
            SiteName == SITE.DCLOCAL
        )
            setZoom(13);
    }, [data, tab]);

    const groupDataByLocationAndDate = (data: any[]) => {
        const groupedData: { [key: string]: any[] } = {}; // Specify the type of groupedData
        if (data && Array.isArray(data)) {
            data.forEach((pin) => {
                const arrivalDate = pin.ArrivalDateTime ? pin.ArrivalDateTime.split("T")[0] : 'Unknown';
                const key = `${pin.Latitude},${pin.Longitude},${arrivalDate}`;
                if (!groupedData[key]) {
                    groupedData[key] = [];
                }
                groupedData[key].push(pin);
            });
        }
        return Object.values(groupedData);
    };


    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: Google_API_Key,
        libraries: ["visualization"],
    });

    const containerStyle = {
        width: "100%",
        height: "60vh",
    };

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null);
        setHeatMap(null);
    }, []);

    const onLoad = (mapInstance: any) => {
        setMap(mapInstance);
    };

    const onHeatMapLoad = (mapInstance: any) => {
        setHeatMap(mapInstance);
    };

    const getPinIcon = (pin: any): any => {
        let iconPin;
        const lat = pin.Latitude;
        const long = pin.Longitude;
        const tolerance = 0.00001;

        if (
            Math.abs(lat - 40.683941) < tolerance &&
            Math.abs(long - -74.163109) < tolerance
        ) {
            iconPin = {
                url: maher,
                scaledSize: new google.maps.Size(50, 30),
            };
        } else if (
            Math.abs(lat - 40.668652) < tolerance &&
            Math.abs(long - -74.160019) < tolerance
        ) {
            iconPin = {
                url: apm,
                scaledSize: new google.maps.Size(50, 30),
            };
        } else if (
            Math.abs(lat - 40.690239) < tolerance &&
            Math.abs(long - -74.151901) < tolerance
        ) {
            iconPin = {
                url: pnct,
                scaledSize: new google.maps.Size(50, 30),
            };
        } else if (
            Math.abs(lat - 40.69976) < tolerance &&
            Math.abs(long - -74.14048) < tolerance
        ) {
            iconPin = {
                url: port,
                scaledSize: new google.maps.Size(50, 30),
            };
        } else if (
            Math.abs(lat - 40.675529) < tolerance &&
            Math.abs(long - -74.080681) < tolerance
        ) {
            iconPin = {
                url: portliberty,
                scaledSize: new google.maps.Size(50, 30),
            };
        } else if (
            Math.abs(lat - 40.66497) < tolerance &&
            Math.abs(long - -74.07263) < tolerance
        ) {
            iconPin = {
                url: ssamarine,
                scaledSize: new google.maps.Size(50, 30),
            };
        } else if (
            Math.abs(lat - 40.694901) < tolerance &&
            Math.abs(long - -74.15023) < tolerance
        ) {
            iconPin = {
                url: redhook,
                scaledSize: new google.maps.Size(50, 30),
            };
        } else if (
            Math.abs(lat - 40.6637) < tolerance &&
            Math.abs(long - -74.06922) < tolerance
        ) {
            iconPin = {
                url: port,
                scaledSize: new google.maps.Size(50, 30),
            };
        } else {
            iconPin = {
                url: v_red,
                scaledSize: new google.maps.Size(50, 30),
            };
        }

        return iconPin;
    };

    return (
        <div>
            {isLoaded ? (
                <>
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={zoom}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                        key={mapKey}
                    >
                        <>
                            {groupDataByLocationAndDate(data).map((pins: any, index: number) => {
                                const firstPin = pins[0];
                                const position = {
                                    lat: parseFloat(firstPin.Latitude),
                                    lng: parseFloat(firstPin.Longitude),
                                };
                                return (
                                    <Marker
                                        key={index}
                                        position={position}
                                        icon={getPinIcon(firstPin)}
                                        onClick={() => {
                                            setSelectedCenter(pins);
                                        }}
                                    >
                                        {selectedCenter &&
                                            selectedCenter[0].Latitude === firstPin.Latitude &&
                                            selectedCenter[0].Longitude === firstPin.Longitude && (
                                                <InfoWindow
                                                    position={position}
                                                    onCloseClick={() => {
                                                        setSelectedCenter(null);
                                                    }}
                                                >
                                                    <div className="p-2">
                                                        {selectedCenter.map((pin: { ShipName: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; ArrivalDateTime: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; Berth: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, i: React.Key | null | undefined) => (
                                                            <div key={i}>
                                                                <span className="font-bold">ShipName:</span> {pin.ShipName}
                                                                <br />
                                                                <span className="font-bold">Berth:</span> {pin.Berth}
                                                                <br />
                                                                <span className="font-bold">DateTime:</span> {pin.ArrivalDateTime}
                                                                <hr className="p-2" />
                                                            </div>
                                                        ))}
                                                    </div>

                                                </InfoWindow>
                                            )}
                                    </Marker>
                                );
                            })}
                            <HeatmapLayer
                                onLoad={onHeatMapLoad}
                                options={{
                                    opacity: 1,
                                    radius: radius,
                                    gradient: [
                                        "rgba(255,96,96,0)",
                                        "rgba(255,85,85,1)",
                                        "rgba(255,75,75,1)",
                                        "rgba(255,67,67,1)",
                                        "rgba(255,47,47,1)",
                                        "rgba(255,30,30,1)",
                                        "rgba(255,18,18,1)",
                                        "rgba(255,7,7,1)",
                                        "rgba(255,0,0,1)",
                                        "rgba(236,0,0,1)",
                                        "rgba(213,1,1,1)",
                                        "rgba(182,0,0,1)",
                                        "rgba(175,0,0,1)",
                                        "rgba(145,0,0,1)",
                                    ],
                                }}
                                data={heatMapData}
                            />
                        </>
                    </GoogleMap>
                </>
            ) : (
                <></>
            )}
        </div>
    );
};
export default NJPortMap;